import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import TitleBar from "./Components/TitleBar/TitleBar.js";
import AboutUs from "./Pages/AboutUs/AboutUsPage.js";
import Appointment from "./Pages/Appointment/Appointment.js";
import BloodPressure from "./Pages/BodyParams/BloodPressure.js";
import BodyWater from "./Pages/BodyParams/BodyWater.js";
import Cholestrol from "./Pages/BodyParams/Cholestrol.js";
import HeartParams from "./Pages/BodyParams/HeartParams.js";
import Hemoglobin from "./Pages/BodyParams/Hemoglobin.js";
import OxygenSaturation from "./Pages/BodyParams/OxygenSaturation.js";
import RandomBloodSugar from "./Pages/BodyParams/RandomBloodSugar.js";
import Stress from "./Pages/BodyParams/Stress.js";
import DeleteProfile from "./Pages/DeleteProfile/DeleteProfile.js";
import HelloPatient from "./Pages/HelloPatient/HelloPatient.js";
import HomeScreen from "./Pages/HomeScreen/HomeScreen.js";
import Imr from "./Pages/IMR/Imr.js";
import Login from "./Pages/Login/Login.js";
import MyDoctor from "./Pages/MyDoctor/MyDoctor.js";
import PasswordChange from "./Pages/PasswordChange/PasswordChange.js";
import PasswordCreate from "./Pages/PasswordCreate/PasswordCreate.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.js";
import Profile from "./Pages/Profile/Profile.js";
import RatingPage from "./Pages/RatingPage/RatingPage.js";
import NewUserRegister from "./Pages/Register/NewUserRegister.js";
import Register from "./Pages/Register/Register.js";
import Reports from "./Pages/Reports/Reports.js";
import SelfTest from "./Pages/SelfTest/SelfTest.js";
import SelfTest1 from "./Pages/SelfTest/SelfTest1.js";
import Settings from "./Pages/Settings/Settings.js";
import SplashScreen from "./Pages/SplashScreen/SplashScreen.js";
import TnCPage from "./Pages/TnCPage/TnCPage.js";
import VitalResults from "./Pages/VitalResults/VitalResults.js";
import ReportVitals from "./Pages/Vitals/ReportVitals.js";
import { SavedStateContext } from "./context/SavedState.js";
import PatientAppointment from "./Pages/PatientAppointment/PatientAppointment.js";
import Sendemail from "./Pages/SendEmail/Sendemail.js";
import EmailChange from "./Pages/EmailChange/EmailChange.js";
import PhoneChange from "./Pages/PhoneChange/PhoneChange.js";
import { Phone } from "@mui/icons-material";

function App() {
  const [did, setdid] = useState(null);
  const [userExists, setuserExists] = useState(false);
  const [loginInvoked, setLoginInvoked] = useState(false);
  const states = useContext(SavedStateContext);

  async function getuser(deviceId) {
    console.log(
      "getUser is called from app.js,\n userExistence:",
      userExists,
      "\ndevice Id:",
      deviceId
    );
    if (!deviceId || deviceId === "") return;
    await axios
      .get(`https://www.iterve.ai/uservitals/thisdid`, {
        params: { did: deviceId },
      })
      .then((res) => {
        console.log(res.data, res.status);
        if (res.status == 200) {
          console.log("user with device id found");
          states.setCurrentUser(res.data);
          localStorage.setItem("currentUser", JSON.stringify(res.data));
          setuserExists(true);
        } else {
          console.log("the device id does not corresponds to any user");
          states.setCurrentUser(null);
          localStorage.removeItem("currentUser");
          setuserExists(false);
          // if(location.pathname === "login") return;
          // console.log(location.pathname)
          // const login = document.createElement("a");
          // login.href = "/login";
          // login.click();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // Generate fingerprint
    Fingerprint2.get((components) => {
      const deviceId = Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      console.log(deviceId); // Log the deviceId
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
      // if(loginInvoked)

      getuser(deviceId);
    });
  }, []);

  return (
    // <SavedState>
    <Router>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/register" element={<Register />} />
        <Route path="/passcreate" element={<PasswordCreate />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route
          path="/login"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          }
          // element={ <Login setuserExists={setuserExists} />}
        />

        <Route
          path="/titlebar"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <TitleBar />
          }
        />

        <Route
          path="/dashboard"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <HelloPatient />
            )
          }
        />

        <Route
          path="/appointment"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <Appointment />
            )
          }
        />

        <Route path="/patientappointment" element={<PatientAppointment />} />

        <Route path="/sendemail" element={<Sendemail />} />

        <Route
          path="/profile"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <Profile />
          }
        />
        <Route
          path="/selftest"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <SelfTest />
          }
        />
        <Route
          path="/emailchange"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <EmailChange />
            )
          }
        />
        <Route
          path="/selftest1"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <SelfTest1 />
            )
          }
        />
        <Route
          path="/imr"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <Imr />
          }
        />

        <Route
          path="/heart"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <HeartParams />
            )
          }
        />
        <Route
          path="/bloodpressure"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <BloodPressure />
            )
          }
        />
        <Route
          path="/bodywater"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <BodyWater />
            )
          }
        />
        <Route
          path="/oxygensaturation"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <OxygenSaturation />
            )
          }
        />
        <Route
          path="/hemoglobin"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <Hemoglobin />
            )
          }
        />
        <Route
          path="/rbs"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <RandomBloodSugar />
            )
          }
        />
        <Route
          path="/stressIndex"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <Stress />
          }
        />
        <Route
          path="/mydoctor"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <MyDoctor />
          }
        />
        <Route
          path="/newuser"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <NewUserRegister />
            )
          }
        />
        <Route
          path="/cholestrol"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <Cholestrol />
            )
          }
        />

        <Route
          path="/reports"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <Reports />
          }
        />
        <Route
          path="/vitals"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <ReportVitals />
            )
          }
        />

        <Route
          path="/rating"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <RatingPage />
            )
          }
        />

        {/* <Route
          path="/results"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <VitalResults />
            )
          }
        /> */}

        <Route path="/results" element={<VitalResults />} />

        <Route
          path="/delete"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <DeleteProfile />
            )
          }
        />

        <Route
          path="/passchange"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <PasswordChange />
            )
          }
        />
        <Route
          path="/phonechange"
          element={
            !userExists ? (
              <Login setuserExists={setuserExists} />
            ) : (
              <PhoneChange />
            )
          }
        />

        <Route
          path="/settings"
          element={
            !userExists ? <Login setuserExists={setuserExists} /> : <Settings />
          }
        />

        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/vitals/:pid/:cid" element={<ReportVitals />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/tncpage" element={<TnCPage />} />
      </Routes>
    </Router>
    // </SavedState>
  );
}

export default App;
