import React from "react";
import classes from "./reports.module.css";
import pdficon from "../../Images/pdficon.png";
import { useNavigate } from "react-router-dom";

const Card = ({ name, date, d, userID, navigate }) => {
  const accno = localStorage.getItem("accno");
  const TruncateName = ({ name }) => {
    if (name.length <= 10) {
      return <div className={classes.cardname}>{name}</div>;
    } else {
      const truncatedName = name.substring(0, 10) + "...";
      return <div className={classes.cardname}>{truncatedName}</div>;
    }
  };
  return (
    <div
      className={classes.maincard}
      onClick={() => {
        d["id"] = userID;
        console.log("d", d);
        navigate("/vitals", { state: d });
      }}
    >
      <div className={classes.ncont}>
        <div className={classes.smallcircle}></div>
        {TruncateName({ name })}
      </div>
      <div className={classes.iconcont}>
        <img src={pdficon} className={classes.card} />
      </div>
      <div className={classes.ptexts}>
        <div className={classes.p1}>Saved on {date}</div>
        <div className={classes.p2}>Created on {date}</div>
      </div>
    </div>
  );
};

export default Card;
