import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import avatarIcon from "../../Images/blank-profile-picture.png";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import classes from "./DeleteProfile.module.css";
import MobileFooter from "../../Components/Footer/MobileFooter";
import MobileHeader from "../../Components/Header/MobileHeader";
import UserSelect from "../../Components/UserSelect/Userselect";
import draglist from "../../Images/dragListDown.png";
import videoCall from "../../Images/videoIcon.png";

const DeleteProfile = () => {
  const states = useContext(SavedStateContext);

  const email = states.currentUser.email;
  const name = states.currentUser.name;

  const users = states.users;

  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [avatar, setAvatar] = useState(null);
  const [deleteReason, setDeleteReason] = useState(null);

  const changeUser = (name, email) => {
    console.log("change user called", name, email);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUser([states.currentUser.name, ...states.users]);
    setAvatar(
      states.userImage[states.currentUser.name]
        ? states.userImage[states.currentUser.name].data
        : avatarIcon
    );
  }, [states.users]);

  const reasons = [
    "Service Dissatisfaction: If you are unhappy with the service provided by iTerve, whether due to technical issues, lack of features, or other reasons, you might choose to delete your account.",
    "Lack of Use: If you find that you are not using the iTerve app or its services frequently or at all, you might decide to delete your account to simplify your digital life and reduce unnecessary accounts.",
    "Privacy Concerns: You may have concerns about how your personal data is being used, stored, or shared, and prefer to remove your information from the platform to ensure your privacy.",
    "Switching to Another Service: If you have found another service or app that better meets your needs for health monitoring and management, you might decide to delete your iTerve account.",
  ];

  const signout = async () => {
    await axios
      .put("https://www.iterve.ai/uservitals", {
        params: {
          email: states.currentUser.email,
          deviceId: "",
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(null);
        localStorage.removeItem("currentUser");
        navigate("/home");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deletedUser = () => {
    if (states.currentUser.usertype === "child") {
      if (deleteReason === "" || !deleteReason) {
        alert("Please provide a valid reason from the list");
        return;
      }
      axios
        .delete("https://www.iterve.ai/uservitals/deleteuser", {
          params: {
            email: states.currentUser.email,
            name: states.currentUser.name,
          },
        })
        .then((res) => {
          console.log(res);
          axios
            .post("https://www.iterve.ai/deleteUser/", {
              params: {
                email: states.currentUser.email,
                name: states.currentUser.name,
                reason: deleteReason,
              },
            })
            .then((res) => {
              console.log(res);
              signout();
              navigate("/login");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (deleteReason === "" || !deleteReason) {
        alert("Please provide a valid reason from the list");
        return;
      }
      axios
        .delete("https://www.iterve.ai/uservitals/deleteallusers", {
          params: { email: states.currentUser.email, reason: deleteReason },
        })
        .then((res) => {
          console.log(res);
          axios
            .post("https://www.iterve.ai/deleteUser/", {
              params: {
                email: states.currentUser.email,
                name: states.currentUser.name,
                reason: deleteReason,
              },
            })
            .then((res) => {
              console.log(res);
              signout();
              navigate("/login");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={classes.fullpage}>
      {window.innerWidth > 768 && (
        <div className={classes.hellotext}>
          HELLO,
          <div className={classes.name}>
            {states.currentUser ? states.currentUser.name : ""}
          </div>
        </div>
      )}
      {window.innerWidth > 768 ? (
        <div className={classes.switch}> Switch User </div>
      ) : (
        <div className={classes.deletetext}>Delete Account</div>
      )}
      {window.innerWidth > 768 ? (
        <div className={classes.userCont}>
          {users &&
            users.map((name, idx) => (
              <button
                onClick={(e) => changeUser(name, states.currentUser.email)}
                className={classes.avatarName}
                key={idx}
              >
                <img
                  src={
                    states.userImage[name]
                      ? states.userImage[name].data
                      : avatarIcon
                  }
                  alt="avatar icon"
                  className={classes.avatar}
                />
                <div className={classes.user}>{name}</div>
              </button>
            ))}
          {window.innerWidth <= 768 && (
            <button className={classes.avatarName}>
              <div className={classes.user}>Previous Report</div>
              <img
                src={draglist}
                alt="avatar icon"
                className={classes.avatar}
              />
            </button>
          )}
          {window.innerWidth <= 768 && (
            <div
              onClick={() => {
                states.currentUser.email === "prakhar@aivot.in"
                  ? navigate("/selftest1")
                  : navigate("/selftest");
              }}
              className={classes.avatarName}
            >
              <div className={classes.user}>New Test</div>
              <img
                src={videoCall}
                alt="avatar icon"
                className={classes.avatar}
              />
            </div>
          )}
        </div>
      ) : (
        <UserSelect />
      )}
      <div className={classes.selectuser}>
        Select the reason to delete the account?
      </div>
      {reasons.map((reason, idx) => {
        return (
          <div
            className={
              reason === deleteReason ? classes.selectedreason : classes.reasons
            }
            onClick={() => setDeleteReason(reason)}
          >
            {reason}
          </div>
        );
      })}
      <button className={classes.delete} onClick={deletedUser}>
        Delete
      </button>
      {window.innerWidth <= 768 && <MobileFooter />}
    </div>
  );
};

export default AppLayout()(DeleteProfile);
