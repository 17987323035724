import React, { useContext, useState, useRef, useEffect } from "react";
import classes from "./MobileHeader.module.css";
import itervelogo from "../../Images/itervelogo.png";
import menu from "../../Images/Menu.png";
import homeIcon from "../../Images/Home.png";
import loginIcon from "../../Images/Login.png";
import registerIcon from "../../Images/Add User Male.png";
import passCreateIcon from "../../Images/Password.png";
import ragtingIcon from "../../Images/Star Filled.png";
import dashboardIcon from "../../Images/Control Panel.png";
import selftestIcon from "../../Images/Live Video On (2).png";
import reportIcon from "../../Images/Graph Report.png";
import profileIcon from "../../Images/Registration.png";
import settingsIcon from "../../Images/Admin Settings Male.png";
import PasswordbookIcon from "../../Images/Password Book.png";
import emailIcon from "../../Images/Email.png";
import phoneIcon from "../../Images/Phone.png";
import deleteIcon from "../../Images/Denied (2).png";
import imrdashboardIcon from "../../Images/Graph Report Script.png";
import heartpulsIcon from "../../Images/Heart with Pulse.png";
import bloodPressureIcon from "../../Images/Sphygmomanometer.png";
import bodywaterIcon from "../../Images/Water.png";
import oxygenIcon from "../../Images/Oxygen Mask.png";
import haemoIcon from "../../Images/Blood Sample.png";
import sugarIcon from "../../Images/Sugar Cubes.png";
import lipidsIcon from "../../Images/Lipids.png";
import stressIcon from "../../Images/Anxiety.png";
import close from "../../Images/Multiply.png";
import search from "../../Images/Search.png";
import abouticon from "../../Images/US News.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SavedStateContext } from "../../context/SavedState";

const MobileHeader = ({ heading }) => {
  const states = useContext(SavedStateContext);
  const [menuopen, setmenuopen] = useState(false);
  const divRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setmenuopen(false);
    }
  };

  useEffect(() => {
    // Add event listener to detect outside clicks
    if (menuopen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component unmounts or div closes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuopen]);

  const signout = async () => {
    await axios
      .put("https://www.iterve.ai/uservitals", {
        params: {
          email: states.currentUser.email,
          deviceId: "",
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(null);
        localStorage.removeItem("currentUser");
        navigate("/home");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.mobileheader}>
      <div className={classes.mobileheaderhome}>
        <div className={classes.menuleftcont}>
          <img
            src={menu}
            className={classes.menu}
            onClick={() => setmenuopen(true)}
          />
          <div className={classes.hometext}>{heading}</div>
        </div>
        {menuopen && states.currentUser && (
          <div className={classes.mobilemenu} ref={divRef}>
            <img src={itervelogo} className={classes.menulogo} />
            <div className={classes.menuItems}>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  setmenuopen(false);
                }}
              >
                <img src={close} className={classes.menuItemIcon} />
                <span>Close</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  setmenuopen(false);
                }}
              >
                <img src={search} className={classes.menuItemIcon} />
                <span>Search</span>
              </div>
              <div className={classes.menuheading}>Menu</div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/home");
                  setmenuopen(false);
                }}
              >
                <img src={homeIcon} className={classes.menuItemIcon} />
                <span>Home</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/dashboard");
                  setmenuopen(false);
                }}
              >
                <img src={dashboardIcon} className={classes.menuItemIcon} />
                <span>Dashboard</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/selftest");
                  setmenuopen(false);
                }}
              >
                <img src={selftestIcon} className={classes.menuItemIcon} />
                <span>Self Test</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/reports");
                  setmenuopen(false);
                }}
              >
                <img src={reportIcon} className={classes.menuItemIcon} />
                <span>Previous Reports</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/profile");
                  setmenuopen(false);
                }}
              >
                <img src={profileIcon} className={classes.menuItemIcon} />
                <span>Profile</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/settings");
                  setmenuopen(false);
                }}
              >
                <img src={settingsIcon} className={classes.menuItemIcon} />
                <span>Settings</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/rating");
                  setmenuopen(false);
                }}
              >
                <img src={ragtingIcon} className={classes.menuItemIcon} />
                <span>Ratings</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/imr");
                  setmenuopen(false);
                }}
              >
                <img src={imrdashboardIcon} className={classes.menuItemIcon} />
                <span>Imr Dashboard</span>
              </div>
              <div className={classes.menuheading}>PP&TC</div>
              {states.currentUser ? (
                <div
                  className={classes.menuItem1}
                  onClick={() => {
                    signout();
                    navigate("/login");
                    setmenuopen(false);
                  }}
                >
                  <img src={loginIcon} className={classes.menuItemIcon} />
                  <span>Logout</span>
                </div>
              ) : (
                <div
                  className={classes.menuItem1}
                  onClick={() => {
                    navigate("/login");
                    setmenuopen(false);
                  }}
                >
                  <img src={loginIcon} className={classes.menuItemIcon} />
                  <span>Login</span>
                </div>
              )}
              <div
                className={classes.menuItem1}
                onClick={() => {
                  navigate("/delete");
                  setmenuopen(false);
                }}
              >
                <img src={registerIcon} className={classes.menuItemIcon} />
                <span>Delete Account</span>
              </div>

              <div
                className={classes.menuItem1}
                onClick={() => {
                  setmenuopen(false);
                }}
              >
                <img src={deleteIcon} className={classes.menuItemIcon} />
                <span>Privacy Policy</span>
              </div>
              <div
                className={classes.menuItem1}
                onClick={() => {
                  setmenuopen(false);
                }}
              >
                <img src={deleteIcon} className={classes.menuItemIcon} />
                <span>Term and Conditions</span>
              </div>
            </div>
          </div>
        )}
        {menuopen && !states.currentUser && (
          <div className={classes.mobilemenu} ref={divRef}>
            <img src={itervelogo} className={classes.menulogo} />
            <div className={classes.menuItems}>
              <div
                className={classes.menuItem}
                onClick={() => {
                  setmenuopen(false);
                }}
              >
                <img src={close} className={classes.menuItemIcon} />
                <span>Close</span>
              </div>
              <div
                className={classes.menuItem}
                onClick={() => {
                  setmenuopen(false);
                }}
              >
                <img src={search} className={classes.menuItemIcon} />
                <span>Search</span>
              </div>
              <div
                className={classes.menuItem}
                onClick={() => {
                  navigate("/aboutus");
                  setmenuopen(false);
                }}
              >
                <img src={homeIcon} className={classes.menuItemIcon} />
                <span>About Us</span>
              </div>
              {states.currentUser ? (
                <div
                  className={classes.menuItem}
                  onClick={() => {
                    signout();
                    navigate("/login");
                    setmenuopen(false);
                  }}
                >
                  <img src={loginIcon} className={classes.menuItemIcon} />
                  <span>Logout</span>
                </div>
              ) : (
                <div
                  className={classes.menuItem}
                  onClick={() => {
                    navigate("/login");
                    setmenuopen(false);
                  }}
                >
                  <img src={loginIcon} className={classes.menuItemIcon} />
                  <span>Login</span>
                </div>
              )}
              <div
                className={classes.menuItem}
                onClick={() => {
                  navigate("/register");
                  setmenuopen(false);
                }}
              >
                <img src={registerIcon} className={classes.menuItemIcon} />
                <span>Register</span>
              </div>
            </div>
          </div>
        )}
        <img src={itervelogo} className={classes.logoimage} />
      </div>
    </div>
  );
};

export default MobileHeader;
