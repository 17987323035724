import React, { useState, useEffect, useContext, useRef } from "react";
import classes from "./selfTest.module.css";
import AppLayout from "../../layout/AppLayout/AppLayout";
import avatarIcon from "../../Images/avatarIcon.png";
import dropDownIcon from "../../Images/dropDownIcon.png";
import MobileHeader from "../../Components/Header/MobileHeader";
import CircWebcam from "./CircWebcam";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SavedStateContext } from "../../context/SavedState";
import { set } from "mongoose";

const SelfTest = () => {
  const states = useContext(SavedStateContext);

  const email = states.currentUser.email;
  const name = states.currentUser.name;
  const usertype = states.currentUser.usertype;
  console.log(email, name, usertype);

  const [userform, setuserform] = useState({});
  const [thispageform, setthispageform] = useState({});
  const [selectedTime, setSelectedTime] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isAlertActive, setAlertActive] = useState(false);

  const permissionRef = useRef();

  function alertWDC(message) {
    setAlertActive(true);
    alert(message);
    setTimeout(() => setAlertActive(false), 100);
  }

  useEffect(() => {
    axios
      .get("https://www.iterve.ai/uservitals/registeruser", {
        params: { e: email, name: name },
      })
      .then((res) => {
        console.log(res.data);
        setuserform((prevUserForm) => ({
          ...prevUserForm,
          ...res.data,
        }));

        if (res.data.Exercise) {
          if (res.data.Exercise === "Sedentary Active") {
            setuserform((prevUserForm) => ({
              ...prevUserForm,
              ["activity_factor"]: "1.2",
            }));
          } else if (res.data.Exercise === "Lightly Active") {
            setuserform((prevUserForm) => ({
              ...prevUserForm,
              ["activity_factor"]: "1.375",
            }));
          } else if (res.data.Exercise === "Moderately Active") {
            setuserform((prevUserForm) => ({
              ...prevUserForm,
              ["activity_factor"]: "1.55",
            }));
          } else if (res.data.Exercise === "Very Active") {
            setuserform((prevUserForm) => ({
              ...prevUserForm,
              ["activity_factor"]: "1.725",
            }));
          } else if (res.data.Exercise === "Super Active") {
            setuserform((prevUserForm) => ({
              ...prevUserForm,
              ["activity_factor"]: "1.9",
            }));
          }
        }
        console.log(userform);
      });
  }, [states.currentUser]);

  useEffect(() => {
    console.log(userform);
  }, [userform]);

  const changeUser = (name, email) => {
    console.log("change user called", name, email);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const healthParams = [
    { label: "Diabetic-", name: "diabetic", options: ["yes", "no"] },
    {
      label: "Blood Pressure-",
      name: "bloodPressure",
      options: ["High", "Normal", "Low"],
    },
    { label: "Heart Disease-", name: "heartDisease", options: ["yes", "no"] },
    {
      label: "Major Health Issues-",
      name: "majorHealthIssues",
      options: [
        "None",
        "Cancer",
        "Heart Attack",
        "Mental Health disorder",
        "Autoimmune Disease",
        "Kidney Disease",
      ],
    },
  ];

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setuserform((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleMealTimeLapsed = (time) => {
    console.log(time);
    setSelectedTime(time);
    setuserform((prev) => ({
      ...prev,
      ["mealTimeLapsed"]: time,
    }));
  };
  const [isRecording, setRecording] = React.useState(false);
  const [isModelLoading, setModelLoading] = React.useState(false);

  const handleRecord = () => {
    if (isRecording) {
      setRecording(false);
      return;
    }

    if (!isChecked) {
      alertWDC("Please agree to the privacy policy");
      permissionRef.current.focus();
      return;
    }

    if (
      userform.diabetic &&
      userform.bloodPressure &&
      userform.heartDisease &&
      userform.majorHealthIssues &&
      userform.mealTimeLapsed
    ) {
      setRecording(true);
    } else {
      alertWDC("Please fill all the fields");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={classes.container}>
      {window.innerWidth <= 768 && (
        <div className={classes.heading}>Self Test</div>
      )}
      <div className={classes.hellotext}>
        HELLO,
        <div className={classes.name}>
          {states.currentUser ? states.currentUser.name : ""}
        </div>
      </div>
      {window.innerWidth > 768 && (
        <div style={{ display: "flex", gap: "3vw" }}>
          {states.users ? (
            states.users.map((user, index) => (
              <div
                key={index}
                className={classes.avatarName}
                onClick={() => changeUser(user, email)}
              >
                <img
                  src={
                    states.userImage[user]
                      ? states.userImage[user].data
                      : avatarIcon
                  }
                  alt={avatarIcon}
                  className={classes.avatar}
                />
                <div className={classes.user}>{user}</div>
              </div>
            ))
          ) : (
            <></>
          )}
          <div className={classes.prevReportSec}>
            <div className={classes.user}>Previous Report</div>
            <img
              src={dropDownIcon}
              alt="avatar icon"
              className={classes.avatar}
            />
          </div>
        </div>
      )}

      <div className={classes.userSec}>
        <div className={classes.headMiniCont}>
          <div className={classes.headingOfMiniCont}>Activity Status</div>
          <div className={classes.miniCont}>
            {healthParams.map((detail, idx) => (
              <div className={classes.detailsRow} key={idx}>
                <div className={classes.userData}>{detail.label}</div>
                <select
                  className={classes.userInput}
                  name={detail.name}
                  onChange={handleInputChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Please select {detail.label}
                  </option>
                  {detail.options.map((option, optionIdx) => (
                    <option key={optionIdx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ))}
            <div className={classes.detailsRow}>
              <div className={classes.userData}>Meal Time Lapsed</div>
              <div className={classes.mealTimeSec}>
                {[2, 4, 6, 8].map((time) => (
                  <div
                    key={time}
                    className={`${classes.time} ${
                      selectedTime === time ? classes.selectedTime : ""
                    }`}
                    onClick={() => handleMealTimeLapsed(time)}
                  >
                    {time}
                  </div>
                ))}
                <div className={classes.hrs}>Hrs</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.webcamSection}>
          <CircWebcam
            startRecording={isRecording}
            setRecording={setRecording}
            userform={userform}
            setLoading={setModelLoading}
            email={email}
            name={name}
            usertype={usertype}
            isAlertActive={isAlertActive}
            setAlertActive={setAlertActive}
          />
          <button onClick={handleRecord} className={classes.recordBtn}>
            {!isRecording ? "Start" : "Stop"}
          </button>
          <div className={classes.inst}>
            Please be still and clam while recording
          </div>
        </div>
      </div>
      <div className={classes.tncContainer}>
        <div className={classes.disclaimer}>
          Your video and picture is not stored in our database; our system
          extracts only RGB and rPPG from camera processing and uses it to
          derive your health vitals.
        </div>
        <label className={classes.consent}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            ref={permissionRef}
            className={classes.checkbox}
          />
          I agree to share my data for research and training purposes.
        </label>
      </div>
    </div>
  );
};

export default AppLayout()(SelfTest);
