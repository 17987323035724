import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import manavatar from "../../Images/manavatar.png";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import classes from "./helloPatient.module.css";
import MobileFooter from "../../Components/Footer/MobileFooter";
import MobileHeader from "../../Components/Header/MobileHeader";

const HelloPatient = () => {
  const states = useContext(SavedStateContext);

  const email = states.currentUser.email;
  const name = states.currentUser.name;

  const navigate = useNavigate();

  const [allusers, setallusers] = useState([]);
  const [latestReport, setLatestReport] = useState();
  const [changedUser, setchangeduser] = useState();
  const [viewmoreComp, setviewmoreComp] = useState(false);

  function findAndSHowThatUser(name) {
    console.log("finding that user", name);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    console.log("email name", email, name);
    axios
      .get("https://www.iterve.ai/uservitals/latestreport", {
        params: { email: email, name: name },
      })
      .then((res) => {
        setLatestReport(res.data[0]);
        console.log("latest report", res.data[0]);
        axios
          .get("https://www.iterve.ai/uservitals/subusernames", {
            params: { email: email },
          })
          .then((res) => {
            console.log("all users", res.data);
            var a = [];
            for (let i = 0; i < res.data.length; i++) {
              a.push(res.data[i].name);
            }
            setallusers(a.filter((name) => name != states.currentUser.name));
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [states.currentUser]);

  function calculateAge(dateString) {
    // Split the input date string to get day, month, and year
    let [day, month, year] = dateString.split("/").map(Number);

    // Create a date object from the input date
    let birthDate = new Date(year, month - 1, day);
    let today = new Date();

    // Calculate age
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  // const cardData = !latestReport
  //   ? []
  //   : [
  //       {
  //         label: "Heart Rate",
  //         reference: "Reference range is 60-100 beats per minute",
  //         value: latestReport ? latestReport.heart_rate : "",
  //         color1: "#6652D3",
  //         color2: "#8B82BD",
  //         barcolor: "#554999",
  //       },
  //       {
  //         label: "Stress Index",
  //         reference: "Reference range is 20-80 %",
  //         value: latestReport ? latestReport.stress_index : "",
  //         color1: "#3E67CF",
  //         color2: "#9BBDDC",
  //         barcolor: "#3E67CF",
  //       },
  //       {
  //         label: "Oxygen Saturation",
  //         reference: "Reference range is 95-100 %",
  //         value: latestReport ? latestReport.oxygen_saturation : "",
  //         color1: "#701F8C",
  //         color2: "#C695D7",
  //         barcolor: "#9223B9",
  //       },
  //       {
  //         label: "Blood Pressure",
  //         reference: "Reference range is 100 - 130 / 65 - 90",
  //         value: `${latestReport ? latestReport.sbp : ""}/${
  //           latestReport ? latestReport.dbp : ""
  //         }`,
  //         color1: "#C746D2",
  //         color2: "#C746D2",
  //         barcolor: "#7B0C85",
  //       },
  //       {
  //         label: "BMI",
  //         reference: "Reference range is 18.5 – 24.9 kg/m^2",
  //         value: latestReport ? latestReport.bmi : "",
  //         color1: "#6652D3",
  //         color2: "#8B82BD",
  //         barcolor: "#554999",
  //         unit: "kg/m^2",
  //       },
  //       {
  //         label: "Body Water",
  //         reference:
  //           states.currentUser["gender"] === "Male"
  //             ? "Reference range is 43 - 73 %"
  //             : "Reference range is 41 - 60 %",
  //         value: latestReport ? latestReport.body_water : "",
  //         color1: "#3E67CF",
  //         color2: "#9BBDDC",
  //         barcolor: "#3E67CF",
  //       },
  //       {
  //         label: "Body Fat",
  //         reference:
  //           states.currentUser["gender"] === "Male"
  //             ? "Reference range is 8 - 21 %"
  //             : "Reference range is 14 - 28 %",
  //         value: latestReport ? latestReport.body_fat : "",
  //         color1: "#701F8C",
  //         color2: "#C695D7",
  //         barcolor: "#9223B9",
  //       },
  //       {
  //         label: "Visceral Fat",
  //         reference: "Reference range is 1 - 12 %",
  //         value: latestReport ? latestReport.visceral_fat : "",
  //         color1: "#C746D2",
  //         color2: "#C746D2",
  //         barcolor: "#7B0C85",
  //       },
  //     ];
  let cardData;
  if (window.innerWidth > 768) {
    cardData = !latestReport
      ? []
      : [
          {
            label: "Heart Rate",
            reference: "Reference range is 60-100 beats per minute",
            value: latestReport ? latestReport.heart_rate : "",
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
          },
          {
            label: "Oxygen Saturation",
            reference: "Reference range is 95-100 %",
            value: latestReport ? latestReport.oxygen_saturation : "",
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
          },
          {
            label: "BMI",
            reference: "Reference range is 18.5 – 24.9 kg/m^2",
            value: latestReport ? latestReport.bmi : "",
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
          },
          {
            label: "Cardiac Output",
            reference: "Reference range is 4.0 - 8.0 L/min",
            value: latestReport ? latestReport.cardiac_output : "",
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
          },
          {
            label: "Cardiac Index",
            reference: "Reference range is 2.6 - 4.2 L/min/m^2",
            value: latestReport ? latestReport.cardiac_index : "",
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
          },
          {
            label: "Stroke Volume",
            reference: "Reference range is 48.2 - 98.5 ml",
            value: latestReport ? latestReport.stroke_volume : "",
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
          },
          {
            label: "SBP",
            reference: "Reference range is 100 - 130",
            value: latestReport ? latestReport.sbp : "",
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
          },
          {
            label: "DBP",
            reference: "Reference range is 65 - 90",
            value: latestReport ? latestReport.dbp : "",
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
          },
        ];
  } else {
    cardData = !latestReport
      ? []
      : [
          {
            label: "Heart Rate",
            reference: "Reference range is 60 - 100 beats per min",
            value: latestReport["heart_rate"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "beats per min",
          },
          {
            label: "Stress Index",
            reference: "Reference range is 20 – 80%",
            value: latestReport["stress_index"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "%",
          },
          {
            label: "Oxygen Saturation",
            reference: "Reference range is 95 – 100%",
            value: latestReport["oxygen_saturation"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "%",
          },
          // {
          //   label: "Blood Pressure",
          //   reference: "Reference range is 100 - 130 / 65 - 90",
          //   value: latestReport["sbp"] + "/" + latestReport["dbp"],
          //   color1: "#C746D2",
          //   color2: "#C746D2",
          //   barcolor: "#7B0C85",
          //   unit: "",
          // },
          {
            label: "BMI",
            reference: "Reference range is 18.5 – 24.9 kg/m^2",
            value: latestReport["bmi"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "kg/m^2",
          },
          {
            label: "Body Water",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "43 - 73%"
                : "41 - 60%"),
            value: latestReport["body_water"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "%",
          },
          {
            label: "Body Fat",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "8 - 21%"
                : "14 - 28%"),
            value: latestReport["body_fat"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "%",
          },
          {
            label: "Visceral Fat",
            reference: "Reference range is 1 - 12%",
            value: latestReport["visceral_fat"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "%",
          },
          {
            label: "Subcutaneous Fat",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "8 - 25%"
                : "20 - 35%"),
            value: latestReport["subcutaneous_fat"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "%",
          },
          {
            label: "Cardiac Output",
            reference: "Reference range is 4 – 8 L/min",
            value: latestReport["cardiac_output"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "L/min",
          },
          {
            label: "Cardiac Index",
            reference: "Reference range is 2.6 – 4.2 L/min/m^2",
            value: latestReport["cardiac_index"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "L/min/m^2",
          },
          {
            label: "Stroke Volume",
            reference: "Reference range is 48.2 - 98.5 millilitre",
            value: latestReport["stroke_volume"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "millilitre",
          },
          {
            label: "RMSSD",
            reference: "Reference range is 19 – 107 milliseconds",
            value: latestReport["rmssd"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "milliseconds",
          },
          {
            label: "SDNN",
            reference: "Reference range is >55 milliseconds",
            value: latestReport["sdnn"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "milliseconds",
          },
          {
            label: "SD1",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "40.5 - 71 milliseconds"
                : "29 - 65 milliseconds"),
            value: latestReport["sd1"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "milliseconds",
          },
          {
            label: "SD2",
            reference: "Reference range is 35 - 107 milliseconds",
            value: latestReport["sd2"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "milliseconds",
          },
          {
            label: "IBI",
            reference: "Reference range is 600 – 900 milliseconds",
            value: latestReport["ibi"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "milliseconds",
          },
          {
            label: "SBP",
            reference: "Reference range is 100 – 130 mm/Hg",
            value: latestReport["sbp"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "mm/Hg",
          },
          {
            label: "DBP",
            reference: "Reference range is 65 - 90 mm/Hg",
            value: latestReport["dbp"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "mm/Hg",
          },
          {
            label: "Mean Arterial Pressure",
            reference: "Reference range is 70 - 100 mm/Hg",
            value: latestReport["mean_arterial_pressure"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "mm/Hg",
          },
          {
            label: "Pulse Pressure",
            reference: "Reference range is 44 – 60 mm/Hg",
            value: latestReport["pulse_pressure"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "mm/Hg",
          },
          {
            label: "HDL",
            reference: "Reference range is 40 - 75 mg/dl",
            value: latestReport["hdl"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "mg/dl",
          },
          {
            label: "LDL",
            reference: "Reference range is 50 - 130 mg/dl",
            value: latestReport["ldl"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "mg/dl",
          },
          {
            label: "Triglycerides",
            reference: "Reference range is 60 - 150 mg/dl",
            value: latestReport["triglycerides"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "mg/dl",
          },
          {
            label: "Total Cholesterol",
            reference: "Reference range is 140 - 200 mg/dl",
            value: latestReport["total_cholestrol"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "mg/dl",
          },
          {
            label: "VLDL",
            reference: "Reference range is 5 - 40 mg/dl",
            value: latestReport["vldl"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "mg/dl",
          },
          {
            label: "RBC Count",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "4.7 - 6.1 million cell/mcl"
                : "4.2 - 5.4 million cell/mcl"),
            value: latestReport["rbc_count"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "million cell/mcl",
          },
          {
            label: "Mean Corpuscular Value",
            reference: "Reference range is 80 – 100 fl",
            value: latestReport["mean_corpuscular_volume"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "fl",
          },
          {
            label: "Mean Corpuscular Haemoglobin",
            reference: "Reference range is 27 - 31 pg//cells",
            value: latestReport["mean_corpuscular_hemoglobin"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "pg//cells",
          },
          {
            label: "Haemoglobin",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "13 - 17 g/dl"
                : "11.6 - 15.5 g/dl"),
            value: latestReport["hemoglobin"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "g/dl",
          },
          {
            label: "Hematocrit",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "40 - 54%"
                : "36 - 48%"),
            value: latestReport["hematocrit"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "%",
          },
          {
            label: "Random Blood Sugar",
            reference: "Reference range is 70 – 140 mg/dl",
            value: latestReport["random_blood_sugar"],
            color1: "#C746D2",
            color2: "#C746D2",
            barcolor: "#7B0C85",
            unit: "mg/dl",
          },
          {
            label: "HbA1c",
            reference: "Reference range is 4 - 5.6%",
            value: latestReport["hba1c"],
            color1: "#6652D3",
            color2: "#8B82BD",
            barcolor: "#554999",
            unit: "%",
          },
          {
            label: "Mean Plasma Glucose",
            reference: "Reference range is 72 - 100 mg/dl",
            value: latestReport["mean_plasma_glucose"],
            color1: "#3E67CF",
            color2: "#9BBDDC",
            barcolor: "#3E67CF",
            unit: "mg/dl",
          },
          {
            label: "Allowable Blood Loss",
            reference:
              "Reference range is " +
              (states.currentUser["gender"] === "Male"
                ? "75 ml/kg"
                : "65 ml/kg"),
            value: latestReport["allowable_blood_loss"],
            color1: "#701F8C",
            color2: "#C695D7",
            barcolor: "#9223B9",
            unit: "ml/kg",
          },
        ];
  }

  useEffect(() => {
    console.log(states.currentUser);
  }, []);

  return (
    <div className={classes.fullpage}>
      {window.innerWidth <= 768 && <MobileHeader />}
      {window.innerWidth > 768 ? (
        <></>
      ) : (
        <div className={classes.dashboardhead}>Dashboard</div>
      )}
      {window.innerWidth > 768 ? (
        <div className={classes.hellotext}>
          HELLO,
          <div className={classes.name}>
            {states.currentUser ? states.currentUser.name : ""}
          </div>
        </div>
      ) : (
        <div className={classes.hellonamediv}>
          <div className={classes.mobilehellotext}>
            <div className={classes.mobilename}>
              HELLO,{states.currentUser ? states.currentUser.name : ""}
            </div>
          </div>
        </div>
      )}
      <div className={classes.contdivs}>
        {window.innerWidth > 768 ? (
          <div
            className={classes.divitem}
            style={{ cursor: "pointer" }}
            onClick={() => {
              states.currentUser.email === "prakhar@aivot.in"
                ? navigate("/selftest1")
                : navigate("/selftest");
            }}
          >
            <div className={classes.itemhead}>Self Test</div>
            <div className={classes.boxdivitem}>
              <img src={manavatar} className={classes.imageman} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={classes.divitem}>
          <div className={classes.itemhead}>Personal Details</div>
          <div className={classes.boxdivitem}>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Name -</div>
              <div className={classes.actStaVal}>
                {states.currentUser ? states.currentUser.name : ""}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Age -</div>
              <div className={classes.actStaVal}>
                {states.currentUser ? calculateAge(states.currentUser.dob) : ""}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Gender -</div>
              <div className={classes.actStaVal}>
                {states.currentUser ? states.currentUser.gender : ""}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Height(m) -</div>
              <div className={classes.actStaVal}>
                {states.currentUser ? states.currentUser.height : ""}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Weight(kg) -</div>
              <div className={classes.actStaVal}>
                {states.currentUser ? states.currentUser.weight : ""}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.divitem}>
          <div className={classes.itemhead}>Activity Status</div>
          <div className={classes.boxdivitem}>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Yoga -</div>
              <div className={classes.actStaVal}>{states.currentUser.Yoga}</div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Sports -</div>
              <div className={classes.actStaVal}>
                {states.currentUser.Sports}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Meditation -</div>
              <div className={classes.actStaVal}>
                {states.currentUser.Meditation}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Exercise -</div>
              <div className={classes.actStaVal}>
                {states.currentUser.Exercise}
              </div>
            </div>
            <div className={classes.actStadiv}>
              <div className={classes.actStaLabel}>Jog -</div>
              <div className={classes.actStaVal}>{states.currentUser.Jog}</div>
            </div>
          </div>
        </div>
        {window.innerWidth > 768 ? (
          <div className={classes.divitem}>
            <div className={classes.itemhead}>Other Users</div>
            <div className={classes.boxdivitem1}>
              {allusers.length > 0 ? (
                allusers.map((users, idx) => (
                  <div
                    className={classes.imrstatusdiv}
                    key={idx}
                    onClick={() => findAndSHowThatUser(users)}
                  >
                    {users}
                  </div>
                ))
              ) : (
                <></>
              )}
              {allusers.length < 2 ? (
                <div
                  className={classes.imrstatusdiv}
                  onClick={() => {
                    navigate("/newuser");
                  }}
                >
                  Add User
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {window.innerWidth > 768 ? <></> : <></>}
      {!latestReport ? (
        <>
          {window.innerWidth > 768 ? (
            <div style={{ position: "fixed", bottom: 0 }}>
              <Footer />
            </div>
          ) : (
            <div style={{ marginTop: "2rem" }}></div>
          )}
        </>
      ) : (
        <>
          {window.innerWidth > 768 ? (
            <div className={classes.name} style={{ margin: "1vw" }}>
              Results
            </div>
          ) : (
            <div className={classes.name} style={{ margin: "1vw" }}>
              <div className={classes.mobileimrstatusdiv}>IMR Status</div>
            </div>
          )}
          {!viewmoreComp ? (
            <div className={classes.scrollview}>
              {cardData.length > 0 &&
                cardData.map((card, index) => (
                  <div
                    key={index}
                    className={classes.card}
                    style={{
                      background: `linear-gradient(to bottom, ${card.color1}, ${card.color2}`,
                    }}
                  >
                    <div className={classes.cardLabel}>{card.label}</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                          textAlign: "justify",
                          boxSizing: "border-box",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={classes.cardtext}>
                          Your {card.label} is {card.value}
                        </div>
                        <div className={classes.cardReference}>
                          {card.reference}
                        </div>
                      </div>

                      {window.innerWidth > 768 ? (
                        <CircularProgressbar
                          value={card.value}
                          text={`${card.value}`}
                          className={classes.progressBar}
                          strokeWidth={15}
                          styles={{
                            path: {
                              stroke: card.barcolor, // The stroke color based on barcolor attribute
                            },
                            trail: {
                              stroke: "#d6d6d6", // The trail color (background of the progress bar)
                            },
                            text: {
                              fill: "#fff", // The color of the text inside the progress bar
                              fontSize: "1.1vw",
                            },
                          }}
                        />
                      ) : (
                        <CircularProgressbar
                          value={card.value}
                          text={`${card.value}`}
                          className={classes.progressBar}
                          strokeWidth={15}
                          styles={{
                            path: {
                              stroke: card.barcolor, // The stroke color based on barcolor attribute
                            },
                            trail: {
                              stroke: "#d6d6d6", // The trail color (background of the progress bar)
                            },
                            text: {
                              fill: "#fff", // The color of the text inside the progress bar
                              fontSize: "5.1vw",
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <>
              {/* <div
                className={classes.viewlessdiv}
                onClick={() => setviewmoreComp(false)}
              >
                View less
              </div> */}
            </>
          )}
          {!viewmoreComp && window.innerWidth > 768 ? (
            <div
              className={classes.viewmoewdiv}
              onClick={() => navigate("/results")}
            >
              View More
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {window.innerWidth <= 768 ? (
        <>
          <div
            className={classes.testButton}
            onClick={() => navigate("/selftest")}
          >
            Take Test
          </div>
          <div className={classes.testButton} onClick={() => navigate("/imr")}>
            IMR Dashboard
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default window.innerWidth > 768
  ? AppLayout()(HelloPatient)
  : HelloPatient;
