import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { SavedStateContext } from "../../context/SavedState";
import classes from "./Register.module.css";
import DatePicker from "react-datepicker";
import { CalendarMonth } from "@mui/icons-material";
import { format } from "date-fns";

const NewUserRegister = () => {
  const navigate = useNavigate();

  const states = useContext(SavedStateContext);

  const userData = states.currentUser;

  const initialFormData = {
    email: userData?.email || "",
    mobileNumber: userData?.mobileNumber || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const [currentAge, setCurrentAge] = useState();

  const fields1 = [
    { label: "Name", name: "name", placeholder: "Enter your name" },
    { label: "Email ID", name: "email" },
    {
      label: "Mobile Number",
      name: "mobileNumber",
      placeholder: "Enter your mobile number",
    },
  ];

  const fields2 = [
    { label: "Age", name: "age", placeholder: "Enter your age" },
    {
      label: "Height",
      name: "height",
      placeholder: "Enter your height (in meters)",
    },
    {
      label: "Weight",
      name: "weight",
      placeholder: "Enter your weight (in kg)",
    },
    { label: "State", name: "state", placeholder: "Enter your state" },
    { label: "PIN CODE", name: "pincode", placeholder: "Enter your pincode" },
  ];

  function calculateAge(dateString) {
    // Split the input date string to get day, month, and year
    let [day, month, year] = dateString.split("/").map(Number);

    // Create a date object from the input date
    let birthDate = new Date(year, month - 1, day);
    let today = new Date();

    // Calculate age
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGenderChange = (event) => {
    setFormData({ ...formData, ["gender"]: event.target.value });
    setGender(event.target.value);
  };
  const handleDobChange = (date) => {
    setDob(date);
    console.log(date);
    setCurrentAge(calculateAge(format(date, "dd/MM/yyyy")));
    setFormData({
      ...formData,
      ["dob"]: format(date, "dd/MM/yyyy"),
    });
  };
  // const validateForm = () => {
  //   const { name, email, mobileNumber } = formData;

  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const phoneRegex = /^\d{10}$/;

  //   if (!name) {
  //     alert("Please enter your name.");
  //     return false;
  //   }

  //   if (!email || !emailRegex.test(email)) {
  //     alert("Please enter a valid email address.");
  //     return false;
  //   }

  //   if (!mobileNumber || !phoneRegex.test(mobileNumber)) {
  //     alert("Please enter a valid 10-digit phone number.");
  //     return false;
  //   }

  //   return true;
  // };

  const handleSubmit = () => {
    const submitReg = document.getElementById("submitReg");
    submitReg.style.pointerEvents = "none";

    const {
      name,
      email,
      mobileNumber,
      age,
      gender,
      height,
      weight,
      state,
      pincode,
      dob,
    } = formData;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const ageRegex = /^\d+$/;
    const weightRegex = /^\d+(\.\d+)?$/;
    const pincodeRegex = /^\d{6}$/;
    const dobRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const stateRegex = /^[A-Za-z\s]+$/;
    const genderRegex = /^(Male|Female)$/;

    if (!name) {
      alert("Please enter your name.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!email || !emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!mobileNumber || !phoneRegex.test(mobileNumber)) {
      alert("Please enter a valid 10-digit phone number.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!gender || !genderRegex.test(gender)) {
      alert("Please select gender");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    const heightValue = parseFloat(height);
    if (!height || isNaN(heightValue) || heightValue < 1 || heightValue > 2.2) {
      alert("Please enter a valid height between 1 and 2.2 meters.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!weight || !weightRegex.test(formData.weight)) {
      alert("Please enter a valid weight.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!pincode || !pincodeRegex.test(pincode)) {
      alert("Please enter a valid 6-digit pincode.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!state || !stateRegex.test(state)) {
      alert("Please enter a valid state name containing only alphabets.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!dob || !dobRegex.test(dob)) {
      alert("Please enter a valid date of birth in the format MM-DD-YYYY.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    formData.accountNumber = formData["email"];
    formData.password = userData.password;

    const e = formData.email;
    console.log(formData);

    if (formData.email !== "") {
      console.log(states.users.length);
      if (states.users.length < 2) {
        axios
          .get("https://www.iterve.ai/uservitals/findsamesubuser", {
            params: { email: formData.email, name: formData.name },
          })
          .then((res) => {
            console.log(res);
            if (res.data !== "" || res.data) {
              alert(
                "A user with this name already exists. Try using a different name"
              );
              submitReg.style.pointerEvents = "auto";
              return;
            } else {
              console.log(formData);
              axios
                .post("https://www.iterve.ai/uservitals/childUser", formData)
                .then((res) => {
                  console.log(res);
                  states.setCurrentUser(res.data);

                  navigate("/dashboard");
                })
                .catch((err) => {
                  console.log(err);
                  submitReg.style.pointerEvents = "auto";
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("Already two sub-users created for this user.");
        submitReg.style.pointerEvents = "auto";
      }
    }
  };

  return (
    <div>
      <Header />
      <div className={classes.container}>
        <div className={classes.registertext}>REGISTER</div>
        <div className={classes.inputcontainers}>
          <div className={classes.leftcontainer}>
            {fields1.map((field, index) => (
              <div key={index} className={classes.lableNameWrap}>
                <div className={classes.inputlabel}>{field.label}:</div>
                <input
                  name={field.name}
                  id={field.name}
                  className={classes.inputtags}
                  onChange={handleInputChange}
                  value={formData[field.name] || ""}
                  autoComplete="on"
                  placeholder={field.placeholder}
                  readOnly={
                    field.name === "email" || field.name === "mobileNumber"
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
              </div>
            ))}
            <div className={classes.genderCont}>
              <label htmlFor="gender" className={classes.inputlabel}>
                Gender:{" "}
              </label>
              <select
                id="gender"
                value={gender}
                onChange={handleGenderChange}
                className={classes.inputtags}
              >
                <option value="" disabled>
                  Please select your gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <div
                className={classes.genderCont}
                style={{ position: "relative" }}
              >
                <div className={classes.inputlabel}>Date of Birth:</div>
                <DatePicker
                  placeholderText="Select your date of birth"
                  selected={dob}
                  onChange={handleDobChange}
                  className={classes.inputtags}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                />
                <CalendarMonth className={classes.calendarIcon} />
              </div>
            </div>
          </div>
          <div className={classes.rightcontainer}>
            {fields2.map((field, index) => (
              <div key={index} className={classes.lableNameWrap}>
                <div className={classes.inputlabel}>{field.label}:</div>
                <input
                  name={field.name}
                  id={field.name}
                  className={classes.inputtags}
                  onChange={handleInputChange}
                  value={
                    field.name === "age" ? currentAge : formData[field.name]
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  autoComplete="off"
                  placeholder={field.placeholder}
                  readOnly={field.name === "age" ? true : false}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.next} onClick={handleSubmit} id="submitReg">
          Submit
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewUserRegister;
