import React from "react";
import classes from "./MyDoctor.module.css";
import Menu from "../../Components/Menu/Menu";
import schedapp from "../../Images/Schedule.png";
import totdoc from "../../Images/Out Patient Department.png";
import videocon from "../../Images/Video Conference.png";
import phonecon from "../../Images/Call male.png";
import listdown from "../../Images/Drag List Down.png";
import doctorImg from "../../Images/avatarIcon.png";
import TitleBar from "../../Components/TitleBar/TitleBar";
import AppLayout from "../../layout/AppLayout/AppLayout";
import doctorimg from "../../Images/Rectangle 136.png";
import videoCall from "../../Images/Video Call.png";
import videoCall1 from "../../Images/Video Call (1).png";
import phonecall from "../../Images/Incoming Call.png";
import phonecall1 from "../../Images/Incoming Call (1).png";
import clinic from "../../Images/Clinic.png";
import clinic1 from "../../Images/Clinic (1).png";
import findclinic from "../../Images/Find Clinic.png";
import findclinic1 from "../../Images/Find Clinic (1).png";
import gyneacologist from "../../Images/Ellipse 107.png";
import pulmonologist from "../../Images/Ellipse 105.png";
import nephrologist from "../../Images/Ellipse 106.png";
import dermatologist from "../../Images/Ellipse 108.png";
import neurologist from "../../Images/Ellipse 109.png";

const MyDoctor = () => {
  const [selectedNav, setSelectedNav] = React.useState(null);
  const doctortype = [
    "Dermatology",
    "Cardiology",
    "Gynaecology",
    "ENT",
    "Pediatric",
    "Urology",
  ];

  const colors = [
    "#95C5F0",
    "#7AB8F2",
    "#6AA6DE",
    "#5699D7",
    "#318FCB",
    "#043E74",
  ];

  const doctors = [
    {
      name: "Dr. John Doe",
      designation: "Video Call Conferencing",
      image: doctorImg,
      smallimg1: videoCall,
      smallimg2: videoCall1,
    },
    {
      name: "Dr. Jane Smith",
      designation: "Phone Call Conferencing",
      image: doctorImg,
      smallimg1: phonecall,
      smallimg2: phonecall1,
    },
    {
      name: "Dr. Sam Wilson",
      designation: "Clinic Consulting",
      image: doctorImg,
      smallimg1: clinic,
      smallimg2: clinic1,
    },
    {
      name: "Dr. Lucy Liu",
      designation: "Find Doctor",
      image: doctorImg,
      smallimg1: findclinic,
      smallimg2: findclinic1,
    },
  ];

  const rounddiv = [
    { label: "Gynaecologist", image: gyneacologist },
    { label: "Pulmonologist", image: pulmonologist },
    { label: "Nephrologist", image: nephrologist },
    { label: "Dermatologist", image: dermatologist },
    { label: "Neurologist", image: neurologist },
  ];

  const handleClick = (navItem) => {
    if (selectedNav === navItem) {
      setSelectedNav(null);
    } else {
      setSelectedNav(navItem);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.minicont}>
        <div className={classes.headnav}>
          <div
            onClick={() => handleClick("appointment")}
            className={
              selectedNav === "appointment"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Appointment
          </div>
          <div
            onClick={() => handleClick("doctors")}
            className={
              selectedNav === "doctors"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            My Doctors
          </div>
          <div
            onClick={() => handleClick("schedule")}
            className={
              selectedNav === "schedule"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Scheduled Timing
          </div>
          <div
            onClick={() => handleClick("payments")}
            className={
              selectedNav === "payments"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Payments
          </div>
          <div
            onClick={() => handleClick("message")}
            className={
              selectedNav === "message"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Message
          </div>
        </div>
        <div className={classes.buttondiv}>
          <div className={classes.schedulediv}>
            <div className={classes.imgtextdiv}>
              <img src={schedapp} />
              <div className={classes.imgtext}>20</div>
            </div>
            Schedule Appointments
          </div>
          <div className={classes.totdoctordiv}>
            <div className={classes.imgtextdiv}>
              <img src={totdoc} />
              <div className={classes.imgtext}>20</div>
            </div>
            Total Doctors
          </div>
          <div className={classes.videoconsultdiv}>
            <div className={classes.imgtextdiv}>
              <img src={videocon} />
              <div className={classes.imgtext}>20</div>
            </div>
            Video Consulting
          </div>
          <div className={classes.phoneconsultdiv}>
            <div className={classes.imgtextdiv}>
              <img src={phonecon} />
              <div className={classes.imgtext}>20</div>
            </div>
            Phone Consulting
          </div>
        </div>
        <div className={classes.listdiv}>
          {doctors.map((d, idx) => (
            <div key={idx} className={classes.doctortypediv}>
              <div className={classes.doctorelediv}>
                <img src={doctorimg} className={classes.imgdoctor} />
                <div className={classes.labeldoctor}>
                  <img className={classes.smallimg} src={d.smallimg1} />
                  <div className={classes.labeltext}>{d.designation}</div>
                  <img className={classes.smallimg} src={d.smallimg2} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.text2}>Choose Doctor For Appointment</div>
        <div className={classes.doctorchoose}>
          {rounddiv.map((d, idx) => (
            <div key={idx} className={classes.doctortypediv}>
              <div>
                <img src={d.image} className={classes.roundimg} />
                <div className={classes.imglabel}>{d.label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppLayout()(MyDoctor);
