import React from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import bodyParamImage from "../../Images/rbc.png";

const OxygenSaturation = () => {
  const topic = "Hemoglobin";
  const params = [
    "Mean Corpuscular Hemoglobin",
    "Hemoglobin",
    "Hematocrit",
    "HbA1c",
  ];
  const valuesOfParams = ["240 mg/dl", "40 mmHg", "84 mmHg", "6.51 L/min"];
  const name = "Hemoglobin";
  const value = "45.82%";

  return (
    <BodyParamsLayout
      topic={topic}
      params={params}
      name={name}
      img={bodyParamImage}
    />
  );
};

export default AppLayout()(OxygenSaturation);
