import React, { useContext, useEffect, useState } from "react";
import classes from "./PasswordChange.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import MobileFooter from "../../Components/Footer/MobileFooter";

const PasswordChange = () => {
  const navigate = useNavigate();
  const [password, setpassword] = useState("");
  const [currpassword, setcurrpassword] = useState("");
  const [verpass, setverpass] = useState("");
  const [orgOtp, setOrgOtp] = useState("");
  const [thisotp, setthisotp] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showVerPassword, setshowVerPassword] = useState(false);

  const states = useContext(SavedStateContext);

  const handleSubmit = () => {
    const signIn = document.getElementById("signIn");
    // signIn.style.pointerEvents = "none";

    if (passwordError !== "") {
      alert("Password is not in valid format");
      document.getElementById("pass").focus();
      signIn.style.pointerEvents = "auto";
      return;
    }
    if (password !== verpass) {
      alert("Passwords do not match");
      document.getElementById("verPass").focus();
      signIn.style.pointerEvents = "auto";

      return;
    }

    console.log(currpassword, password, verpass);

    axios
      .put("https://www.iterve.ai/uservitals/updatepassword", {
        params: {
          email: states.currentUser.email,
          currpassword: currpassword,
          password: password,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.message === "incur") {
          alert("Incorrect current password");
          return;
        }
        alert("Password Changed Successfully");
        navigate("/dashboard");
      });
  };

  const validatePassword = (pass) => {
    const minLength = /.{8,}/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const id = document.getElementById("pass");

    setpassword(pass);
    if (!minLength.test(pass)) {
      setpasswordError("Password must be at least 8 characters long.");
      return;
    } else if (!hasNumber.test(pass)) {
      setpasswordError("Password must contain at least one number.");
      return;
    } else if (!hasSpecialChar.test(pass)) {
      setpasswordError("Password must contain at least one special character.");
      return;
    } else if (!hasUpperCase.test(pass)) {
      setpasswordError("Password must contain at least one uppercase letter.");
      return;
    }
    setpasswordError("");
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headertext}>CHANGE PASSWORD</div>
        <div className={classes.inputconatiner}>
          <div style={{ position: "relative" }}>
            <div className={classes.inputlabel}>Current Password - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => setcurrpassword(e.target.value)}
              value={currpassword}
              type={showPassword ? "text" : "password"}
            />
            {showPassword ? (
              <div>
                {window.innerWidth > 768 ? (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "2vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "21px",
                      height: "21px",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                {window.innerWidth > 768 ? (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "2vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "21px",
                      height: "21px",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <div className={classes.inputlabel}>New Password - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => validatePassword(e.target.value)}
              value={password}
              type={showPassword ? "text" : "password"}
            />
            {showPassword ? (
              <div>
                {window.innerWidth > 768 ? (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "2vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "21px",
                      height: "21px",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                {window.innerWidth > 768 ? (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "2vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "21px",
                      height: "21px",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            )}
            <div className={classes.passwordError}>{passwordError}</div>
          </div>
          <div style={{ position: "relative" }}>
            <div className={classes.inputlabel}>Confirm New Password - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => setverpass(e.target.value)}
              value={verpass}
              id="verPass"
              type={showVerPassword ? "text" : "password"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            {showVerPassword ? (
              <div>
                {window.innerWidth > 768 ? (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "2vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "21px",
                      height: "21px",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                {window.innerWidth > 768 ? (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "2vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "21px",
                      height: "21px",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className={classes.smalltext}>
            Password should contain minimum one number, one special character,
            one upper case letter and minimum 8 letters.
          </div>
          <div className={classes.signin} onClick={handleSubmit} id="signIn">
            Submit
          </div>
        </div>
      </div>
      {window.innerWidth <= 768 && <MobileFooter />}
    </div>
  );
};

export default AppLayout()(PasswordChange);
