import React from "react";
import Calendar from "react-calendar"; // Import the Calendar component
import "react-calendar/dist/Calendar.css"; // Import the CSS for the calendar
import classes from "./Appointment.module.css";
import schedapp from "../../Images/Schedule.png";
import totdoc from "../../Images/Out Patient Department.png";
import videocon from "../../Images/Video Conference.png";
import phonecon from "../../Images/Call male.png";
import listdown from "../../Images/Drag List Down.png";
import doctorImg from "../../Images/avatarIcon.png";
import TitleBar from "../../Components/TitleBar/TitleBar";
import AppLayout from "../../layout/AppLayout/AppLayout";
import AppointmentHeader from "../../Components/AppointmentHeader/AppointmentHeader";

const Appointment = () => {
  const [selectedNav, setSelectedNav] = React.useState(null);
  const [date, setDate] = React.useState(new Date());

  const doctortype = [
    "Dermatology",
    "Cardiology",
    "Gynaecology",
    "ENT",
    "Pediatric",
    "Urology",
    "Physician",
    "Pulmonologist",
    "Nephrologist",
    "Neurologist",
  ];

  const colors = [
    "#95C5F0",
    "#7AB8F2",
    "#6AA6DE",
    "#5699D7",
    "#318FCB",
    "#043E74",
    "#02588E",
    "#004169",
    "#002B47",
    "#00121E",
  ];

  const doctors = [
    { name: "Dr. John Doe", designation: "Cardiologist", image: doctorImg },
    { name: "Dr. Jane Smith", designation: "Dermatologist", image: doctorImg },
    { name: "Dr. Sam Wilson", designation: "Pediatrician", image: doctorImg },
    { name: "Dr. Lucy Liu", designation: "ENT Specialist", image: doctorImg },
  ];

  const handleClick = (navItem) => {
    if (selectedNav === navItem) {
      setSelectedNav(null);
    } else {
      setSelectedNav(navItem);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.minicont}>
        {/* <div className={classes.headnav}>
          <div
            onClick={() => handleClick("appointment")}
            className={
              selectedNav === "appointment"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Appointment
          </div>
          <div
            onClick={() => handleClick("doctors")}
            className={
              selectedNav === "doctors"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            My Doctors
          </div>
          <div
            onClick={() => handleClick("schedule")}
            className={
              selectedNav === "schedule"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Scheduled Timing
          </div>
          <div
            onClick={() => handleClick("payments")}
            className={
              selectedNav === "payments"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Payments
          </div>
          <div
            onClick={() => handleClick("message")}
            className={
              selectedNav === "message"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Message
          </div>
        </div>
        <div className={classes.buttondiv}>
          <div className={classes.schedulediv}>
            <div className={classes.imgtextdiv}>
              <img src={schedapp} />
              <div className={classes.imgtext}>20</div>
            </div>
            Schedule Appointments
          </div>
          <div className={classes.totdoctordiv}>
            <div className={classes.imgtextdiv}>
              <img src={totdoc} />
              <div className={classes.imgtext}>20</div>
            </div>
            Total Doctors
          </div>
          <div className={classes.videoconsultdiv}>
            <div className={classes.imgtextdiv}>
              <img src={videocon} />
              <div className={classes.imgtext}>20</div>
            </div>
            Video Consulting
          </div>
          <div className={classes.phoneconsultdiv}>
            <div className={classes.imgtextdiv}>
              <img src={phonecon} />
              <div className={classes.imgtext}>20</div>
            </div>
            Phone Consulting
          </div>
        </div> */}
        <AppointmentHeader />
        <div className={classes.listdiv}>
          <div className={classes.colnames}>
            <div className={classes.doctypehead}>
              Choose Category For Appointment
              <img className={classes.downimg} src={listdown} />
            </div>
            {doctortype.map((type, idx) => (
              <div
                key={idx}
                className={classes.doctortypediv}
                style={{ backgroundColor: colors[idx % colors.length] }}
              >
                {type}
              </div>
            ))}
          </div>
          <div className={classes.colnames}>
            Choose Doctor For Appointment
            {doctors.map((doctor, idx) => (
              <div key={idx} className={classes.doctorcard}>
                <img
                  src={doctor.image}
                  alt={doctor.name}
                  className={classes.doctorimage}
                />
                <div className={classes.doctordetails}>
                  <div className={classes.doctorname}>{doctor.name}</div>
                  <div className={classes.doctordesignation}>
                    {doctor.designation}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.colnames}>
            Last Appointment
            {doctors.map((doctor, idx) => (
              <div key={idx} className={classes.doctorcard}>
                <img
                  src={doctor.image}
                  alt={doctor.name}
                  className={classes.doctorimage}
                />
                <div className={classes.doctordetails}>
                  <div className={classes.doctorname}>{doctor.name}</div>
                  <div className={classes.doctordesignation}>
                    {doctor.designation}
                  </div>
                </div>
              </div>
            ))}
            <div className={classes.calendertext}>Calender</div>
            <div className={classes.calendarContainer}>
              <Calendar onChange={setDate} value={date} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout()(Appointment);
