import React from "react";
import aivotLogo from "../../Images/aivotLogo.png";
import watermark from "../../Images/watermark.png";
import HiimsLogo from "../../Images/aivotLogo.png";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Define your styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  container: {
    display: "flex",
    padding: "0vw 5vw ",
    justifyContent: "center",
    alignItems: "center",
  },
  headingRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 0 1vw 0",
    // height: "auto",
    alignItems: "flex-end",
    margin: "0.5vw 0",
    // backgroundColor: 'blue',
  },
  logo: {
    height: "6vw",
  },
  headingText: {
    fontSize: "3.5vw",
    fontWeight: "bold",
  },
  headingTextReq: {
    fontSize: "4vw",
    fontWeight: "bold",
    marginTop: "2vw",
    marginBottom: "2vw",
  },
  logo1: {
    height: "7vw",
    width: "7vw",
  },

  logo1VisOff: {
    width: "10vw",
    height: "10vw",
    display: "none",
  },

  infoSec: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoLabel: {
    fontSize: "1.5vw",
    border: "0.1vw solid black",
  },
  name: {
    width: "45vw",
    display: "flex",
    flexDirection: "row",
    fontSize: "1.8vw",
    alignItems: "center",
    padding: "1vw 0",
  },
  email: {
    width: "45vw",
    display: "flex",
    flexDirection: "row",
    fontSize: "1.8vw",
    alignItems: "center",
    padding: "1vw 0",
  },
  phoneNumber: {
    width: "15vw",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    // border: "0.1vw solid black",
  },
  subject: {
    width: "15vw",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    // border: "0.1vw solid black",
  },
  gender: {
    width: "15vw",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    // border: "0.1vw solid black",
  },
  age: {
    width: "15vw",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    // border: "0.1vw solid black",
  },
  height: {
    width: "15vw",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    // border: "0.1vw solid black",
  },
  weight: {
    width: "15vw",
    display: "flex",
    fontSize: "1.8vw",
    justifyContent: "center",
    alignItems: "center",
    padding: "1vw 0",
    // border: "0.1vw solid black",
  },
  vitalRows: {
    display: "flex",
    flexDirection: "column",
    padding: "1vw 0",
    width: "100%",
  },
  req_userVitalRows: {
    display: "flex",
    flexDirection: "column",
    gap: "4vw",
    padding: "2vw 0",
    width: "100%",
    marginTop: "4vw",
  },
  vitalsCol: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    // backgroundColor: "grey",
    padding: "0.5vw 0",
  },
  vital: {
    width: "40vw",
    fontWeight: "bold",
    fontSize: "2vw",
    // backgroundColor: "yellow",
  },
  value: {
    width: "15vw",
    fontSize: "1.7vw",
    color: "#303030",
    // backgroundColor: "blue",
  },
  units: {
    width: "15vw",
    fontSize: "1.7vw",
    color: "#303030",
    // backgroundColor: "red",
  },
  range: {
    width: "20vw",
    fontSize: "1.7vw",
    color: "#303030",
    // backgroundColor: "green",
  },

  watermark: {
    position: "absolute",
    minWidth: "280%",
    minHeight: "200%",
    display: "block",
    height: "100%",
    width: "100%",
    opacity: 0.5,
  },
  // watermark1: {
  //   position: "absolute",
  //   minWidth: "20%",
  //   minHeight: "20%",
  //   display: "block",
  //   height: "10%",
  //   width: "80%",
  //   opacity: 1,
  // },
  noteSec: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1.7vw",
    marginTop: "0.5vw",
  },
  noteSecReq: {
    display: "flex",
    justifyContent: "center",
    fontSize: "2vw",
    marginTop: "4vw",
  },
  bold: {
    fontWeight: "bold",
  },
});

// Create your PDF component
const MyPDF = ({ healthParameters, formData, accno }) => {
  console.log(accno);
  return (
    <Document>
      <Page size={"A4"}>
        <View style={styles.container}>
          <Image src={watermark} style={styles.watermark} />
          <View style={styles.headingRow}>
            <View style={{ width: "15vw" }}>
              <Image
                src={HiimsLogo}
                alt="Hiims logo here"
                style={
                  accno.startsWith("Hiims") ? styles.logo1 : styles.logo1VisOff
                }
              />
            </View>
            <Text
              style={
                accno === "req_user"
                  ? styles.headingTextReq
                  : styles.headingText
              }
            >
              Your Vitals
            </Text>
            <View style={{ width: "15vw" }}>
              <Image
                src={aivotLogo}
                alt="aivot logo here"
                style={styles.logo}
              />
            </View>
          </View>
          <View style={styles.infoSec}>
            <View
              style={[
                styles.name,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                },
              ]}
            >
              <Text
                style={{
                  padding: "0 2vw",
                }}
              >
                Name:
              </Text>

              <Text>{formData.name}</Text>
            </View>
            <View
              style={[
                styles.email,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderRight: "0.1vw solid black",
                },
              ]}
            >
              <Text
                style={{
                  padding: "0 2vw",
                }}
              >
                Email:
              </Text>
              <Text>{formData.email}</Text>
            </View>
          </View>
          <View style={styles.infoSec}>
            <View
              style={[
                styles.phoneNumber,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                },
              ]}
            >
              <Text>PhoneNumber</Text>
            </View>
            <View
              style={[
                styles.subject,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                },
              ]}
            >
              <Text>Subject ID</Text>
            </View>
            <View
              style={[
                styles.gender,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                },
              ]}
            >
              <Text>Gender</Text>
            </View>
            <View
              style={[
                styles.age,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                },
              ]}
            >
              <Text>Age</Text>
            </View>
            <View
              style={[
                styles.height,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                },
              ]}
            >
              <Text>Height</Text>
            </View>
            <View
              style={[
                styles.weight,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderRight: "0.1vw solid black",
                },
              ]}
            >
              <Text>Weight</Text>
            </View>
          </View>
          <View style={styles.infoSec}>
            <View
              style={[
                styles.phoneNumber,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderBottom: "0.1vw solid black",
                },
              ]}
            >
              <Text>{formData.phoneNumber}</Text>
            </View>
            <View
              style={[
                styles.subject,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderBottom: "0.1vw solid black",
                },
              ]}
            >
              <Text>{formData.subjectId}</Text>
            </View>
            <View
              style={[
                styles.gender,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderBottom: "0.1vw solid black",
                },
              ]}
            >
              <Text>{formData.gender}</Text>
            </View>
            <View
              style={[
                styles.age,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderBottom: "0.1vw solid black",
                },
              ]}
            >
              <Text>{formData.age}</Text>
            </View>
            <View
              style={[
                styles.height,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderBottom: "0.1vw solid black",
                },
              ]}
            >
              <Text>{formData.height}</Text>
            </View>
            <View
              style={[
                styles.weight,
                {
                  borderTop: "0.1vw solid black",
                  borderLeft: "0.1vw solid black",
                  borderBottom: "0.1vw solid black",
                  borderRight: "0.1vw solid black",
                },
              ]}
            >
              <Text>{formData.weight}</Text>
            </View>
          </View>

          <View
            style={
              accno === "req_user" ? styles.req_userVitalRows : styles.vitalRows
            }
          >
            <View style={styles.vitalsCol}>
              <View style={styles.vital}>
                <Text style={{ fontSize: "2.3vw", color: "black" }}>
                  Vitals
                </Text>
              </View>
              <View style={styles.value}>
                <Text style={{ fontSize: "2.3vw", color: "black" }}>
                  Values
                </Text>
              </View>
              <View style={styles.units}>
                <Text style={{ fontSize: "2.3vw", color: "black" }}>Units</Text>
              </View>
              <View style={styles.range}>
                <Text style={{ fontSize: "2.3vw", color: "black" }}>
                  Reference Ranges
                </Text>
              </View>
            </View>
            {healthParameters.map((param, index) => (
              <View style={styles.vitalsCol} key={index}>
                <View style={styles.vital}>
                  <Text>{param.vital}</Text>
                </View>
                <View style={styles.value}>
                  <Text>{param.value}</Text>
                </View>
                <View style={styles.units}>
                  <Text>{param.unit}</Text>
                </View>
                <View style={styles.range}>
                  <Text>{param.referenceRange}</Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={accno === "req_user" ? styles.noteSecReq : styles.noteSec}
          >
            <Text>
              <Text style={{ fontSize: "2vw" }}>Note: </Text>These values are
              only indicative, cannot be used for clinical. The vitals marked as
              <Text style={{ fontSize: "2vw" }}>* (asterisk)</Text> are under
              evaluation for testing the models.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// export default MyPDF;

// import React from "react";
// import aivotLogo from "../../Images/aivotLogo.png";
// import watermark from "../../Images/watermark.png";
// import HiimsLogo from "../../Images/HIIMSLogo.png";

// Define your styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//   },
//   container: {
//     display: "flex",
//     padding: "0vw 5vw ",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   headingRow: {
//     width: "100%",
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     padding: "0 0 1vw 0",
//     // height: "auto",
//     alignItems: "flex-end",
//     margin: "1vw 0",
//     // backgroundColor: 'blue',
//   },
//   logo: {
//     height: "6vw",
//   },
//   headingText: {
//     fontSize: "3.5vw",
//     fontWeight: "bold",
//   },
//   headingTextReq: {
//     fontSize: "4vw",
//     fontWeight: "bold",
//     marginTop: "2vw",
//     marginBottom: "2vw",
//   },
//   logo1: {
//     height: "7vw",
//     width: "7vw",
//   },

//   logo1VisOff: {
//     width: "10vw",
//     height: "10vw",
//     display: "none",
//   },

//   infoSec: {
//     display: "flex",
//     width: "100%",
//     flexDirection: "row",
//     justifyContent: "space-around",
//   },
//   infoLabel: {
//     fontSize: "1.5vw",
//     border: "0.1vw solid black",
//   },
//   name: {
//     width: "45vw",
//     display: "flex",
//     flexDirection: "row",
//     fontSize: "1.8vw",
//     alignItems: "center",
//     padding: "1vw 0",
//   },
//   email: {
//     width: "45vw",
//     display: "flex",
//     flexDirection: "row",
//     fontSize: "1.8vw",
//     alignItems: "center",
//     padding: "1vw 0",
//   },
//   phoneNumber: {
//     width: "15vw",
//     display: "flex",
//     fontSize: "1.8vw",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "1vw 0",
//     // border: "0.1vw solid black",
//   },
//   subject: {
//     width: "15vw",
//     display: "flex",
//     fontSize: "1.8vw",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "1vw 0",
//     // border: "0.1vw solid black",
//   },
//   gender: {
//     width: "15vw",
//     display: "flex",
//     fontSize: "1.8vw",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "1vw 0",
//     // border: "0.1vw solid black",
//   },
//   age: {
//     width: "15vw",
//     display: "flex",
//     fontSize: "1.8vw",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "1vw 0",
//     // border: "0.1vw solid black",
//   },
//   height: {
//     width: "15vw",
//     display: "flex",
//     fontSize: "1.8vw",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "1vw 0",
//     // border: "0.1vw solid black",
//   },
//   weight: {
//     width: "15vw",
//     display: "flex",
//     fontSize: "1.8vw",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "1vw 0",
//     // border: "0.1vw solid black",
//   },
//   vitalRows: {
//     display: "flex",
//     flexDirection: "column",
//     padding: "2vw 0",
//     width: "100%",
//   },
//   req_userVitalRows: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "4vw",
//     padding: "2vw 0",
//     width: "100%",
//     marginTop: "4vw",
//   },
//   vitalsCol: {
//     display: "flex",
//     width: "100%",
//     flexDirection: "row",
//     // backgroundColor: "grey",
//     padding: "0.5vw 0",
//   },
//   vital: {
//     width: "40vw",
//     fontWeight: "bold",
//     fontSize: "2vw",
//     // backgroundColor: "yellow",
//   },
//   value: {
//     width: "15vw",
//     fontSize: "1.7vw",
//     color: "#303030",
//     // backgroundColor: "blue",
//   },
//   units: {
//     width: "15vw",
//     fontSize: "1.7vw",
//     color: "#303030",
//     // backgroundColor: "red",
//   },
//   range: {
//     width: "20vw",
//     fontSize: "1.7vw",
//     color: "#303030",
//     // backgroundColor: "green",
//   },

//   watermark: {
//     position: "absolute",
//     minWidth: "280%",
//     minHeight: "200%",
//     display: "block",
//     height: "100%",
//     width: "100%",
//     opacity: 0.5,
//   },
//   // watermark1: {
//   //   position: "absolute",
//   //   minWidth: "20%",
//   //   minHeight: "20%",
//   //   display: "block",
//   //   height: "10%",
//   //   width: "80%",
//   //   opacity: 1,
//   // },
//   noteSec: {
//     display: "flex",
//     justifyContent: "center",
//     fontSize: "1.7vw",
//     marginTop: "0.5vw",
//   },
//   noteSecReq: {
//     display: "flex",
//     justifyContent: "center",
//     fontSize: "2vw",
//     marginTop: "4vw",
//   },
// });

// // Create your PDF component
// const MyPDF = ({ healthParameters, formData, accno }) => {
//   console.log(accno);
//   return (
//     <Document>
//       <Page size={"A4"}>
//         <View style={styles.container}>
//           <Image src={watermark} style={styles.watermark} />
//           <View style={styles.headingRow}>
//             <View style={{ width: "15vw" }}>
//               <Image
//                 src={aivotLogo}
//                 alt="Hiims logo here"
//                 style={styles.logo1VisOff}
//               />
//             </View>
//             <Text style={styles.headingTextReq}>Your Vitals</Text>
//             <View style={{ width: "15vw" }}>
//               <Image
//                 src={aivotLogo}
//                 alt="aivot logo here"
//                 style={styles.logo}
//               />
//             </View>
//           </View>
//           <View style={styles.infoSec}>
//             <View
//               style={[
//                 styles.name,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text
//                 style={{
//                   padding: "0 2vw",
//                 }}
//               >
//                 Name:
//               </Text>

//               <Text>{formData.name}</Text>
//             </View>
//             <View
//               style={[
//                 styles.email,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderRight: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text
//                 style={{
//                   padding: "0 2vw",
//                 }}
//               >
//                 Email:
//               </Text>
//               <Text>{formData.email}</Text>
//             </View>
//           </View>
//           <View style={styles.infoSec}>
//             <View
//               style={[
//                 styles.phoneNumber,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>PhoneNumber</Text>
//             </View>
//             <View
//               style={[
//                 styles.subject,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>Subject ID</Text>
//             </View>
//             <View
//               style={[
//                 styles.gender,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>Gender</Text>
//             </View>
//             <View
//               style={[
//                 styles.age,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>Age</Text>
//             </View>
//             <View
//               style={[
//                 styles.height,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>Height</Text>
//             </View>
//             <View
//               style={[
//                 styles.weight,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderRight: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>Weight</Text>
//             </View>
//           </View>
//           <View style={styles.infoSec}>
//             <View
//               style={[
//                 styles.phoneNumber,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderBottom: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>{formData.phoneNumber}</Text>
//             </View>
//             <View
//               style={[
//                 styles.subject,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderBottom: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>{formData.subjectId}</Text>
//             </View>
//             <View
//               style={[
//                 styles.gender,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderBottom: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>{formData.gender}</Text>
//             </View>
//             <View
//               style={[
//                 styles.age,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderBottom: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>{formData.age}</Text>
//             </View>
//             <View
//               style={[
//                 styles.height,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderBottom: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>{formData.height}</Text>
//             </View>
//             <View
//               style={[
//                 styles.weight,
//                 {
//                   borderTop: "0.1vw solid black",
//                   borderLeft: "0.1vw solid black",
//                   borderBottom: "0.1vw solid black",
//                   borderRight: "0.1vw solid black",
//                 },
//               ]}
//             >
//               <Text>{formData.weight}</Text>
//             </View>
//           </View>

//           <View style={styles.req_userVitalRows}>
//             <View style={styles.vitalsCol}>
//               <View style={styles.vital}>
//                 <Text style={{ fontSize: "2.3vw", color: "black" }}>
//                   Vitals
//                 </Text>
//               </View>
//               <View style={styles.value}>
//                 <Text style={{ fontSize: "2.3vw", color: "black" }}>
//                   Values
//                 </Text>
//               </View>
//               <View style={styles.units}>
//                 <Text style={{ fontSize: "2.3vw", color: "black" }}>Units</Text>
//               </View>
//               <View style={styles.range}>
//                 <Text style={{ fontSize: "2.3vw", color: "black" }}>
//                   Reference Ranges
//                 </Text>
//               </View>
//             </View>
//             {healthParameters.map((param, index) => (
//               <View style={styles.vitalsCol} key={index}>
//                 <View style={styles.vital}>
//                   <Text>{param.vital}</Text>
//                 </View>
//                 <View style={styles.value}>
//                   <Text>{param.value}</Text>
//                 </View>
//                 <View style={styles.units}>
//                   <Text>{param.unit}</Text>
//                 </View>
//                 <View style={styles.range}>
//                   <Text>{param.referenceRange}</Text>
//                 </View>
//               </View>
//             ))}
//           </View>
//           <View style={styles.noteSec}>
//             <Text>
//               <Text style={{ fontSize: "2vw" }}>Note: </Text>These values are
//               only indicative, cannot be used for clinical. The vitals marked as
//               <Text style={{ fontSize: "2vw" }}>* (asterisk)</Text> are under
//               evaluation for testing the models.
//             </Text>
//           </View>
//         </View>
//       </Page>
//     </Document>
//   );
// };

export default MyPDF;
