import React, { useContext, useState } from "react";
import classes from "./EmailChange.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import MobileFooter from "../../Components/Footer/MobileFooter";

const EmailChange = () => {
  const navigate = useNavigate();
  const [curremail, setcurremail] = useState("");
  const [prevemail, setprevemail] = useState("");
  const [newemail, setnewemail] = useState("");
  const [emailError, setemailError] = useState("");
  const [showDialog, setShowDialog] = useState(false); // State to control dialog visibility
  const [otp, setOtp] = useState(""); // State to capture OTP input

  const states = useContext(SavedStateContext);

  const validateEmail = (email) => {
    const hasAtSymbol = /@/;
    const domainRegex = /@aivot\.ai$/;

    setnewemail(email);
    if (!hasAtSymbol.test(email)) {
      setemailError("Email must contain @.");
      return;
    } else if (!domainRegex.test(email)) {
      setemailError("Email must be from the domain aivot.ai.");
      return;
    }
    setemailError(""); // No errors
  };

  const handleSubmit = () => {
    validateEmail(newemail);

    if (emailError !== "") {
      alert("Invalid email format.");
      return;
    }
    axios
      .get("https://www.iterve.ai/otp/getemail", {
        params: { email: newemail },
      })
      .then(async (res) => {
        console.log(res);
        if (res.data) {
          axios
            .delete("https://www.iterve.ai/otp/deleteotp", {
              params: { email: res.data.email },
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        axios
          .post("https://www.iterve.ai/otp/postotp", {
            params: { email: newemail },
          })
          .then((res) => {
            console.log(res);
            setShowDialog(true);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    // Show OTP dialog
  };

  const submitOtp = async () => {
    console.log("OTP Submitted: ", otp);

    await axios
      .get("https://www.iterve.ai/otp/getemail", {
        params: { email: newemail },
      })
      .then((res) => {
        console.log(res.data);
        if (otp !== res.data.otp) {
          alert("OTP is incorrect");
          return;
        } else {
          axios
            .put("https://www.iterve.ai/uservitals/updateemail", {
              params: {
                email: states.currentUser.email,
                curremail: states.currentUser.email,
                newemail: newemail,
              },
            })
            .then((res) => {
              console.log(res);
              if (res.data.message === "incur") {
                alert("Incorrect current Email");
                return;
              }
              states.setCurrentUser(res.data);
              alert("Email Changed Successfully");
              setTimeout(() => {
                navigate("/dashboard");
              }, 1000);
              setShowDialog(false);
            });
        }
      })
      .catch((err) => {
        console.log("error in getting otp: " + err.message);
      });
    console.log("otp", otp);

    // Implement your OTP verification logic here
    // Close dialog after OTP submission
  };

  return (
    <div>
      <div className={showDialog ? classes.blurbackground : ""}>
        <div className={classes.container}>
          <div className={classes.headertext}>CHANGE EMAIL</div>
          <div className={classes.inputconatiner}>
            <div style={{ position: "relative" }}>
              <div className={classes.inputlabel}>Email ID - </div>
              <input
                className={classes.inputtags}
                readOnly
                value={states.currentUser.email}
                type="email"
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className={classes.inputlabel}>New Email ID - </div>
              <input
                className={classes.inputtags}
                onChange={(e) => setnewemail(e.target.value)}
                value={newemail}
                id="newemail"
                type="email"
              />
            </div>
            <div className={classes.signin} onClick={handleSubmit} id="signIn">
              Submit
            </div>
          </div>
        </div>
        {window.innerWidth <= 768 && <MobileFooter />}
      </div>

      {/* OTP Dialog */}
      {showDialog && (
        <div className={classes.dialogoverlay}>
          <div className={classes.dialogbox}>
            <div className={classes.dialogheader}>
              Enter the OTP sent to the email
            </div>
            <input
              className={classes.dialoginput}
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
            />
            <div className={classes.submitbuttondiv}>
              <button
                className={classes.dialogbutton1}
                onClick={() => navigate("/settings")}
              >
                Cancel
              </button>
              <button className={classes.dialogbutton} onClick={submitOtp}>
                Submit OTP
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppLayout()(EmailChange);
