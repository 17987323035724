import React from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import bodyParamImage from "../../Images/rbs.png";

const OxygenSaturation = () => {
  const topic = "Random Blood Sugar";
  const params = ["Mean Plasma Glucose", "Hemoglobin", "Hematocrit", "HbA1c"];
  const valuesOfParams = ["240 mg/dl", "40 mmHg", "84 mmHg", "6.51 L/min"];
  const name = "Random Blood Sugar";
  const value = "45.82%";

  return (
    <BodyParamsLayout
      topic={topic}
      params={params}
      name={name}
      img={bodyParamImage}
    />
  );
};

export default AppLayout()(OxygenSaturation);
