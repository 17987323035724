import React, { useEffect, useState } from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import Heart from "../../Images/heart.png";

const HeartParams = () => {
  const topic = "Heart";
  const params = [
    "SD1/SD2",
    "IBI",
    "SDNN",
    "RMSSD",
    "Cardiac Output",
    "Stroke Volume",
  ];

  const name = "Heart Rate";

  return (
    <BodyParamsLayout topic={topic} params={params} name={name} img={Heart} />
  );
};

export default AppLayout()(HeartParams);
