import React, { useContext, useEffect, useState } from "react";
import classes from "./Password.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SavedStateContext } from "../../context/SavedState";
import MobileFooter from "../../Components/Footer/MobileFooter";
import MobileHeader from "../../Components/Header/MobileHeader";

const PasswordCreate = () => {
  const navigate = useNavigate();
  const [password, setpassword] = useState("");
  const [verpass, setverpass] = useState("");
  const [orgOtp, setOrgOtp] = useState("");
  const [thisotp, setthisotp] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showVerPassword, setshowVerPassword] = useState(false);

  const location = useLocation();

  // const formData = location.state.formData;
  const { formData } = location.state || {};

  const email = formData.email;

  console.log(formData);

  useEffect(() => {
    axios
      .get("https://www.iterve.ai/otp/getemail", {
        params: { email: email },
      })
      .then((res) => {
        console.log(res.data);
        setOrgOtp(res.data.otp);
      })
      .catch((err) => {
        console.log("error in getting otp: " + err.message);
      });
  }, []);

  const handleSubmit = () => {
    console.log("submitting", formData);

    const signIn = document.getElementById("signIn");
    signIn.style.pointerEvents = "none";

    if (passwordError !== "") {
      alert("Password is not in valid format");
      document.getElementById("pass").focus();
      signIn.style.pointerEvents = "auto";
      return;
    }
    if (password !== verpass) {
      alert("Passwords do not match");
      document.getElementById("verPass").focus();
      signIn.style.pointerEvents = "auto";

      return;
    }
    if (thisotp !== orgOtp) {
      alert("OTP is incorrect");
      signIn.style.pointerEvents = "auto";

      return;
    }

    axios
      .delete("https://www.iterve.ai/otp/deleteotp", {
        params: { email: email },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    formData["password"] = password;
    console.log(formData);
    axios
      .post(`https://www.iterve.ai/uservitals`, formData)
      .then((res) => {
        console.log(res.data);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validatePassword = (pass) => {
    const minLength = /.{8,}/;
    const hasNumber = /[0-9]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const id = document.getElementById("pass");

    setpassword(pass);
    if (!minLength.test(pass)) {
      setpasswordError("Password must be at least 8 characters long.");
      return;
    } else if (!hasNumber.test(pass)) {
      setpasswordError("Password must contain at least one number.");
      return;
    } else if (!hasSpecialChar.test(pass)) {
      setpasswordError("Password must contain at least one special character.");
      return;
    } else if (!hasUpperCase.test(pass)) {
      setpasswordError("Password must contain at least one uppercase letter.");
      return;
    }
    setpasswordError("");
  };

  return (
    <div>
      {window.innerWidth > 768 ? (
        <Header />
      ) : (
        <MobileHeader heading={"Register"} />
      )}
      <div className={classes.container}>
        <div className={classes.headertext}>CREATE PASSWORD</div>
        <div className={classes.inputconatiner}>
          <div>
            <div className={classes.inputlabel}>Email ID - </div>
            <input className={classes.inputtags} value={email} />
          </div>
          <div style={{ position: "relative" }}>
            <div className={classes.inputlabel}>Password - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => validatePassword(e.target.value)}
              value={password}
              id="pass"
              type={showPassword ? "text" : "password"}
            />
            {showPassword ? (
              <div>
                {window.innerWidth > 768 ? (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "27px",
                      height: "27px",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                {window.innerWidth > 768 ? (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "27px",
                      height: "27px",
                    }}
                    onClick={() => {
                      setshowPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            )}
            <div className={classes.passwordError}>{passwordError}</div>
          </div>
          <div style={{ position: "relative" }}>
            <div className={classes.inputlabel}>Re-type Password - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => setverpass(e.target.value)}
              value={verpass}
              id="verPass"
              type={showVerPassword ? "text" : "password"}
            />
            {showVerPassword ? (
              <div>
                {window.innerWidth > 768 ? (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <Visibility
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "27px",
                      height: "27px",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            ) : (
              <div>
                {window.innerWidth > 768 ? (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "3.3vw",
                      cursor: "pointer",
                      width: "1.5vw",
                      height: "1.5vw",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      color: "#043E74",
                      position: "absolute",
                      right: "0.5vw",
                      top: "10vw",
                      cursor: "pointer",
                      width: "27px",
                      height: "27px",
                    }}
                    onClick={() => {
                      setshowVerPassword((prev) => !prev);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className={classes.smalltext}>
            Password should contain minimum one number, one special character,
            one upper case letter and minimum 8 letters.
          </div>
          <div>
            <div className={classes.inputlabel}>
              Enter OTP
              <div className={classes.smalltext}>
                (sent on registered email id)
              </div>
            </div>
            <input
              className={classes.inputtags}
              onChange={(e) => setthisotp(e.target.value)}
            ></input>
          </div>
          <div className={classes.signin} onClick={handleSubmit} id="signIn">
            Sign In
          </div>
        </div>
      </div>
      {window.innerWidth > 768 ? <Footer /> : <MobileFooter />}
    </div>
  );
};

export default PasswordCreate;
