import React, { useContext, useEffect, useState } from "react";
import classes from "./titalBar.module.css";
import { InputAdornment, TextField } from "@mui/material";
import { Flare, Search as SearchIcon } from "@mui/icons-material";
import notificationIcon from "../../Images/notificationIcon.png";
import settingicon from "../../Images/settingIcon.png";
import chatIcon from "../../Images/chatIcon.png";
import seperatorIcon from "../../Images/seperatorIcon.png";
import avatarIcon from "../../Images/avatarIcon.png";
import arrowIcon from "../../Images/arrowIcon.png";
import { SavedStateContext } from "../../context/SavedState";
import settingswhite from "../../Images/Settings.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TitleBar = () => {
  const states = useContext(SavedStateContext);

  const [allusers, setallusers] = useState([]);

  const email = states.currentUser.email;
  const name = states.currentUser.name;

  const navigate = useNavigate();

  useEffect(() => {
    console.log("email name", email, name);
    axios
      .get("https://www.iterve.ai/uservitals/subusernames", {
        params: { email: email },
      })
      .then((res) => {
        console.log("all users", res.data);
        var a = [];
        for (let i = 0; i < res.data.length; i++) {
          a.push(res.data[i].name);
        }
        setallusers(a);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const findAndSHowThatUser = (name) => {
    console.log("finding that user", name);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePrevUser = () => {
    if (allusers.length > 0) {
      const index = allusers.indexOf(states.currentUser.name);

      if (index === 0) {
        findAndSHowThatUser(allusers[allusers.length - 1]);
      } else {
        findAndSHowThatUser(allusers[index - 1]);
      }
    }
  };

  const handleNextUser = () => {
    if (allusers.length > 0) {
      const index = allusers.indexOf(states.currentUser.name);

      if (index === allusers.length - 1) {
        findAndSHowThatUser(allusers[0]);
      } else {
        findAndSHowThatUser(allusers[index + 1]);
      }
    }
  };

  var p = window.location.href.split("/").filter(Boolean).pop();

  return (
    <div className={classes.titleContainer}>
      <TextField
        variant="outlined"
        name="Search"
        placeholder="Type to search..."
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ width: "2vw" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            color: "#043E74",
            fontSize: "1vw",
            height: "2.5vw",
            // Class for the border around the input field
            border: "1px #564D8B",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px #564D8B",
            fontSize: "1vw",
          },
          // Class for the label of the input field
          "& .MuiInputLabel-outlined": {
            color: "#043E74",
            fontSize: "1vw",
          },
          width: "30%",
          background: "linear-gradient(to bottom, #4A88C0, #f5f5f5)",
          borderRadius: "0.5vw",
          border: "1px solid #564D8B",
        }}
      />
      <div style={{ display: "flex", gap: "1vw" }}>
        <div className={classes.miniContainer}>
          <img
            src={notificationIcon}
            alt="notification icon"
            className={classes.icon}
          />
          <img
            src={seperatorIcon}
            alt="seperator icon"
            className={classes.bar}
          />
          <img
            src={
              p === "passchange" || p === "settings"
                ? settingswhite
                : settingicon
            }
            alt="setting icon"
            className={classes.icon}
            onClick={() => navigate("/settings")}
            style={{
              cursor: "pointer",
              ...(p === "settings" || p === "passchange"
                ? {
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                  }
                : { boxShadow: "none" }),
            }}
          />
          <img
            src={seperatorIcon}
            alt="seperator icon"
            className={classes.bar}
          />
          <img src={chatIcon} alt="chat icon" className={classes.icon} />
        </div>
        <div className={classes.miniContainer}>
          <img
            src={
              states.userImage[states.currentUser.name]
                ? states.userImage[states.currentUser.name].data
                : avatarIcon
            }
            alt="avatar icon"
            className={classes.icon}
          />
          <div className={classes.userName}>{states.currentUser.name}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={arrowIcon}
            alt="arrow icon"
            className={classes.arrowIcon}
            onClick={handlePrevUser}
          />
          <img
            src={arrowIcon}
            alt="arrow icon"
            className={classes.arrowIcon}
            style={{ transform: "scaleY(-1)" }}
            onClick={handleNextUser}
          />
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
