import React, { useState, useEffect, useContext } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import classes from "./imr.module.css";
import AppLayout from "../../layout/AppLayout/AppLayout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import normalRangeIcon from "../../Images/normalRangeIcon.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import axios from "axios";
import userEvent from "@testing-library/user-event";
import { SavedStateContext } from "../../context/SavedState";
import { useNavigate } from "react-router-dom";
import MobileFooter from "../../Components/Footer/MobileFooter";
import MobileHeader from "../../Components/Header/MobileHeader";

const Imr = () => {
  const states = useContext(SavedStateContext);

  const navigate = useNavigate();

  const [timeRangeOxygen, setTimeRangeOxygen] = useState("monthly");
  const [timeRangeCholesterol, setTimeRangeCholesterol] = useState("monthly");
  const [timeRangeHeartRate, setTimeRangeHeartRate] = useState("monthly");
  const [monthOxygenData, setMonthOxygenData] = useState([]);
  const [weekOxygenData, setWeekOxygenData] = useState([]);
  const [yearOxygenData, setYearOxygenData] = useState([]);
  const [monthHeartData, setMonthHeartData] = useState([]);
  const [weekHeartData, setWeekHeartData] = useState([]);
  const [yearHeartData, setYearHeartData] = useState([]);
  const [ldlmonthData, setldlmonthData] = useState([]);
  const [ldlyearData, setldlyearData] = useState([]);
  const [ldlWeekData, setldlWeekData] = useState([]);
  const [hdlmonthData, sethdlmonthData] = useState([]);
  const [hdlyearData, sethdlyearData] = useState([]);
  const [hdlWeekData, sethdlWeekData] = useState([]);
  const [totChomonthData, settotChomonthData] = useState([]);
  const [totChoyearData, settotChoyearData] = useState([]);
  const [totChoWeekData, settotChoWeekData] = useState([]);
  const [triGlymonthData, settriGlymonthData] = useState([]);
  const [triGlyyearData, settriGlyyearData] = useState([]);
  const [triGlyWeekData, settriGlyWeekData] = useState([]);
  const [weekTable, setWeekTable] = useState();
  const [monthTable, setMonthTable] = useState();
  const [yearTable, setYearTable] = useState();
  const [latestReport, setlatestReport] = useState();
  const [currentUserEmail, setCurrentUserEmail] = useState();
  const [currentUserName, setCurrentUserName] = useState();

  useEffect(() => {
    setCurrentUserEmail(states.currentUser.email);
    setCurrentUserName(states.currentUser.name);
  }, [states.currentUser]);

  const days = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
  const yearMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthWeeks = ["Week1", "Week2", "Week3", "Week4", "week5"];

  const healthParams = ["Heart Rate", "Blood Pressure (SBP/DBP)", "Body Water"];

  const values = latestReport
    ? [
        latestReport.heart_rate,
        `${latestReport.sbp}/${latestReport.dbp}`,
        latestReport.body_water,
      ]
    : [];
  const units = ["Beats per min", "mmHg", "%"];

  const cardsData = latestReport
    ? healthParams.map((param, index) => ({
        name: param,
        value: values[index],
        unit: units[index],
        width: "30%",
      }))
    : "";

  const navigateCard = (name) => {
    if (name === "Heart Rate") {
      return "heart";
    } else if (name === "Blood Pressure (SBP/DBP)") {
      return "bloodpressure";
    } else if (name === "Body Water") {
      return "bodywater";
    } else if (name === "Oxygen Saturation Rate") {
      return "oxygensaturation";
    } else if (name === "Hemoglobin") {
      return "hemoglobin";
    } else if (name === "Random Blood Sugar") {
      return "rbs";
    }
    return 0;
  };

  const getChartData = (timeRange) => {
    if (timeRange === "monthly") {
      return monthOxygenData;
    } else if (timeRange === "weekly") {
      return weekOxygenData;
    } else if (timeRange === "yearly") {
      return yearOxygenData;
    }
  };

  const getChartData1 = (timeRange) => {
    if (timeRange === "monthly") {
      return monthHeartData;
    } else if (timeRange === "weekly") {
      return weekHeartData;
    } else if (timeRange === "yearly") {
      return yearHeartData;
    }
  };
  // Sample table data (update to match your actual data structure)
  const weeklyTableData = [];
  const monthlyTableData = [];
  const yearlyTableData = [];

  const updateWeeklyTableValue = () => {
    days.map((day, idx) => {
      weeklyTableData.push([
        day,
        totChoWeekData[idx].value,
        ldlWeekData[idx].value,
        hdlWeekData[idx].value,
        triGlyWeekData[idx].value,
      ]);
    });
    if (weeklyTableData.length > 0) setWeekTable(weeklyTableData);
  };
  const updateMonthlyTableValue = () => {
    monthWeeks.map((weeks, idx) => {
      monthlyTableData.push([
        weeks,
        totChomonthData[idx].value,
        ldlmonthData[idx].value,
        hdlmonthData[idx].value,
        triGlymonthData[idx].value,
      ]);
    });
    if (monthlyTableData.length > 0) setMonthTable(monthlyTableData);
  };

  const updateYearlyTableValue = () => {
    yearMonths.map((val, idx) => {
      yearlyTableData.push([
        val,
        totChoyearData[idx].value,
        ldlyearData[idx].value,
        hdlyearData[idx].value,
        triGlyyearData[idx].value,
      ]);
    });
    if (yearlyTableData.length > 0) setYearTable(yearlyTableData);
  };

  const getTableData = (timeRange) => {
    if (timeRange === "monthly") {
      return monthTable;
    } else if (timeRange === "weekly") {
      return weekTable;
    } else if (timeRange === "yearly") {
      return yearTable;
    }
  };

  const getTableHeaders = (timeRange) => {
    if (timeRange === "monthly") {
      return [
        "Month",
        "Total CHolestrol",
        "LDL Cholestrol",
        "HDL Cholestrol",
        "Triglycerides",
      ];
    } else if (timeRange === "weekly") {
      return [
        "Day",
        "Total CHolestrol",
        "LDL Cholestrol",
        "HDL Cholestrol",
        "Triglycerides",
      ];
    } else if (timeRange === "yearly") {
      return [
        "Year",
        "Total CHolestrol",
        "LDL Cholestrol",
        "HDL Cholestrol",
        "Triglycerides",
      ];
    }
  };

  // Helper function to check if any value in the row is over 100
  const isRowOver100 = (row) => {
    return row.some((value) => parseInt(value, 10) > 200);
  };

  function getalltestsweek(accno) {
    var alltests = [];
    var today = new Date();
    var startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    var endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Ensure startOfWeek and endOfWeek are at the start and end of the days
    startOfWeek.setHours(0, 0, 0, 0);
    endOfWeek.setHours(23, 59, 59, 999);

    console.log("Start of the week: ", startOfWeek.toISOString());
    console.log("End of the week: ", endOfWeek.toISOString());

    axios
      .get("https://www.iterve.ai/uservitals/tests", {
        params: {
          email: states.currentUser.email,
          startDate: startOfWeek.toISOString,
          endDate: endOfWeek.toISOString,
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("Filtered tests: ", alltests);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function getalltestsmonth(accno) {
    var alltests = [];
    var today = new Date();
    var startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    // Ensure startOfMonth and endOfMonth are at the start and end of the days
    startOfMonth.setHours(0, 0, 0, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    console.log("Start of the month: ", startOfMonth.toISOString());
    console.log("End of the month: ", endOfMonth.toISOString());

    axios
      .get("https://www.iterve.ai/uservitals/tests", {
        params: {
          email: states.currentUser.email,
          startDate: startOfMonth,
          endDate: endOfMonth,
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("Filtered tests: ", alltests);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function getalltestsyear(accno) {
    var alltests = [];
    var today = new Date();
    var startOfYear = new Date(today.getFullYear(), 0, 1);
    var endOfYear = new Date(today.getFullYear(), 11, 31);

    // Ensure startOfYear and endOfYear are at the start and end of the days
    startOfYear.setHours(0, 0, 0, 0);
    endOfYear.setHours(23, 59, 59, 999);

    console.log("Start of the year: ", startOfYear.toISOString());
    console.log("End of the year: ", endOfYear.toISOString());

    axios
      .get("https://www.iterve.ai/uservitals/tests", {
        params: {
          email: currentUserEmail,
          startDate: startOfYear,
          endDate: endOfYear,
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("Filtered tests: ", alltests);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function fetchWeeklyAverages(param1) {
    try {
      const now = new Date();

      const response = await axios.get(
        "https://www.iterve.ai/uservitals/averageWeek",
        {
          params: {
            email: currentUserEmail,
            name: currentUserName,
            vital: param1,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching weekly averages:", error.message);
      throw error;
    }
  }

  async function fetchMonthWeeklyAverages(param1) {
    try {
      const now = new Date();

      const response = await axios.get(
        "https://www.iterve.ai/uservitals/averageWeekMonth",
        {
          params: {
            email: currentUserEmail,
            name: currentUserName,
            vital: param1,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error fetching weekly averages:", error.message);
      throw error;
    }
  }

  async function fetchYearlyAverages(param1) {
    try {
      const now = new Date();

      const response = await axios.get(
        "https://www.iterve.ai/uservitals/averageMonth",
        {
          params: {
            email: currentUserEmail,
            name: currentUserName,
            vital: param1,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching yearly averages:", error.message);
      throw error;
    }
  }

  useEffect(() => {
    // getalltestsweek();
    // getalltestsmonth();
    // getalltestsyear();
    fetchYearlyAverages("oxygen_saturation")
      .then((monthlyAverages) => {
        setYearOxygenData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchMonthWeeklyAverages("oxygen_saturation")
      .then((monthlyAverages) => {
        setMonthOxygenData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchWeeklyAverages("oxygen_saturation")
      .then((weeklyAverages) => {
        setWeekOxygenData(weeklyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchYearlyAverages("random_blood_sugar")
      .then((monthlyAverages) => {
        setYearHeartData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchMonthWeeklyAverages("random_blood_sugar")
      .then((monthlyAverages) => {
        setMonthHeartData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchWeeklyAverages("random_blood_sugar")
      .then((weeklyAverages) => {
        setWeekHeartData(weeklyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchYearlyAverages("ldl")
      .then((monthlyAverages) => {
        setldlyearData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchMonthWeeklyAverages("ldl")
      .then((monthlyAverages) => {
        setldlmonthData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchWeeklyAverages("ldl")
      .then((weeklyAverages) => {
        setldlWeekData(weeklyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchYearlyAverages("hdl")
      .then((monthlyAverages) => {
        sethdlyearData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchMonthWeeklyAverages("hdl")
      .then((monthlyAverages) => {
        sethdlmonthData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchWeeklyAverages("hdl")
      .then((weeklyAverages) => {
        sethdlWeekData(weeklyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchYearlyAverages("total_cholestrol")
      .then((monthlyAverages) => {
        settotChoyearData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchMonthWeeklyAverages("total_cholestrol")
      .then((monthlyAverages) => {
        settotChomonthData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchWeeklyAverages("total_cholestrol")
      .then((weeklyAverages) => {
        settotChoWeekData(weeklyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchYearlyAverages("triglycerides")
      .then((monthlyAverages) => {
        settriGlyyearData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchMonthWeeklyAverages("triglycerides")
      .then((monthlyAverages) => {
        settriGlymonthData(monthlyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    fetchWeeklyAverages("triglycerides")
      .then((weeklyAverages) => {
        settriGlyWeekData(weeklyAverages.data.results);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });

    axios
      .get("https://www.iterve.ai/uservitals/latestReport", {
        params: {
          email: states.currentUser.email,
          name: states.currentUser.name,
        },
      })
      .then((response) => {
        setlatestReport(response.data[0]);
      })
      .catch((error) => {
        console.error("Failed to fetch monthly averages:", error);
      });
    // updateAllTableValue();
  }, [currentUserEmail, currentUserName]);

  useEffect(() => {
    console.log(latestReport);
  }, [latestReport]);

  useEffect(() => {
    if (
      totChoWeekData.length > 0 &&
      ldlWeekData.length > 0 &&
      hdlWeekData.length > 0 &&
      triGlyWeekData.length > 0
    )
      updateWeeklyTableValue();
  }, [totChoWeekData, ldlWeekData, hdlWeekData, triGlyWeekData]);

  useEffect(() => {
    if (
      totChomonthData.length > 0 &&
      ldlmonthData.length > 0 &&
      hdlmonthData.length > 0 &&
      triGlymonthData.length > 0
    )
      updateMonthlyTableValue();
  }, [totChomonthData, ldlmonthData, hdlmonthData, triGlymonthData]);

  useEffect(() => {
    if (
      totChoyearData.length > 0 &&
      ldlyearData.length > 0 &&
      hdlyearData.length > 0 &&
      triGlyyearData.length > 0
    )
      updateYearlyTableValue();
  }, [totChoyearData, ldlyearData, hdlyearData, triGlyyearData]);

  return (
    <>
      {window.innerWidth > 768 ? (
        <div className={classes.container}>
          <div className={classes.heading}>IMR STATUS</div>
          <div className={classes.fulldiv}>
            <div className={classes.leftcont}>
              <div className={classes.upperCardSec}>
                {latestReport
                  ? cardsData.map((cardData, index) => (
                      <Card
                        key={index}
                        name={cardData.name}
                        value={cardData.value}
                        unit={cardData.unit}
                        width={cardData.width}
                      />
                    ))
                  : ""}
              </div>
              <div className={classes.midiv}>
                <div className={classes.chartContainer1}>
                  <div className={classes.chartHeader1}>
                    <div
                      className={classes.chartTitle1}
                      onClick={() => navigate("/oxygensaturation")}
                    >
                      Oxygen Saturation
                    </div>
                    <div className={classes.timeRangeDropdown1}>
                      <select
                        value={timeRangeOxygen}
                        onChange={(e) => setTimeRangeOxygen(e.target.value)}
                      >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <ResponsiveContainer
                    // width="100%"
                    height={(window.innerWidth * 12) / 100}
                    width={(window.innerWidth * 30) / 100}
                  >
                    <AreaChart
                      data={getChartData(timeRangeOxygen)}
                      margin={{ top: 5, right: 80, left: -40, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" fontSize={0.5 + "vw"} />
                      <YAxis fontSize={0.5 + "vw"} />
                      <Tooltip />
                      <Area
                        type="linear"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#022b52"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className={classes.middivtable}>
                  <div className={classes.tableheadiv}>
                    <div
                      className={classes.chartTitle1}
                      onClick={() => navigate("/cholestrol")}
                    >
                      Cholesterol
                    </div>
                    <div className={classes.timeRangeDropdown2}>
                      <select
                        value={timeRangeCholesterol}
                        onChange={(e) =>
                          setTimeRangeCholesterol(e.target.value)
                        }
                      >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <table className={classes.cholesterolTable}>
                    <thead>
                      <tr>
                        {getTableHeaders(timeRangeCholesterol).map(
                          (header, index) => (
                            <th key={index}>{header}</th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {getTableData(timeRangeCholesterol) &&
                        getTableData(timeRangeCholesterol).map(
                          (row, rowIndex) => (
                            <tr
                              key={rowIndex}
                              className={
                                isRowOver100(row.slice(1))
                                  ? classes.rowOver100
                                  : ""
                              }
                            >
                              {row.map((cell, cellIndex) => (
                                <td
                                  key={cellIndex}
                                  className={
                                    cellIndex === 0 ? classes.daynames : ""
                                  }
                                >
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={classes.bottomdiv}>
                <div className={classes.chartContainer}>
                  <div className={classes.chartHeader}>
                    <div
                      className={classes.chartTitle1}
                      onClick={() => navigate("/rbs")}
                    >
                      Random Blood Sugar
                    </div>
                    <div className={classes.timeRangeDropdown}>
                      <select
                        value={timeRangeHeartRate}
                        onChange={(e) => setTimeRangeHeartRate(e.target.value)}
                      >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <ResponsiveContainer
                    width={(window.innerWidth * 52) / 100}
                    height={(window.innerWidth * 14) / 100}
                  >
                    <AreaChart
                      data={getChartData1(timeRangeHeartRate)}
                      margin={{ top: 5, right: 80, left: -40, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" fontSize={0.7 + "vw"} />
                      <YAxis fontSize={0.7 + "vw"} />
                      <Tooltip />
                      <Area
                        type="linear"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#022b52"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className={classes.rightcont}>
              <Card
                key={4}
                name={"Stress Index"}
                value={latestReport ? `${latestReport.stress_index}%` : "0%"}
                unit={""}
                width={"66.7%"}
              />
              <Card
                key={5}
                name={"Haemoglobin"}
                value={latestReport ? `${latestReport.hemoglobin}` : "0"}
                unit={"g/dl"}
                width={"66.7%"}
              />
              <div className={classes.bmistatus}>
                <div className={classes.bmistatustext}>BMI Status</div>
                <CircularProgressbar
                  value={latestReport ? latestReport.bmi : 0}
                  text={latestReport ? latestReport.bmi : 0}
                  className={classes.progressBar}
                  strokeWidth={15}
                  styles={{
                    path: {
                      stroke: "#043E74", // The stroke color based on barcolor attribute
                    },
                    trail: {
                      stroke: "#d6d6d6", // The trail color (background of the progress bar)
                    },
                    text: {
                      fill: "#043E74", // The color of the text inside the progress bar
                      fontSize: "1.5rem",
                    },
                  }}
                />
                <div className={classes.bmistatusmore}>
                  Age :{latestReport ? latestReport.age : 0}
                </div>
                <div className={classes.bmistatusmore}>
                  Gender :{latestReport ? latestReport.gender : 0}
                </div>
                <div className={classes.bmistatusmore}>
                  Height :{latestReport ? latestReport.height : 0}
                </div>
                <div className={classes.bmistatusmore}>
                  Weight :{latestReport ? latestReport.weight : 0}
                </div>
                <div className={classes.gengraphbutton}>Generate Graph</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          {window.innerWidth <= 768 && <MobileHeader />}
          <div className={classes.heading}>IMR Dashboard</div>
          <div className={classes.heading}>
            Hello, {states.currentUser.name}
          </div>
          <div
            className={classes.mobileimrstatusdiv}
            onClick={() => navigate("/imr")}
          >
            IMR Status
          </div>
          <div className={classes.fulldiv}>
            <div className={classes.leftcont}>
              <div className={classes.upperCardSec}>
                {latestReport
                  ? cardsData.map((cardData, index) => (
                      <Card
                        key={index}
                        name={cardData.name}
                        value={cardData.value}
                        unit={cardData.unit}
                        width={cardData.width}
                      />
                    ))
                  : ""}
              </div>
              <Card
                key={4}
                name={"Stress Index"}
                value={latestReport ? `${latestReport.stress_index}%` : "0%"}
                unit={""}
                width={"66.7%"}
              />
              <Card
                key={5}
                name={"Haemoglobin"}
                value={latestReport ? `${latestReport.hemoglobin}` : "0"}
                unit={"g/dl"}
                width={"66.7%"}
              />
              <div className={classes.bmistatus}>
                <div className={classes.bmistatustext}>BMI Status</div>
                <CircularProgressbar
                  value={latestReport ? latestReport.bmi : 0}
                  text={latestReport ? latestReport.bmi : 0}
                  className={classes.progressBar}
                  strokeWidth={15}
                  styles={{
                    path: {
                      stroke: "#043E74", // The stroke color based on barcolor attribute
                    },
                    trail: {
                      stroke: "#d6d6d6", // The trail color (background of the progress bar)
                    },
                    text: {
                      fill: "#043E74", // The color of the text inside the progress bar
                      fontSize: "24px",
                    },
                  }}
                />
                <div className={classes.mobbmistatusmore}>
                  Age :{latestReport ? latestReport.age : 0}
                </div>
                <div className={classes.mobbmistatusmore}>
                  Gender :{latestReport ? latestReport.gender : 0}
                </div>
                <div className={classes.mobbmistatusmore}>
                  Height :{latestReport ? latestReport.height : 0}
                </div>
                <div className={classes.mobbmistatusmore}>
                  Weight :{latestReport ? latestReport.weight : 0}
                </div>
                <div className={classes.gengraphbutton}>Generate Graph</div>
              </div>
              <div className={classes.midiv}>
                <div className={classes.chartContainer1}>
                  <div className={classes.chartHeader1}>
                    <div
                      className={classes.chartTitle1}
                      onClick={() => navigate("/oxygensaturation")}
                    >
                      Oxygen Saturation
                    </div>
                    <div className={classes.timeRangeDropdown1}>
                      <select
                        value={timeRangeOxygen}
                        onChange={(e) => setTimeRangeOxygen(e.target.value)}
                      >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <ResponsiveContainer
                    // width="100%"
                    height={207}
                    width={350}
                  >
                    <AreaChart
                      data={getChartData(timeRangeOxygen)}
                      margin={{ top: 5, right: 20, left: -30, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" fontSize={2 + "vw"} />
                      <YAxis fontSize={2 + "vw"} />
                      <Tooltip />
                      <Area
                        type="linear"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#022b52"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className={classes.middivtable}>
                  <div className={classes.tableheadiv}>
                    <div
                      className={classes.chartTitle1}
                      onClick={() => navigate("/cholestrol")}
                    >
                      Cholesterol
                    </div>
                    <div className={classes.timeRangeDropdown2}>
                      <select
                        value={timeRangeCholesterol}
                        onChange={(e) =>
                          setTimeRangeCholesterol(e.target.value)
                        }
                      >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <table className={classes.cholesterolTable}>
                    <thead>
                      <tr>
                        {getTableHeaders(timeRangeCholesterol).map(
                          (header, index) => (
                            <th key={index}>{header}</th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {getTableData(timeRangeCholesterol) &&
                        getTableData(timeRangeCholesterol).map(
                          (row, rowIndex) => (
                            <tr
                              key={rowIndex}
                              className={
                                isRowOver100(row.slice(1))
                                  ? classes.rowOver100
                                  : ""
                              }
                            >
                              {row.map((cell, cellIndex) => (
                                <td
                                  key={cellIndex}
                                  className={
                                    cellIndex === 0 ? classes.daynames : ""
                                  }
                                >
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                <div className={classes.chartContainer}>
                  <div className={classes.chartHeader}>
                    <div
                      className={classes.chartTitle1}
                      onClick={() => navigate("/rbs")}
                    >
                      Random Blood Sugar
                    </div>
                    <div className={classes.timeRangeDropdown}>
                      <select
                        value={timeRangeHeartRate}
                        onChange={(e) => setTimeRangeHeartRate(e.target.value)}
                      >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <ResponsiveContainer width={332} height={387}>
                    <AreaChart
                      data={getChartData1(timeRangeHeartRate)}
                      margin={{ top: 5, right: 20, left: -30, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" fontSize={2 + "vw"} />
                      <YAxis fontSize={2 + "vw"} />
                      <Tooltip />
                      <Area
                        type="linear"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#022b52"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div
                  className={classes.testButton}
                  onClick={() => navigate("/reports")}
                >
                  REPORT
                </div>
                <div
                  className={classes.testButton}
                  onClick={() => navigate("/dashboard")}
                >
                  DASHBOARD
                </div>
                <div
                  className={classes.testButton}
                  onClick={() => navigate("/selftest")}
                >
                  SELF TEST
                </div>
              </div>
              <div className={classes.bottomdiv}></div>
            </div>
            <div className={classes.rightcont}></div>
          </div>
        </div>
      )}
    </>
  );
};

const Card = ({ name, value, unit, width }) => {
  const navigate = useNavigate();

  const handleCardClick = (name) => {
    if (name === "Heart Rate") {
      navigate("/heart");
    } else if (name === "Blood Pressure (SBP/DBP)") {
      navigate("/bloodpressure");
    } else if (name === "Body Water") {
      navigate("/bodywater");
    } else if (name === "Oxygen Saturation Rate") {
      navigate("/oxygensaturation");
    } else if (name === "Haemoglobin") {
      navigate("/hemoglobin");
    } else if (name === "Stress Index") {
      navigate("/stressindex");
    }
  };

  return (
    <>
      {window.innerWidth > 768 ? (
        <div className={classes.cardContainer} style={{ width: `${width}` }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "1.5vw",
            }}
          >
            <div className={classes.name} onClick={() => handleCardClick(name)}>
              {name}
            </div>
            <MoreVertIcon style={{ color: "#043e74", fontSize: "1.5vw" }} />
          </div>
          <div className={classes.valueUnit}>
            <div className={classes.value}>{value}</div>
            <div className={classes.unit}>{unit}</div>
          </div>
          <img
            src={normalRangeIcon}
            alt="normal range icon"
            className={classes.normalRangeIcon}
          />
        </div>
      ) : (
        <div
          className={classes.cardContainer}
          style={{ width: `${width}` }}
          onClick={() => handleCardClick(name)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "fit-content",
            }}
          >
            <div className={classes.name}>{name}</div>
            <MoreVertIcon style={{ color: "#043e74", fontSize: "1.5vw" }} />
          </div>
          <div className={classes.valueUnit}>
            <div className={classes.value}>{value}</div>
            <div className={classes.unit}>{unit}</div>
          </div>
          <img
            src={normalRangeIcon}
            alt="normal range icon"
            className={classes.normalRangeIcon}
          />
        </div>
      )}
    </>
  );
};

export default window.innerWidth > 768 ? AppLayout()(Imr) : Imr;
