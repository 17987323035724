import React, { useContext, useState } from "react";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { SavedStateContext } from "../../context/SavedState";
import axios from "axios";

const Footer = () => {
  const navigate = useNavigate();

  const states = useContext(SavedStateContext);
  const signout = async () => {
    await axios
      .put("https://www.iterve.ai/uservitals", {
        params: {
          email: states.currentUser.email,
          deviceId: "",
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(null);
        localStorage.removeItem("currentUser");
        navigate("/home");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.columnsinfo} style={{ width: "21%" }}>
          <div className={classes.columnHead}>Info</div>
          <div className={classes.paragraph}>
            Experience hassle- free healthcare access and connect with trusted
            healthcare professionals effortlessly .
          </div>
        </div>
        <div className={classes.columns}>
          <div className={classes.columnHead}>For Patients</div>
          <div className={classes.columnItem}>Search for Doctors</div>
          {states.currentUser ? (
            <div className={classes.clickableColItem} onClick={signout}>
              Sign Out
            </div>
          ) : (
            <div
              className={classes.clickableColItem}
              onClick={() => navigate("/login")}
            >
              Login
            </div>
          )}
          <div
            className={classes.clickableColItem}
            style={
              states.currentUser ? { display: "none" } : { display: "block" }
            }
            onClick={() => navigate("/register")}
          >
            Register
          </div>
        </div>
        <div className={classes.columns}>
          <div className={classes.columnHead}>For Doctor</div>
          <div className={classes.columnItem}>Video Consult</div>
          <div className={classes.columnItem}>Chat</div>
          <div className={classes.columnItem}>Appointment</div>
        </div>
        <div className={classes.columns}>
          <div className={classes.columnHead}>Contact us</div>
          <div className={classes.columnItem}>Navi Mumbai, 410210</div>
          <div className={classes.columnItem}>02248261142</div>
          <div
            className={classes.clickableColItem}
            onClick={() => navigate("/rating")}
          >
            Rating
          </div>
          <a
            className={classes.clickableColItem}
            href="mailto:support@aivot.ai"
          >
            support@aivot.ai
          </a>
        </div>
        <div className={classes.columns1}>
          <div className={classes.columnHead}>Join us</div>
          <div className={classes.email}>
            <input className={classes.emailbox} placeholder="Email" />
            <div className={classes.submitbutton}>Submit</div>
          </div>
        </div>
      </div>
      <div
        style={{
          fontSize: "1vw",
          fontWeight: "300",
          padding: "0vw 0vw 1vw 5vw",
        }}
      >
        Copyright © 2024 Aivot AI Pvt Ltd, All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
