import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className={classes.heading}>Privacy Policy</div>
      <div className={classes.paragh}>
        <div>
          At iTerve, we are dedicated to protecting your privacy and ensuring
          the security of your personal information. This Privacy Policy
          outlines how we collect, use, disclose, and protect your data when you
          use our website and services.
        </div>
        <div className={classes.bold}>Our Commitment:</div>
        <div>
          We live and die by our rule: you always remain the sole owner of your
          data. The present Privacy Policy is supplied electronically for the
          registration of the application. Your video and picture are not stored
          in our database; our system extracts only RGB and rPPG from camera
          processing and uses it to derive your health vitals. I agree to share
          my data for research and training purposes
        </div>
        <div className={classes.bold}>
          What Information Do We Collect About You?
        </div>
        <div>
          When you start using iTerve, we will ask you to create an account to
          allow secure storage of your data. In this respect, we collect limited
          general personal details such as your name, email address, and
          demographic information like your age, gender, and country. By using
          our services, you will provide us with your health data and
          health-related data (such as your measurements). Otherwise, we cannot
          do a body scan: Given its sensitive nature, additional technical and
          organizational measures are taken to safeguard your information. We
          collect information about the device you use to access the iTerve app
          or iTerve dashboard to assure quality.
        </div>
        <div className={classes.bold}>Why Do We Collect This Information?</div>
        <div>
          To assist you in your health journey to timely detect irregular health
          patterns and provide you with comprehensive vital support. To provide
          customer support. To improve our services and ensure you can make
          optimal use of iTerve. To contact you and keep you up to date about
          our services. To send you questionnaires or invite you to provide a
          review of your experiences with our services. We may also get in touch
          with you regarding feedback, inquiries, and complaints you made
          regarding our offered services. In the event that a project is funded
          by your employer, provide your employer with insights regarding the
          health of its employees. In case you gave explicit consent, we will
          provide the occupational physician of your employer’s Health and
          Safety department with an analysis of your measurements. Otherwise,
          only aggregated data (meaning anonymized data) shall be shared. For
          scientific and research purposes, we will use and/or share only
          pseudonymized personal data or anonymized data.
        </div>
        <div className={classes.bold}>Who Has Access to Your Data?</div>
        <div>
          Your personal data will never be sold or made available to other
          parties. Remember our mantra? You always remain the sole owner of your
          data. Only a select number of people on the trustworthy iTerve team
          have access to your information. We do make use of external suppliers
          and service providers, such as web hosting services. These parties are
          legally obliged to always ensure your privacy. They can only process
          data in accordance with our purposes. We will only share your
          pseudonymized personal data (for scientific and research purposes) or
          anonymized data.
        </div>
        <div className={classes.bold}>What Do You Control?</div>
        <div>
          It is your data, so you are in control: Want to modify your personal
          data? You can do so yourself in the iTerve app. Want to know what
          information we collect from you? Almost all the data is visible in
          your dashboard. Or you can request a copy of all the information we
          collected about you by sending an email to
          <span className={classes.redtext}> support.iterve@aivot.ai</span>.
        </div>
        <div className={classes.bold}>How Do We Protect Your Data?</div>
        <div>
          A multitude of safety measures has been put in place on both a
          technical and organizational level. We implemented internal procedures
          to ensure the confidentiality of our IT infrastructure and to make
          sure it is managed responsibly.
        </div>
        <div className={classes.bold}>Want to know more?</div>
        <div>
          Scroll down to read our Privacy Policy in its entirety. We, AIVOT AI,
          are registered at Kharghar Navi Mumbai 410210 , hereinafter referred
          to as iTerve. At iTerve, we value your trust and respect your privacy.
          This Privacy Policy provides you with details about the way your data
          is collected, stored, and used by us. You are advised to read this
          Privacy Policy carefully. By downloading and using the iTerve
          application/website/WAP site, you expressly give us consent to use and
          disclose your personal information in accordance with this Privacy
          Policy. If you do not agree to the terms of the policy, please do not
          use or access iTerve.
        </div>
        <div className={classes.redtext}>Note:</div>
        <div>
          Our privacy policy may change at any time without prior notification.
          To make sure that you are aware of any changes, kindly review the
          policy periodically. This Privacy Policy shall apply uniformly to
          iTerve applications, desktop websites, and mobile WAP sites.
        </div>
        <div className={classes.bold}>General</div>
        <div>
          We will not sell, share, or rent your personal information to any
          third party or use your email address/mobile number for unsolicited
          emails and/or SMS. Any emails and/or SMS sent by iTerve will only be
          in connection with the provision of agreed services and products and
          this Privacy Policy. Periodically, we may reveal general statistical
          information about iTerve and its users, such as the number of
          visitors, number and type of goods and services purchased, etc. We
          reserve the right to communicate your personal information to any
          third party that makes a legally compliant request for its disclosure.
        </div>
        <div className={classes.bold}>Personal Information</div>
        <div>
          Personal Information means and includes all information that can be
          linked to a specific individual or to identify any individual, such as
          name, address, email ID, and any details that may have been
          voluntarily provided by the user in connection with availing of any of
          the services on iTerve. When you browse through iTerve, we may collect
          information regarding the mobile/tab device details, domain, and host
          from which you access the internet, the Internet Protocol [IP] address
          of the computer or Internet service provider [ISP] you are using, and
          anonymous site statistical data.
        </div>
        <div className={classes.bold}>Use of Personal Information</div>
        <div>
          We use personal information to provide you with services and products
          you explicitly requested, to resolve disputes, troubleshoot concerns,
          help promote safe services, collect money, measure consumer interest
          in our services, inform you about offers, products, services, updates,
          customize your experience, detect and protect us against error, fraud
          and other criminal activity, enforce our terms and conditions, etc. We
          also use your contact information to send you offers based on your
          previous orders and interests. We may occasionally ask you to complete
          optional online surveys. These surveys may ask you for contact
          information and demographic information (like zip code, age, gender,
          etc.). We use this data to customize your experience at iTerve,
          providing you with content that we think you might be interested in
          and displaying the content according to your preferences.
        </div>
        <div className={classes.bold}>Cookies</div>
        <div>
          A "cookie" is a small piece of information stored by a web server on a
          web browser so it can be later read back from that browser. iTerve
          uses cookie and tracking technology depending on the features offered.
          No personal information will be collected via cookies and other
          tracking technology; however, if you previously provided personally
          identifiable information, cookies may be tied to such information.
          Aggregate cookie and tracking information may be shared with third
          parties. Links to Other Sites Our site links to other websites that
          may collect personally identifiable information about you. iTerve is
          not responsible for the privacy practices or the content of those
          linked websites.
        </div>
        <div className={classes.bold}>Security</div>
        <div>
          iTerve has stringent security measures in place to protect the loss,
          misuse, and alteration of the information under our control. Whenever
          you change or access your account information, we offer the use of a
          secure server. Once your information is in our possession, we adhere
          to strict security guidelines, protecting it against unauthorized
          access.
        </div>
        <div className={classes.bold}>Consent</div>
        <div>
          By using iTerve and/or by providing your information, you consent to
          the collection and use of the information you disclose on iTerve in
          accordance with this Privacy Policy, including but not limited to your
          consent for sharing your information as per this privacy policy.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
