import React, { useContext, useState } from "react";
import classes from "./RatingPage.module.css";
import AppLayout from "../../layout/AppLayout/AppLayout";
import { SavedStateContext } from "../../context/SavedState";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import StarRating from "./StarRating.js";
import axios from "axios";
import MobileFooter from "../../Components/Footer/MobileFooter";
import MobileHeader from "../../Components/Header/MobileHeader";

const RatingPage = () => {
  const states = useContext(SavedStateContext);

  const [ratings, setRatings] = useState({
    uiExperience: 0,
    vitalsTesting: 0,
    vitalResults: 0,
    ratingDesc: "",
  });

  const handleRatingChange = (key, value) => {
    setRatings((prevRatings) => ({ ...prevRatings, [key]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted Ratings:", ratings);
    axios
      .put("https://www.iterve.ai/uservitals/ratingUpdate", ratings, {
        params: {
          email: states.currentUser.email,
          name: states.currentUser.name,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.container}>
      {window.innerWidth > 768 ? (
        <Header />
      ) : (
        <MobileHeader heading={"Ratings"} />
      )}
      <div className={classes.ratingHead}>Rating</div>
      <form onSubmit={handleSubmit}>
        <div className={classes.quesCont}>
          <div className={classes.perquesCont}>
            <div>1. Please rate your experience with our User Interface.</div>
            <StarRating
              rating={ratings.uiExperience}
              onRatingChange={(value) =>
                handleRatingChange("uiExperience", value)
              }
            />
          </div>
          <div className={classes.perquesCont}>
            <div>
              2. Please rate your experience with our Vitals testing process.
            </div>
            <StarRating
              rating={ratings.vitalsTesting}
              onRatingChange={(value) =>
                handleRatingChange("vitalsTesting", value)
              }
            />
          </div>
          <div className={classes.perquesCont}>
            <div>
              3. Please rate your experience with our iTerve vital results
              satisfaction.
            </div>
            <StarRating
              rating={ratings.vitalResults}
              onRatingChange={(value) =>
                handleRatingChange("vitalResults", value)
              }
            />
          </div>
          <div>
            4. Please give your review for iTerve health tech AI solution.
          </div>
          <div className={classes.perquesCont}>
            <div className={classes.descCont}>
              {window.innerWidth > 768 ? (
                <textarea
                  value={ratings.ratingDesc}
                  onChange={(e) =>
                    handleRatingChange("ratingDesc", e.target.value)
                  }
                  placeholder="Enter your review here"
                  className={classes.textarea}
                />
              ) : (
                <textarea
                  value={ratings.ratingDesc}
                  onChange={(e) =>
                    handleRatingChange("ratingDesc", e.target.value)
                  }
                  className={classes.textarea}
                />
              )}
            </div>
          </div>
          <div className={classes.submitdescCont}>
            <button type="submit" className={classes.submit}>
              Submit
            </button>
          </div>
        </div>
      </form>
      {window.innerWidth > 768 ? <Footer /> : <MobileFooter />}
    </div>
  );
};

export default RatingPage;
