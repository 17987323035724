import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import classes from "./vitalResults.module.css";

const VitalResults = () => {
  const states = useContext(SavedStateContext);

  const [vitalsData, setVitalsData] = useState();

  const email = states.currentUser.email;
  const name = states.currentUser.name;

  useEffect(() => {
    console.log("email name", email, name);
    axios
      .get("https://www.iterve.ai/uservitals/latestreport", {
        params: { email: email, name: name },
      })
      .then((res) => {
        setVitalsData(res.data[0]);
        console.log("latest report", res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [states.currentUser]);

  const cardData = !vitalsData
    ? []
    : [
        {
          label: "Heart Rate",
          reference: "60 - 100",
          value: vitalsData["heart_rate"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "beats per min",
        },
        {
          label: "Stress Index",
          reference: "20 – 80",
          value: vitalsData["stress_index"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "%",
        },
        {
          label: "Oxygen Saturation",
          reference: "95 – 100",
          value: vitalsData["oxygen_saturation"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "%",
        },
        // {
        //   label: "Blood Pressure",
        //   reference: "100 - 130 / 65 - 90",
        //   value: vitalsData["sbp"] + "/" + vitalsData["dbp"],
        //   color1: "#C746D2",
        //   color2: "#C746D2",
        //   barcolor: "#7B0C85",
        //   unit: "",
        // },
        {
          label: "BMI",
          reference: "18.5 – 24.9",
          value: vitalsData["bmi"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "kg/m^2",
        },
        {
          label: "Body Water",
          reference:
            states.currentUser["gender"] === "Male" ? "43 - 73" : "41 - 60",
          value: vitalsData["body_water"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "%",
        },
        {
          label: "Body Fat",
          reference:
            states.currentUser["gender"] === "Male" ? "8 - 21" : "14 - 28",
          value: vitalsData["body_fat"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "%",
        },
        {
          label: "Visceral Fat",
          reference: "1 - 12",
          value: vitalsData["visceral_fat"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "%",
        },
        {
          label: "Subcutaneous Fat",
          reference:
            states.currentUser["gender"] === "Male" ? "8 - 25" : "20 - 35",
          value: vitalsData["subcutaneous_fat"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "%",
        },
        {
          label: "Cardiac Output",
          reference: "4 – 8",
          value: vitalsData["cardiac_output"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "L/min",
        },
        {
          label: "Cardiac Index",
          reference: "2.6 – 4.2",
          value: vitalsData["cardiac_index"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "L/min/m^2",
        },
        {
          label: "Stroke Volume",
          reference: "48.2 - 98.5",
          value: vitalsData["stroke_volume"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "milli  litre",
        },
        {
          label: "RMSSD",
          reference: "19 – 107",
          value: vitalsData["rmssd"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "milli  sec",
        },
        {
          label: "SDNN",
          reference: ">55",
          value: vitalsData["sdnn"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "milli  sec",
        },
        {
          label: "SD1",
          reference:
            states.currentUser["gender"] === "Male" ? "40.5 - 71" : "29 - 65",
          value: vitalsData["sd1"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "milli  sec",
        },
        {
          label: "SD2",
          reference: "35 - 107",
          value: vitalsData["sd2"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "milli  sec",
        },
        {
          label: "IBI",
          reference: "600 – 900",
          value: vitalsData["ibi"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "milli  sec",
        },
        {
          label: "SBP",
          reference: "100 – 130",
          value: vitalsData["sbp"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "mm/Hg",
        },
        {
          label: "DBP",
          reference: "65 - 90",
          value: vitalsData["dbp"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "mm/Hg",
        },
        {
          label: "Mean Arterial Pressure",
          reference: "70 - 100",
          value: vitalsData["mean_arterial_pressure"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "mm/Hg",
        },
        {
          label: "Pulse Pressure",
          reference: "44 – 60",
          value: vitalsData["pulse_pressure"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "mm/Hg",
        },
        {
          label: "HDL",
          reference: "40 - 75",
          value: vitalsData["hdl"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "mg/dl",
        },
        {
          label: "LDL",
          reference: "50 - 130",
          value: vitalsData["ldl"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "mg/dl",
        },
        {
          label: "Triglycerides",
          reference: "60 - 150",
          value: vitalsData["triglycerides"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "mg/dl",
        },
        {
          label: "Total Cholesterol",
          reference: "140 - 200",
          value: vitalsData["total_cholestrol"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "mg/dl",
        },
        {
          label: "VLDL",
          reference: "5 - 40",
          value: vitalsData["vldl"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "mg/dl",
        },
        {
          label: "RBC Count",
          reference:
            states.currentUser["gender"] === "Male" ? "4.7 - 6.1" : "4.2 - 5.4",
          value: vitalsData["rbc_count"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "million cell/mcl",
        },
        {
          label: "Mean Corpuscular Value",
          reference: "80 – 100",
          value: vitalsData["mean_corpuscular_volume"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "fl",
        },
        {
          label: "Mean Corpuscular Haemoglobin",
          reference: "27 - 31",
          value: vitalsData["mean_corpuscular_hemoglobin"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "pg//cells",
        },
        {
          label: "Haemoglobin",
          reference:
            states.currentUser["gender"] === "Male" ? "13 - 17" : "11.6 - 15.5",
          value: vitalsData["hemoglobin"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "g/dl",
        },
        {
          label: "Hematocrit",
          reference:
            states.currentUser["gender"] === "Male" ? "40 - 54" : "36 - 48",
          value: vitalsData["hematocrit"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "%",
        },
        {
          label: "Random Blood Sugar",
          reference: "70 – 140",
          value: vitalsData["random_blood_sugar"],
          color1: "#C746D2",
          color2: "#C746D2",
          barcolor: "#7B0C85",
          unit: "mg/dl",
        },
        {
          label: "HbA1c",
          reference: "4 - 5.6",
          value: vitalsData["hba1c"],
          color1: "#6652D3",
          color2: "#8B82BD",
          barcolor: "#554999",
          unit: "%",
        },
        {
          label: "Mean Plasma Glucose",
          reference: "72 - 100",
          value: vitalsData["mean_plasma_glucose"],
          color1: "#3E67CF",
          color2: "#9BBDDC",
          barcolor: "#3E67CF",
          unit: "mg/dl",
        },
        {
          label: "Allowable Blood Loss",
          reference: states.currentUser["gender"] === "Male" ? "75" : "65",
          value: vitalsData["allowable_blood_loss"],
          color1: "#701F8C",
          color2: "#C695D7",
          barcolor: "#9223B9",
          unit: "ml/kg",
        },
      ];

  // const cardData = !vitalsData
  //   ? []
  //   : [
  //       {
  //         label: "Heart Rate",
  //         reference: "60 - 100",
  //         value: vitalsData["heart_rate"],
  //         color1: "#6652D3",
  //         color2: "#8B82BD",
  //         barcolor: "#554999",
  //         unit: "beats per min",
  //       },
  //       {
  //         label: "Oxygen Saturation",
  //         reference: "95 - 100",
  //         value: vitalsData["oxygen_saturation"],
  //         color1: "#3E67CF",
  //         color2: "#9BBDDC",
  //         barcolor: "#3E67CF",
  //         unit: "%",
  //       },
  //       {
  //         label: "BMI",
  //         reference: "18.5 - 24.9",
  //         value: vitalsData["bmi"],
  //         color1: "#701F8C",
  //         color2: "#C695D7",
  //         barcolor: "#9223B9",
  //         unit: "kg/m^2",
  //       },
  //       {
  //         label: "Cardiac Output",
  //         reference: "4.0 - 8.0",
  //         value: vitalsData["cardiac_output"],
  //         color1: "#C746D2",
  //         color2: "#C746D2",
  //         barcolor: "#7B0C85",
  //         unit: "L/min",
  //       },
  //       {
  //         label: "Cardiac Index",
  //         reference: "2.6 - 4.2",
  //         value: vitalsData["cardiac_index"],
  //         color1: "#6652D3",
  //         color2: "#8B82BD",
  //         barcolor: "#554999",
  //         unit: "L/min/m^2",
  //       },
  //       {
  //         label: "Stroke Volume",
  //         reference: "48.2 - 98.5",
  //         value: vitalsData["stroke_volume"],
  //         color1: "#3E67CF",
  //         color2: "#9BBDDC",
  //         barcolor: "#3E67CF",
  //         unit: "milli litre",
  //       },
  //       {
  //         label: "SBP",
  //         reference: "100 - 130",
  //         value: vitalsData["sbp"],
  //         color1: "#701F8C",
  //         color2: "#C695D7",
  //         barcolor: "#9223B9",
  //         unit: "mm/Hg",
  //       },
  //       {
  //         label: "DBP",
  //         reference: "65 - 90",
  //         value: vitalsData["dbp"],
  //         color1: "#C746D2",
  //         color2: "#C746D2",
  //         barcolor: "#7B0C85",
  //         unit: "mm/Hg",
  //       },
  //       {
  //         label: "Hemoglobin",
  //         reference:
  //           states.currentUser["gender"] === "Male" ? "13 - 17" : "11.6 - 15.5",
  //         value: vitalsData["hemoglobin"],
  //         color1: "#6652D3",
  //         color2: "#8B82BD",
  //         barcolor: "#554999",
  //         unit: "g/dl",
  //       },
  //       {
  //         label: "Hematocrit",
  //         reference:
  //           states.currentUser["gender"] === "Male" ? "40 - 54" : "36 - 48",
  //         value: vitalsData["hematocrit"],
  //         color1: "#3E67CF",
  //         color2: "#9BBDDC",
  //         barcolor: "#3E67CF",
  //         unit: "%",
  //       },
  //       {
  //         label: "HbA1c",
  //         reference: "4 - 5.6",
  //         value: vitalsData["hba1c"],
  //         color1: "#701F8C",
  //         color2: "#C695D7",
  //         barcolor: "#9223B9",
  //         unit: "%",
  //       },
  //       {
  //         label: "Mean Plasma Glucose",
  //         reference: "72 - 100",
  //         value: vitalsData["mean_plasma_glucose"],
  //         color1: "#C746D2",
  //         color2: "#C746D2",
  //         barcolor: "#7B0C85",
  //         unit: "mg/dl",
  //       },
  //     ];

  return (
    <div className={classes.container}>
      {/* <div className={classes.minicont}> */}
      {/* <div className={classes.scrollview}> */}
      <div className={classes.headtext}>Results</div>
      {cardData.map((card, index) => (
        <div
          key={index}
          className={classes.card}
          style={{
            background: `linear-gradient(to bottom, ${card.color1}, ${card.color2}`,
          }}
        >
          <div className={classes.cardLabel}>{card.label}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                textAlign: "justify",
                boxSizing: "border-box",
                justifyContent: "space-between",
              }}
            >
              <div className={classes.cardtext}>
                Your {card.label} is {card.value}
              </div>
              <div className={classes.cardReference}>
                Reference range is {card.reference} {card.unit}
              </div>
            </div>

            {window.innerWidth >= 768 ? (
              <CircularProgressbar
                value={card.value}
                text={`${card.value}`}
                className={classes.progressBar}
                strokeWidth={15}
                styles={{
                  path: {
                    stroke: card.barcolor, // The stroke color based on barcolor attribute
                  },
                  trail: {
                    stroke: "#d6d6d6", // The trail color (background of the progress bar)
                  },
                  text: {
                    fill: "#fff", // The color of the text inside the progress bar
                    fontSize: "1.1vw",
                  },
                }}
              />
            ) : (
              <CircularProgressbar
                value={card.value}
                text={`${card.value}`}
                className={classes.progressBar}
                strokeWidth={15}
                styles={{
                  path: {
                    stroke: card.barcolor, // The stroke color based on barcolor attribute
                  },
                  trail: {
                    stroke: "#d6d6d6", // The trail color (background of the progress bar)
                  },
                  text: {
                    fill: "#fff", // The color of the text inside the progress bar
                    fontSize: "5.1vw",
                  },
                }}
              />
            )}
          </div>
        </div>
      ))}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default AppLayout()(VitalResults);
