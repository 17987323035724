import React, { useState, useContext, useEffect } from "react";
import classes from "./reports.module.css";
import PdfIcons from "./PdfIcons";
import AppLayout from "../../layout/AppLayout/AppLayout";
import avatarIcon from "../../Images/avatarIcon.png";
import axios from "axios";
import { Edit } from "@mui/icons-material";
import { SavedStateContext } from "../../context/SavedState";
import draglist from "../../Images/dragListDown.png";
import videoCall from "../../Images/videoIcon.png";
import { useNavigate } from "react-router-dom";
import UserSelect from "../../Components/UserSelect/Userselect";

const Reports = () => {
  const [dataCount, setDataCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const states = useContext(SavedStateContext);
  const navigate = useNavigate();
  const users = states.users;

  const changeUser = (name, email) => {
    console.log("change user called", name, email);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const selectedName = e.target.value;
    changeUser(selectedName, states.currentUser.email);
    setSelectedUser(""); // Reset to default option
  };

  useEffect(() => {
    axios
      .get("https://www.iterve.ai/uservitals/vitalsDataSize", {
        params: {
          accno: states.currentUser.email,
          name: states.currentUser.name,
        },
      })
      .then((res) => {
        setDataCount(res.data.vitalsDataSize);
      });
    console.log("user change caught in reports page");
  }, [states.currentUser]);

  const signout = async () => {
    await axios
      .put("https://www.iterve.ai/uservitals", {
        params: {
          email: states.currentUser.email,
          deviceId: "",
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(null);
        localStorage.removeItem("currentUser");
        navigate("/home");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.container}>
      <div>
        {window.innerWidth > 768 ? (
          <div className={classes.hellotext}>
            HELLO,
            <div className={classes.name}>
              {states.currentUser ? states.currentUser.name : ""}
            </div>
          </div>
        ) : (
          <div className={classes.hellonamediv}>
            <div className={classes.mobileprereptext}>Previous Reports</div>
            <div className={classes.mobilehellotext}>
              <div className={classes.mobilename}>
                HELLO,{states.currentUser ? states.currentUser.name : ""}
              </div>
            </div>
          </div>
        )}
        {window.innerWidth > 768 ? (
          <div className={classes.switch}> Switch User </div>
        ) : (
          <></>
        )}
        {window.innerWidth > 768 ? (
          <div className={classes.userCont}>
            {users &&
              users.map((name, idx) => (
                <button
                  onClick={(e) => changeUser(name, states.currentUser.email)}
                  className={classes.avatarName}
                  key={idx}
                >
                  <img
                    src={
                      states.userImage[name]
                        ? states.userImage[name].data
                        : avatarIcon
                    }
                    alt="avatar icon"
                    className={classes.avatar}
                  />
                  <div className={classes.user}>{name}</div>
                </button>
              ))}
            <button className={classes.avatarName}>
              <div className={classes.user}>Previous Report</div>
              <img
                src={draglist}
                alt="avatar icon"
                className={classes.avatar}
              />
            </button>
            <div
              onClick={() => {
                states.currentUser.email === "prakhar@aivot.in"
                  ? navigate("/selftest1")
                  : navigate("/selftest");
              }}
              className={classes.avatarName}
            >
              <div className={classes.user}>New Test</div>
              <img
                src={videoCall}
                alt="avatar icon"
                className={classes.avatar}
              />
            </div>
          </div>
        ) : (
          <UserSelect />
        )}
      </div>
      <div className={classes.pdfcontainer} id="pdfcontainer">
        {dataCount > 0 && states.currentUser.name !== undefined && (
          <PdfIcons
            showdata={[]}
            name={states.currentUser.name}
            navigate={navigate}
            accno={states.currentUser.email}
            userID={states.currentUser._id}
            dataCount={dataCount}
          />
        )}
      </div>
      {window.innerWidth <= 768 ? (
        <div>
          <div className={classes.testButton} onClick={() => navigate("/imr")}>
            IMR DASHBOARD
          </div>
          <div
            className={classes.testButton}
            onClick={() => navigate("/dashboard")}
          >
            DASHBOARD
          </div>
          <div
            className={classes.testButton}
            onClick={() => navigate("/selftest")}
          >
            SELF TEST
          </div>
          {/* <div className={classes.testButton} onClick={signout}>
            Sign out
          </div> */}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AppLayout()(Reports);
