import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  legend: "none",
  bar: { groupWidth: "30%" },
  candlestick: {
    hollowIsRising: true,
    fallingColor: { strokeWidth: 0, fill: "#a52714" }, // red
    risingColor: { strokeWidth: 0, fill: "#0f9d58" }, // green
  },
  backgroundColor: {
    fill: "#DFEFFE",
  },
  chartArea: {
    left: "50",
    right: "40",
    top: "40",
    bottom: "50",
  },

  hAxis: {
    title: "Date",
    titleTextStyle: {
      color: "#043E74", // Color of the title text
      fontSize: 8, // Font size of the title text
    },
    textStyle: {
      color: "#043E74", // Color of the axis labels
      fontName: "Arial", // Font family of the axis labels
      fontSize: 8, // Font size of the axis labels
    },
  },
  vAxis: {
    title: "Measurement",
    viewWindow: {
      min: 0, // Start the y-axis from 40
    },
    gridlines: {
      count: 6, // Number of intervals on y-axis (40, 50, 60, 70, 80, 90)
    },
    ticks: [0, 50, 100, 150], // Specific tick marks on y-axis
    titleTextStyle: {
      color: "#043E74", // Color of the title text
      fontSize: 10, // Font size of the title text
      italic: false, // Italic style of the title text
    },
    textStyle: {
      color: "#043E74", // Color of the axis labels
      fontSize: 10, // Font size of the axis labels
    },
  },
  tooltip: {
    isHtml: true, // Use HTML tooltips
  },
};

const CandleChart = ({ data }) => {
  return (
    <div
      style={
        window.innerWidth <= 768
          ? {
              width: "22rem",
              height: "34rem",
              overflowX: "auto",
            }
          : {}
      }
    >
      <Chart
        chartType="CandlestickChart"
        width={window.innerWidth > 768 ? "58vw" : "66rem"}
        height={window.innerWidth > 768 ? "50vh" : "33.5rem"}
        data={data}
        options={options}
        style={{
          flexGrow: 1,
        }}
      />
    </div>
  );
};

export default CandleChart;
