import React, { useContext, useEffect } from "react";
import itervelogo from "../../Images/itervelogo.png";
import classes from "./menu.module.css";
import dragListDown from "../../Images/dragListDown.png";
import homeIcon from "../../Images/homeIcon.png";
import profileIcon from "../../Images/profileIcon.png";
import videoIcon from "../../Images/videoIcon.png";
import imrIcon from "../../Images/imrIcon.png";
import calendarIcon from "../../Images/calendarIcon.png";
import reportsIcon from "../../Images/reportsIcon.png";
import privacyIcon from "../../Images/privacyIcon.png";
import termsConditionsIcon from "../../Images/termsConditionsIcon.png";
import logoutIcon from "../../Images/logoutIcon.png";
import deleteProfileIcon from "../../Images/deleteProfileIcon.png";
import crownIcon from "../../Images/crownIcon.png";
import { useNavigate } from "react-router-dom/dist";
import { SavedStateContext } from "../../context/SavedState";
import axios from "axios";
import imrwhite from "../../Images/Frequency.png";
import profileWhite from "../../Images/Client Management.png";
import selftestwhite from "../../Images/Live Video On.png";
import reportswhite from "../../Images/News.png";
import deleteWhite from "../../Images/Denied.png";
import dashboardWhite from "../../Images/Drag List Down White.png";

const Menu = () => {
  const navigate = useNavigate();

  const states = useContext(SavedStateContext);

  var p = window.location.href.split("/").filter(Boolean).pop();

  const signout = async () => {
    await axios
      .put("https://www.iterve.ai/uservitals", {
        params: {
          email: states.currentUser.email,
          deviceId: "",
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(null);
        localStorage.removeItem("currentUser");
        navigate("/home");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.container}>
      <img src={itervelogo} alt="iterve logo" className={classes.logo} />
      <div className={classes.elements}>
        <div className={classes.element1}>Menu</div>
        <div
          className={
            p === "dashboard"
              ? classes.elementWithIcon1
              : classes.elementWithIcon
          }
          onClick={() => navigate("/dashboard")}
        >
          <img
            src={p === "dashboard" ? dashboardWhite : dragListDown}
            className={classes.miniIcon}
          />
          <div className={classes.element}>Dashboard</div>
        </div>
        <div
          className={
            p === "home" ? classes.elementWithIcon1 : classes.elementWithIcon
          }
          onClick={() => navigate("/home")}
        >
          <img src={homeIcon} className={classes.miniIcon} />
          <div className={classes.element}>Home</div>
        </div>
        <div
          className={
            p === "profile" ? classes.elementWithIcon1 : classes.elementWithIcon
          }
          onClick={() => navigate("/profile")}
        >
          <img
            src={p === "profile" ? profileWhite : profileIcon}
            className={classes.miniIcon}
          />
          <div className={classes.element}>Profile</div>
        </div>
        <div
          className={
            p === "selftest"
              ? classes.elementWithIcon1
              : classes.elementWithIcon
          }
          onClick={() => {
            states.currentUser.email === "prakhar@aivot.in"
              ? navigate("/selftest1")
              : navigate("/selftest");
          }}
        >
          <img
            src={p !== "selftest" ? videoIcon : selftestwhite}
            className={classes.miniIcon}
          />
          <div className={classes.element}>Self test</div>
        </div>
        <div
          className={
            p === "imr" ||
            p === "heart" ||
            p === "bloodpressure" ||
            p === "bodywater" ||
            p === "oxygensaturation" ||
            p === "hemoglobin" ||
            p === "rbs" ||
            p === "stressindex"
              ? classes.elementWithIcon1
              : classes.elementWithIcon
          }
          onClick={() => navigate("/imr")}
        >
          <img
            src={p !== "imr" ? imrIcon : imrwhite}
            className={classes.miniIcon}
          />
          <div className={classes.element}>IMR Status</div>
        </div>
        <div
          className={classes.elementWithIcon}
          // onClick={() => navigate("/appointment")}
        >
          <img src={calendarIcon} className={classes.miniIcon} />
          <div className={classes.element}>Your Appointment</div>
        </div>
        <div
          className={
            p === "reports" ? classes.elementWithIcon1 : classes.elementWithIcon
          }
          onClick={() => navigate("/reports")}
        >
          <img
            src={p !== "reports" ? reportsIcon : reportswhite}
            className={classes.miniIcon}
          />
          <div className={classes.element}>Previous Report</div>
        </div>
        <div>
          <div className={classes.element1}>PP&TC</div>
        </div>
        <div
          className={classes.elementWithIcon}
          onClick={() => navigate("/privacypolicy")}
        >
          <img src={privacyIcon} className={classes.miniIcon} />
          <div className={classes.element}>Privacy & Policy</div>
        </div>
        <div
          className={
            p === "tncpage" ? classes.elementWithIcon1 : classes.elementWithIcon
          }
          onClick={() => navigate("/tncpage")}
        >
          <img src={termsConditionsIcon} className={classes.miniIcon} />
          <div className={classes.element}>Terms & Conditions</div>
        </div>
        <div className={classes.elementWithIcon} onClick={signout}>
          <img src={logoutIcon} className={classes.miniIcon} />
          <div className={classes.element}>Logout</div>
        </div>
        <div
          className={
            p === "delete" ? classes.elementWithIcon1 : classes.elementWithIcon
          }
          onClick={() => navigate("/delete")}
        >
          <img
            src={p === "delete" ? deleteWhite : deleteProfileIcon}
            className={classes.miniIcon}
          />
          <div className={classes.element}>Delete Profile</div>
        </div>
      </div>
      <div className={classes.goProSec}>
        <div className={classes.goProElement}>
          <img src={crownIcon} className={classes.miniIcon} />
          <div className={classes.goProText}>GO PRO</div>
        </div>
        <div className={classes.goProDesri}>
          Check your whole body vitals report.
        </div>
        <button className={classes.upgrade}>Upgrade Now</button>
      </div>
    </div>
  );
};

export default Menu;
