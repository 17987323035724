import { convertImageToCanvas, cropMask } from "./canvasUtil.js";

const extractRGBs = async (
  webcamRef,
  fps,
  faceLandmarker,
  detectionInProgressRef,
  setProgress,
  setDetectionProcessCount
) => {
  var okToExecute = detectionInProgressRef.current;
  setDetectionProcessCount((val) => {
    if (!okToExecute || val > 0) {
      okToExecute = false;
      return val;
    }
    return val + 1;
  });

  if (!okToExecute) return [true, true];

  var mean_rgbs = Array(1350)
    .fill()
    .map(() => [0, 0, 0]);

  return new Promise((resolve) => {
    const interval = 1000 / fps;
    const startTime = performance.now(); // to calculate total time spend
    const width = 320,
      height = 240; // screenshot dimensions
    let extracted_roi_count = 0,
      extracted_rgb_count = 0;
    let isCapturing = false; // Flag to indicate if captureFrame is currently running
    let prevIterationStartTime = performance.now();

    let totalDetectionTime = 0,
      totalImageLoadTime = 0,
      totalCanvasProcessingTime = 0,
      totalProcessExecutionTime = 0,
      totalGivenWatingTime = 0;
    let detectionStartTime = 0,
      imageLoadStartTime = 0,
      canvasProcessingStartTime = 0,
      ProcessExecutionStartTime = 0;

    const setRGB = async (img, faces, i, width, height, mean_rgbs) => {
      if (!faces[0] || !faces[0].keypoints) return;
      const landmark_points = faces[0].keypoints;
      const box = faces[0].box;
      const forehead = [
        landmark_points[9],
        landmark_points[107],
        landmark_points[66],
        landmark_points[105],
        landmark_points[104],
        landmark_points[103],
        landmark_points[67],
        landmark_points[109],
        landmark_points[10],
        landmark_points[338],
        landmark_points[297],
        landmark_points[332],
        landmark_points[333],
        landmark_points[334],
        landmark_points[296],
        landmark_points[336],
      ];

      const left_cheek = [
        landmark_points[266],
        landmark_points[426],
        landmark_points[436],
        landmark_points[416],
        landmark_points[376],
        landmark_points[352],
        landmark_points[347],
        landmark_points[330],
      ];

      const right_cheek = [
        landmark_points[36],
        landmark_points[206],
        landmark_points[216],
        landmark_points[192],
        landmark_points[147],
        landmark_points[123],
        landmark_points[117],
        landmark_points[118],
        landmark_points[101],
      ];

      const roi = [forehead, left_cheek, right_cheek];
      const h_ctx = convertImageToCanvas(img, faces[0].box);
      const rgb = cropMask(h_ctx, roi, box.xMin, box.yMin);
      if (!rgb || !mean_rgbs[i])
        console.log("undefined rgb found", i, rgb, mean_rgbs[i]);
      else {
        mean_rgbs[i][0] = rgb[0];
        mean_rgbs[i][1] = rgb[1];
        mean_rgbs[i][2] = rgb[2];
        extracted_rgb_count++;
        if (extracted_rgb_count >= 1350) resolve([mean_rgbs, 22.5]);
      }
    };

    const captureFrame = async () => {
      try {
        ProcessExecutionStartTime = performance.now();
        if (!detectionInProgressRef.current) {
          return resolve([true, true]);
        }
        if (isCapturing) {
          return setTimeout(captureFrame, 1);
        }
        isCapturing = true;

        const frame_index = extracted_roi_count;

        // ============================================================
        //                  image loading section
        // ============================================================
        imageLoadStartTime = performance.now();
        const ss = webcamRef.current.getScreenshot({
          width: width,
          height: height,
        });

        const loadImage = (src) =>
          new Promise((imgResolve) => {
            const img = new Image();
            img.onload = () => {
              imgResolve(img);
            };
            img.src = src;
          });

        const img = await loadImage(ss);
        totalImageLoadTime += performance.now() - imageLoadStartTime;
        // ============================================================
        //                  face detection section
        // ============================================================

        detectionStartTime = performance.now();
        const faceLandmarks = faceLandmarker.estimateFaces(img);
        extracted_roi_count++;
        totalDetectionTime += performance.now() - detectionStartTime;

        // ============================================================
        //                  canvas processing section
        // ============================================================
        canvasProcessingStartTime = performance.now();
        faceLandmarks.then((faces) => {
          if (!faces[0]) {
            setProgress(0);
            extracted_roi_count = Number.MAX_VALUE;
            resolve([false, false]);
            return;
          }
          setRGB(img, faces, frame_index, width, height, mean_rgbs);
        });
        totalCanvasProcessingTime +=
          performance.now() - canvasProcessingStartTime;

        if (extracted_roi_count % 100 === 0)
          console.log(extracted_roi_count / 13.5);
        if (extracted_roi_count % 30 === 0)
          setProgress(extracted_roi_count / 13.5);
        isCapturing = false; // unblock

        totalProcessExecutionTime +=
          performance.now() - ProcessExecutionStartTime;

        if (extracted_roi_count < 1350) {
          const presentTime = performance.now();
          const remainingTime = Math.max(
            0,
            interval - (performance.now() - ProcessExecutionStartTime)
          );
          prevIterationStartTime = presentTime;
          totalGivenWatingTime += remainingTime;
          setTimeout(async () => {
            isCapturing = false;
            await captureFrame();
          }, remainingTime);
        } else {
          setProgress(0);
          console.log("average image loading time", totalImageLoadTime / 1350);
          console.log("average face detection time", totalDetectionTime / 1350);
          console.log(
            "average canvas processing time",
            totalCanvasProcessingTime / 1350
          );
          console.log(
            "average process block execution time",
            totalProcessExecutionTime / 1350
          );
          console.log(
            "average iteration time",
            (performance.now() - startTime) / 1350
          );
          console.log(
            "average waiting time",
            (performance.now() - startTime - totalProcessExecutionTime) / 1350
          );
          console.log(
            "average waiting time(given by me)",
            totalGivenWatingTime / 1350
          );
          console.log(
            "total duration in secs",
            (performance.now() - startTime) / 1000
          );
        }
      } catch (err) {
        console.log(
          "error occurred while detecting face landmarks\nError:",
          err
        );
      }
    };

    captureFrame();
  });
};

export default extractRGBs;
