import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import classes from "./TnCPage.module.css";

const TnCPage = () => {
  return (
    <div>
      <Header />
      <div className={classes.heading}>Terms & Condition</div>
      <div className={classes.paragh}>
        Thank you for using our products and services (the “Service/s”). By
        becoming a member or using our Services, you accept and agree to these
        Terms and Conditions (“Terms”). Therefore, it is important that you
        carefully read through these Terms. If you do not agree to these Terms,
        you should not register with iTerve. The iTerve mobile app is operated
        and managed by iTerve Healthcare Solutions Pvt Ltd, a company
        incorporated under the provisions of the Companies Act, 2013, and having
        its registered office at [Your Address] on behalf of itself and of
        iTerve International LLC, with its principal place of business at [Your
        Address] (hereinafter referred to as “WE, OUR, or US”). These Terms of
        Use (the "Agreement") describe the terms and conditions under which
        users ("Users" or "you") can use the Application (as defined hereafter).
        To be able to use our Products, you first need to accept and agree to
        our Privacy Policy. You cannot use the Products unless you first accept
        our Privacy Policy. BY USING THE APPLICATION, YOU ALSO ACCEPT OUR TERMS
        OF USE. SO PLEASE MAKE SURE YOU CAREFULLY READ THE PRESENT TERMS OF USE
        BEFORE USING THE APPLICATION. If you have any questions about the
        Products (as defined hereinafter) of this Agreement, please contact us
        via support.iterve@aivot.ai. PLEASE NOTE THAT YOU ARE NOT OBLIGED TO
        INSTALL THE APPLICATION AND YOUR REFUSAL TO INSTALL THE APPLICATION DOES
        NOT HAVE ANY IMPACT ON A POTENTIAL TREATMENT BY YOUR PHYSICIAN OR THE
        QUALITY OF SUCH TREATMENT. IF YOU THINK YOU MAY HAVE A MEDICAL
        EMERGENCY, PLEASE CONTACT YOUR PHYSICIAN OR THE EMERGENCY SERVICES
        IMMEDIATELY.
        <div className={classes.bold}>Using our Services</div> To use our
        Services, you must follow some simple rules. The Service is only
        available for your private use and is not intended for commercial use.
        Do not misuse the Services, so that either iTerve or anyone else is
        harmed in any way. You may only use the Services as permitted by law and
        these Terms. By using our Services, you agree that: This app uses a
        phone camera and flashlight to determine heart rate and blood oxygen
        level. You may feel a little sensation on your finger as the scan
        proceeds. All of the personal data provided by you is accurate and up to
        date. You are solely responsible for all activities on your account and
        all the content that is uploaded and/or created under your iTerve
        account (“User Material”). iTerve does not monitor the contents of the
        Service but may at any time choose, at its sole discretion, to remove
        User Material from the Service and/or your user account and to terminate
        your account and membership. Your membership, including your email and
        password, with iTerve, is personal and may not be transferred or used by
        someone else. You are responsible for safely storing your login details.
        iTerve is not in any way responsible for any loss or damage caused by
        unauthorized access to your account or the use of your login details. If
        you learn of or suspect any unauthorized use of your account, you must
        immediately inform iTerve’s Customer Service. Contact details are
        available at the bottom of this document. iTerve is not intended for use
        by persons under the age of 13. To use iTerve, you must be at least 13
        years old. You may not engage in any commercial activities, advertise
        and/or provide hints (such as links) on where commercial activities are
        present through our Services. You may not contribute with any
        propaganda, religious and/or political views, or contribute with
        information that in any way contains or involves incitement to racial
        hatred, child pornography, or pornography through the use of our
        Services. Further, you may not defame, harass, or offend other people
        through the use of our Services. If you have any criticism or feedback
        regarding iTerve or our Services, you will first contact iTerve to help
        us to improve our Services. You may not transmit, and/or distribute
        files that may damage iTerve or others’ computers or property (such as
        viruses and trojan horses). You may not share others’ personal
        information, without their approval.
        <div className={classes.bold}>Intellectual Property</div> All rights in
        and to the Services, including any trademarks, service marks, trade
        names, and copyrighted content (collectively “Intellectual Property”)
        presented within the Service are the property of iTerve and/or third
        parties. You agree not to use Intellectual Property for any other
        purposes except for your use of the Service unless required otherwise by
        applicable mandatory law. By submitting User Material to iTerve, you
        warrant and represent that you hold the copyright, trademark, and/or
        other intellectual property rights to your content. You agree to grant
        iTerve a non-exclusive, transferable, sub-licensable, royalty-free,
        worldwide license to use User Material to the extent necessary for
        iTerve to operate and maintain the Service. This license shall remain
        valid until the respective User Material is deleted from the Service by
        you or by iTerve in accordance with these Terms.Disclaimer and
        Limitation of Liability You use the Service at your own risk. The
        Service is provided “AS IS”, without any warranties, and iTerve does not
        warrant that the Service and availability thereof will be uninterrupted
        or error-free. iTerve does not assume any responsibility for errors or
        omissions in the information or software or other documents, including
        User Material, which is referenced by or linked to. References or links
        to third parties' websites are provided "AS IS" without warranty of any
        kind, either express or implied. In no event shall iTerve be liable for
        any indirect or consequential damages, except in cases of intentional
        misconduct or gross negligence. To the maximum extent permitted under
        applicable law, iTerve’s aggregated liability for any direct damages
        shall be limited to the lesser of: (i) membership fees paid by the user;
        (ii) membership fees paid by the user during the previous calendar year.
        <div className={classes.bold}> Miscellaneous</div> You are not entitled
        to assign your rights and/or obligations under these Terms or use of the
        Service to any third party without iTerve’s prior written consent.
        iTerve is entitled to assign its rights and/or obligations under these
        Terms. iTerve reserves the right to refuse the Service to anyone for any
        reason at any time. iTerve may revise these Terms from time to time and
        the most current version will always be posted on iTerve’s website(
        iterve.ai). Any and all material changes shall become into effect
        between you and iTerve upon your acceptance of such changes (e.g. by
        using the Services after such notification has been made to you or
        renewing your subscription).
        <div className={classes.bold}>
          {" "}
          Governing Law and Dispute Resolution
        </div>{" "}
        This Agreement and any claims arising out of or relating to this
        Agreement and its subject matter shall be governed by and construed
        under the laws of India, without reference to its conflicts of law
        principles. <div className={classes.bold}>Contact Details</div> For
        further information, inquiries, or assistance you may contact iTerve.
        Customer Service is available in ways specified on the website or in the
        apps. The channels for support might differ from time to time. Contact
        Information Address -1609/1610, Kamdhenu commerce building, Kharghar,
        Navi Mumbai 410210. Email–support.iterve@aivot.ai
        <div className={classes.bold}> LEGAL AND MEDICAL DISCLAIMER</div> THIS
        APP DOES NOT PROVIDE MEDICAL/HEALTH ADVICE. The information including
        but not limited to text graphics images and vitals contained on this app
        are for information purposes only. No material on this app is intended
        to be a substitute for professional medical advice, diagnosis, or
        treatment. We recommend you consult your physician or other qualified
        health care providers with any questions you may have regarding a
        medical condition or treatment and never disregard professional medical
        advice or delay in seeking it because you have read something on this
        website. The information provided on our APP is for educational and
        informational purposes only and solely as a SELF HELP TOOL FOR YOUR OWN
        USE. We are not doctors/physicians or any medical health providers,
        registered or licensed. Always seek the advice of your Health Care
        Provider and We request you to not stop any medication or start any
        based on any information provided under this APP. All content and
        information contained in this APP DO NOT CONSTITUTE A PATIENT–CLIENT
        RELATIONSHIP. Always consult a professional in the area for your
        particular needs and circumstances prior to making any professional,
        legal, and medical decisions.
        <div className={classes.bold}>
          DO NOT USE THIS APP IF YOUR PHYSICIAN OR HEALTH CARE PROVIDER ADVISES
          AGAINST IT.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TnCPage;
