import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./PhoneChange.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import MobileFooter from "../../Components/Footer/MobileFooter";

const PhoneChange = () => {
  const navigate = useNavigate();
  const [curremail, setcurremail] = useState("");
  const [prevemail, setprevemail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [orgOtp, setOrgOtp] = useState("");
  const [thisotp, setthisotp] = useState("");
  const [emailError, setemailError] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [pass, setpass] = useState("");

  const states = useContext(SavedStateContext);
  const phoneRef = useRef();

  const handleSubmit = async () => {
    if (phoneNumber.length !== 10) {
      alert("Please enter a valid phone number");
      phoneRef.current.focus();
      return;
    }

    console.log(pass, states.currentUser.password);

    if (pass !== states.currentUser.password) {
      return;
    }

    await axios
      .put("https://www.iterve.ai/uservitals/updatephone", {
        params: {
          email: states.currentUser.email,
          newphone: phoneNumber,
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(res.data);
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   const validatePassword = (pass) => {
  //     const minLength = /.{8,}/;
  //     const hasNumber = /[0-9]/;
  //     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  //     const hasUpperCase = /[A-Z]/;
  //     const id = document.getElementById("pass");

  //     setpassword(pass);
  //     if (!minLength.test(pass)) {
  //       setpasswordError("Password must be at least 8 characters long.");
  //       return;
  //     } else if (!hasNumber.test(pass)) {
  //       setpasswordError("Password must contain at least one number.");
  //       return;
  //     } else if (!hasSpecialChar.test(pass)) {
  //       setpasswordError("Password must contain at least one special character.");
  //       return;
  //     } else if (!hasUpperCase.test(pass)) {
  //       setpasswordError("Password must contain at least one uppercase letter.");
  //       return;
  //     }
  //     setpasswordError("");
  //   };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headertext}>CHANGE PHONE NUMBER</div>
        <div className={classes.inputconatiner}>
          <div>
            <div className={classes.inputlabel}>Previous Phone Number - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => setcurremail(e.target.value)}
              value={states.currentUser.mobileNumber}
              readOnly
              type="number"
            />
          </div>
          <div>
            <div className={classes.inputlabel}>New Phone Number - </div>
            <input
              className={classes.inputtags}
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              type="tel"
              ref={phoneRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </div>
          <div className={classes.signin} onClick={() => setShowDialog(true)}>
            Submit
          </div>
        </div>
        {showDialog && (
          <div className={classes.dialogoverlay}>
            <div className={classes.dialogbox}>
              <div className={classes.dialogheader}>Enter the password</div>
              <input
                className={classes.dialoginput}
                type="text"
                value={pass}
                onChange={(e) => setpass(e.target.value)}
                placeholder="**********"
              />
              <div className={classes.submitbuttondiv}>
                <button
                  className={classes.dialogbutton1}
                  onClick={() => navigate("/settings")}
                >
                  Cancel
                </button>
                <button className={classes.dialogbutton} onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {window.innerWidth <= 768 && <MobileFooter />}
    </div>
  );
};

export default AppLayout()(PhoneChange);
