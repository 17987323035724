import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { SavedStateContext } from "../../context/SavedState";
import brainBody from "../../Images/brainBody.png";
import classes from "./login.module.css";
import MobileHeader from "../../Components/Header/MobileHeader";
import MobileFooter from "../../Components/Footer/MobileFooter";

const Login = ({ setuserExists }) => {
  const states = useContext(SavedStateContext);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [did, setdid] = useState("");
  const [alreadyLoggedin, setalreadyLoggedin] = useState(false);

  useEffect(() => {
    // Generate fingerprint
    Fingerprint2.get((components) => {
      const deviceId = Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(),
        31
      );
      console.log(deviceId); // Log the deviceId
      setdid(deviceId); // Assign the deviceId to the global variable
      // localStorage.setItem('deviceId', deviceId); // Save the deviceId in localStorage
    });
  }, [did]);

  useEffect(() => {
    console.log(did);
    if (did === "") return;
    axios
      .get("https://www.iterve.ai/uservitals/thisdid", {
        params: { did: did },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          // navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [did]);

  const navigate = useNavigate();
  const submitlogin = () => {
    console.log(email, password);
    const loginBtn = document.getElementById("loginBtn");
    loginBtn.style.pointerEvents = "none";
    loginBtn.style.cursor = "not-allowed";
    // console.log(loginBtn.classList);
    axios
      .get("https://www.iterve.ai/uservitals/login", {
        params: { email: email, password: password },
      })
      .then(async (res) => {
        console.log("login res", res.data);
        if (res.status === 200) {
          await axios
            .put("https://www.iterve.ai/uservitals/logindid", {
              params: {
                email: email,
                deviceId: did,
              },
            })
            .then((res) => {
              console.log(res);
              // setLoginInvoke && setLoginInvoke((val) => !val);
              setuserExists(true);
            })
            .catch((error) => {
              console.log(error);
            });
          states.setCurrentUser(res.data);
          localStorage.setItem("currentUser", JSON.stringify(res.data));
          navigate("/dashboard");
        } else {
          alert("Invalid login credentials");
          console.log(email, password);
          loginBtn.style.pointerEvents = "auto";
          loginBtn.style.cursor = "pointer";
        }
      })
      .catch((err) => {
        console.log("error in login: " + err.message);
        loginBtn.style.pointerEvents = "auto";
        loginBtn.style.cursor = "pointer";
      });
  };

  return (
    <>
      {window.innerWidth > 768 ? (
        <Header />
      ) : (
        <MobileHeader heading={"Login"} />
      )}
      <div className={classes.content}>
        <div className={classes.left}>
          <img
            src={brainBody}
            alt="brainBody"
            className={classes.brainBodyLogo}
          />
          <div
            style={{
              fontSize: "1vw",
              color: "#043E74",
              textAlign: "center",
            }}
          >
            Welcome to iTerve Health Check
          </div>
          <div
            style={{
              fontSize: "1vw",
              color: "#043E74",
              textAlign: "center",
              fontWeight: "lighter",
            }}
          >
            Powered by AIVOT AI Pvt Ltd
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.heading}>SIGN IN</div>
          <div className={classes.label}>Email ID-</div>
          <input
            type="text"
            className={classes.input}
            onChange={(e) => {
              setemail(e.target.value);
            }}
          />
          <div className={classes.label}>Password-</div>
          <div
            style={{
              display: "flex",
              position: "relative",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <input
              type={showPassword ? "text" : "password"}
              className={classes.input}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitlogin();
                }
              }}
            />
            {/* <img src={eyeLashes} alt="eye" className={classes.eye} /> */}
            {/* <IconButton> */}
            {showPassword ? (
              <Visibility
                sx={
                  window.innerWidth > 768
                    ? {
                        color: "#043E74",
                        position: "absolute",
                        right: "0.5vw",
                        top: "0.7vw",
                        cursor: "pointer",
                        width: "1.5vw",
                        height: "1.5vw",
                      }
                    : {
                        color: "#043E74",
                        position: "absolute",
                        right: "3vw",
                        top: "3vw",
                        cursor: "pointer",
                        width: "27px",
                        height: "27px",
                      }
                }
                onClick={() => {
                  setshowPassword((prev) => !prev);
                }}
              />
            ) : (
              <VisibilityOff
                sx={
                  window.innerWidth > 768
                    ? {
                        color: "#043E74",
                        position: "absolute",
                        right: "0.5vw",
                        top: "0.7vw",
                        cursor: "pointer",
                        width: "1.5vw",
                        height: "1.5vw",
                      }
                    : {
                        color: "#043E74",
                        position: "absolute",
                        right: "3vw",
                        top: "3vw",
                        cursor: "pointer",
                        width: "27px",
                        height: "27px",
                      }
                }
                onClick={() => {
                  setshowPassword((prev) => !prev);
                }}
              />
            )}
            {/* </IconButton> */}
          </div>

          <button className={classes.btn} onClick={submitlogin} id="loginBtn">
            SIGN IN
          </button>
          <div
            className={classes.register}
            onClick={() => navigate("/register")}
          >
            If not register,{" "}
            <span style={{ fontWeight: "bold" }}>Register Here</span>.
          </div>
        </div>
      </div>
      {window.innerWidth > 768 ? <Footer /> : <MobileFooter />}
    </>
  );
};

export default Login;
