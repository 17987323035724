import React, { useState, useEffect, useRef, useContext } from "react";
import Webcam from "react-webcam";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import classes from "./circWebcam.module.css";
import extractRGBs from "./selftestUtil.js";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import { FaceMesh } from "@mediapipe/face_mesh";
import { updateInput } from "./formUtil";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { SavedStateContext } from "../../context/SavedState.js";

import { v4 as uuidv4 } from "uuid";

function uploadDataToDatabase(
  vitalsData,
  rgbdata,
  navigate,
  email,
  name,
  usertype
) {
  if (usertype === "main") {
    console.log("usertype", usertype, email);
    axios
      .post("https://www.iterve.ai/uservitals", vitalsData, {
        params: { email: email, usertype: usertype },
      })
      .then((response) => {
        const rgbid = response.data;
        console.log(rgbid);
        axios
          .post("https://www.iterve.ai/rgbvalue", {
            params: { email: email, rgbid: rgbid, rgbdata: rgbdata },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            navigate("/results");
          });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  } else {
    axios
      .post("https://www.iterve.ai/uservitals/childtestsave", vitalsData, {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log(res);
        const rgbid = res.data;
        console.log(rgbid);
        axios
          .post("https://www.iterve.ai/rgbvalue", {
            params: { email: email, rgbid: rgbid, rgbdata: rgbdata },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;

            navigate("/results", { state: { vitalsData: vitalsData } });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

function calculateAge(dateString) {
  // Split the input date string to get day, month, and year
  let [day, month, year] = dateString.split("/").map(Number);

  // Create a date object from the input date
  let birthDate = new Date(year, month - 1, day);
  let today = new Date();

  // Calculate age
  let age = today.getFullYear() - birthDate.getFullYear();
  let monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if the birth date hasn't occurred yet this year
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

const generateCustomId = () => {
  const uuid = uuidv4();
  const letters = uuid
    .replace(/[^a-zA-Z]/g, "")
    .slice(0, 2)
    .toUpperCase(); // Get first two letters and convert to uppercase
  const digits = uuid.replace(/\D/g, "").slice(0, 4); // Remove non-digits and get the first 4 digits
  return `${letters}${digits}`;
};

function CircWebcam(props) {
  const states = useContext(SavedStateContext);
  console.log(states.currentUser);
  const interval = 500;
  const navigate = useNavigate();

  const accno = localStorage.getItem("accno");
  const page = localStorage.getItem("page");

  const maxTime = 60 * 1000;
  const fps = 30;

  const {
    startRecording,
    setRecording,
    userform,
    setLoading,
    email,
    name,
    usertype,
    isAlertActive,
    setAlertActive,
  } = props;
  const webcamRef = useRef();
  const [progress, setProgress] = useState(0);
  const [recorder, setRecorder] = useState(null);
  const [model, setModel] = useState(null);
  const [detectionInProgress, setDetectionInProgress] = useState(false);
  const [senddata, setsenddata] = useState({});
  const [detectionProcessCount, setDetectionProcessCount] = useState(0);
  const [cameraMode, setcameraMode] = useState("user");

  const detectionInProgressRef = useRef(detectionInProgress);

  useEffect(() => {
    detectionInProgressRef.current = detectionInProgress; // Update the ref when detectionInProgress changes
  }, [detectionInProgress]);

  useEffect(() => {
    const model_setter = async () => {
      const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      const detectorConfig = {
        // runtime: "tfjs",
        // // detectorModelUrl: `${process.env.PUBLIC_URL}/face_detector/model.json`,
        // detectorModelUrl: `https://www.iterve.ai/model/model.json`,
        runtime: "mediapipe", // or 'tfjs'
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
      };
      const detector = await faceLandmarksDetection.createDetector(
        model,
        detectorConfig
      );
      console.log(detector);
      setModel(detector);
    };
    model_setter();
  }, []);

  useEffect(() => {
    console.log("detection state changed", detectionInProgress);
    if (detectionInProgress) {
      console.log("called through useEffect");
      console.log("loading starts here", performance.now());
      const ss = webcamRef.current.getScreenshot({ width: 320, height: 240 });

      const loadImage = (src) =>
        new Promise((imgResolve) => {
          const img = new Image();
          img.onload = () => {
            imgResolve(img);
          };
          img.src = src;
        });

      loadImage(ss)
        .then((img) => model.estimateFaces(img))
        .then(console.log("loading resolves here", performance.now()))
        .then(
          setTimeout(() => {
            if (setLoading) setLoading(false);
            document
              .getElementById("webcam-container")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 1000)
        )
        .then(
          extractRGBs(
            webcamRef,
            fps,
            model,
            detectionInProgressRef,
            setProgress,
            setDetectionProcessCount
          ).then((res) => {
            setProgress(0);
            setRecording(false);
            const rgbDatas = res[0];
            const fps = res[1];

            if (!rgbDatas) {
              setDetectionInProgress(false);
              setAlertActive(true);
              window.alert(
                "Make sure that your face is in front of the camera"
              );
              setTimeout(() => setAlertActive(false), 100);
              return;
            }

            if (rgbDatas === true) return; // detection is not in progress
            console.log("rgb data", rgbDatas);
            // const userform = updateInput();
            userform[`whole_rgbData`] = JSON.stringify(rgbDatas);
            console.log("rgb data", userform["whole_rgbData"]);
            const customId = generateCustomId();
            userform["subject_id"] = customId;
            console.log(userform["dob"]);
            console.log(userform);
            var intage = userform["age"]
              ? parseInt(userform["age"])
              : calculateAge(userform["dob"]);
            var floatheight = parseFloat(userform["height"]);
            var floatweight = parseFloat(userform["weight"]);

            userform["age"] = intage;
            userform["height"] = floatheight;
            userform["weight"] = floatweight;

            userform["activity_factor"] = userform["activity_factor"]
              ? userform["activity_factor"]
              : 1.2;
            var formdata = new FormData();
            Object.entries(userform).forEach(([key, value]) => {
              formdata.append(key, value);
            });

            console.log([...formdata.entries()]);

            return fetch("https://myapi.aivot.ai/process_rgb_data", {
              method: "POST",
              body: formdata,
              // headers: {
              //   "Content-Type": "application/json",
              // },
            })
              .then((response) =>
                response.json().then((res) => {
                  console.log("response", res);
                  const vitalsData = {
                    ...res,
                    name: userform["name"],
                    phoneNumber: userform["mobileNumber"],
                    email: userform["email"],
                    diabetic: userform["diabetic"],
                    subjectid: userform["subject_id"],
                    activityFactor: userform["activity_factor"],
                    gender: userform["gender"],
                    age: userform["age"],
                    height: userform["height"],
                    weight: userform["weight"],
                    allowable_blood_loss:
                      res["Allowable Blood Loss [milliliters]"],
                    bmi: res["BMI [kg/m2]"],
                    body_fat: res["Body Fat [percentage]"],
                    body_water: res["Body Water [percentage]"],
                    breathing_rate: res["Breathing Rate [breaths per minute]"],
                    cardiac_index: res["Cardiac Index [L/(min·m²)]"],
                    cardiac_output: res["Cardiac Output [L/min]"],
                    dbp:
                      localStorage.getItem("accno") === "NDC_DC"
                        ? res["DBP_Old"]
                        : res["DBP [mmHg]"],
                    hdl: res["HDL [mg/dL]"],
                    hba1c: res["HbA1c [percentage]"],
                    heart_rate: res["Heart Rate [beats per minute]"],
                    hematocrit: res["Hematocrit [percentage]"],
                    hemoglobin: res["Hemoglobin [g/dL]"],
                    ibi: res["IBI [miliseconds]"],
                    ldl: res["LDL [mg/dL]"],
                    mean_arterial_pressure: res["Mean Artial Pressure [mmHg]"],
                    mean_corpuscular_hemoglobin:
                      res["Mean Corpuscular Hemoglobin [pg/cells]"],
                    mean_corpuscular_volume:
                      res["Mean Corpuscular Volume [fl]"],
                    mean_plasma_glucose: res["Mean Plasma Glucose [mg/dL]"],
                    oxygen_saturation: res["Oxygen Saturation [percentage]"],
                    pulse_pressure: res["Pulse Pressure [mmHg]"],
                    pulse_rate_quotient:
                      res["Pulse Rate Quotient [beats per breath]"],
                    rbc_count: res["RBC Count [million cells/mcL]"],
                    rmssd: res["RMSSD [miliseconds]"],
                    random_blood_sugar: res["Random Blood Sugar [mg/dL]"],
                    sbp:
                      localStorage.getItem("accno") === "NDC_DC"
                        ? res["SBP_Old"]
                        : res["SBP [mmHg]"],
                    sd1: res["SD1 [miliseconds]"],
                    sd2: res["SD2 [miliseconds]"],
                    sdnn: res["SDNN [miliseconds]"],
                    stress_index: res["Stress Index"],
                    stroke_volume: res["Stroke Volume [milliliters]"],
                    subcutaneous_fat: res["Subcutaneous Fat [percentage]"],
                    total_cholestrol: res["Total Cholesterol [mg/dL]"],
                    triglycerides: res["Triglycerides [mg/dL]"],
                    vldl: res["VLDL [mg/dL]"],
                    visceral_fat: res["Visceral Fat [percentage]"],
                    true_rbs_fbs: userform["rbs_fbs"],
                    true_sbp: userform["sbp"],
                    true_dbp: userform["dbp"],
                    weightChanged: userform["weightChanged"],
                    mealTimeLapsed: userform["mealTimeLapsed"],
                    hypertension: userform["hypertension"],
                    bloodPressure: userform["bloodPressure"],
                    majorHealthIssues: userform["majorHealthIssues"],
                    heartDisease: userform["heartDisease"],
                    familyDiabetes: userform["familyDiabetes"],
                    familyHypertension: userform["familyHypertension"],
                    medication: userform["medication"],

                    // New fields
                    t3: res["(T3) Triiodothyronine [ng/dL]"],
                    t4: res["(T4) Thyroxine [μg/dL]"],
                    tsh: res["(TSH) Thyroid Stimulating Hormone [μIU/mL]"],
                    blood_volume: res["Blood Volume [mL]"],
                    ibi: res["IBI [miliseconds]"],
                    // mean_arterial_pressure: res["Mean Artial Pressure [mmHg]"],
                    mean_plasma_glucose: res["Mean Plasma Glucose [mg/dL]"],
                    rbc_count: res["RBC Count [million cells/mcL]"],
                    vldl: res["VLDL [mg/dL]"],
                    hr_mad: res["hr_mad"],
                    pnn20: res["pnn20"],
                    pnn50: res["pnn50"],
                    s: res["s"],
                    sd1_sd2: res["sd1/sd2"],
                    vlf: res["vlf"],
                  };

                  console.log("res printed here", vitalsData);
                  uploadDataToDatabase(
                    vitalsData,
                    JSON.stringify(rgbDatas),
                    navigate,
                    email,
                    name,
                    usertype
                  );

                  setsenddata(vitalsData);
                  console.log(senddata);

                  console.log(res, [...userform.entries()]);
                })
              )
              .catch((e) => console.log(e.message));
          })
        );
    }
  }, [detectionInProgress]);

  useEffect(() => {
    if (document.getElementById("record-btn"))
      document.getElementById("record-btn").style.backgroundColor =
        startRecording ? "#444" : "#000";
    if (startRecording && model) {
      let elapsedTime = 0;
      if (!detectionInProgress) {
        setDetectionInProgress(true);
      }
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 640,
            height: 480,
          },
        })
        .then((stream) => {
          // function clockTick() {
          //   if (elapsedTime > maxTime || !startRecording) {
          //     setProgress(0);
          //     setRecording(false);
          //   } else {
          //     const newProgress = Math.min((elapsedTime / maxTime) * 100, 100);
          //     setProgress(newProgress);
          //     elapsedTime += interval;
          //     setTimeout(clockTick, interval);
          //   }
          // }
          // clockTick();
        });
    }
    if (!startRecording) {
      setDetectionInProgress(false);
    }
    return () => {
      setDetectionInProgress(false);
      if (recorder) {
        setRecorder(null);
      }
      setProgress(0);
    };
  }, [startRecording, model]);

  return (
    <div className={classes.column}>
      {!isAlertActive && (
        <div className={classes.webcamrecorder}>
          <div className={classes.progressbar}>
            <CircularProgressbar
              value={progress}
              styles={buildStyles({
                rotation: 0,
                pathColor: "#043E74",
                trailColor: "transparent",
              })}
            />
          </div>
          <div className={classes.circularframe} id="webcam-container">
            <canvas
              className={classes.webcamOverlay}
              id="webcam-overlay"
            ></canvas>
            <Webcam
              audio={false}
              videoConstraints={{
                facingMode: "user",
              }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className={classes.webcam}
              mirrored
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CircWebcam;
