import React, { useContext, useState } from "react";
import classes from "./Sendemail.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import {
  Edit as EditIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import { InputAdornment, TextField, Button } from "@mui/material";
import MobileFooter from "../../Components/Footer/MobileFooter";

const Sendemail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = () => {
    const data = {
      email,
      message,
    };
    console.log(data); // Logs the JSON object to the console
  };

  return (
    <div>
      <div className={classes.container}>
        {window.innerWidth >= 768 ? (
          <div className={classes.headertext}>CHANGE SETTINGS</div>
        ) : (
          <div className={classes.headertext}>Send Mail</div>
        )}

        {/* Input container with TextFields */}
        <div className={classes.inputconatiner}>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: "316px", height: "53px" }}
            InputLabelProps={{
              shrink: true, // This keeps the label fixed at the top
            }}
          />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={6} // Ensures the message field can hold more text
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ width: "316px", height: "169px" }}
            InputLabelProps={{
              shrink: true, // This keeps the label fixed at the top
            }}
          />
        </div>

        {/* Submit button */}
        <Button
          variant="contained"
          sx={{
            marginTop: "20px",
            backgroundColor: "#043E74",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#032E5A",
            },
          }}
          className={classes.submitbutton}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
      {window.innerWidth <= 768 && <MobileFooter />}
    </div>
  );
};

export default AppLayout()(Sendemail);
