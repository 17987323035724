import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import itervelogo from "../../Images/itervelogo.png";
import Header from "../../Components/Header/Header";
import faceLogo from "../../Images/faceLogo.png";
import classes from "./UserSelect.module.css";
import { SavedStateContext } from "../../context/SavedState";
import Instagram from "../../Images/Instagram.png";
import Twitter from "../../Images/TwitterX.png";
import LinkedIn from "../../Images/LinkedIn.png";
import menu from "../../Images/Menu.png";
import PPGRGB from "../../Images/PPGRGB.png";
import MobileHeader from "../../Components/Header/MobileHeader";
import avatarIcon from "../../Images/avatarIcon.png";
import downArrow from "../../Images/downArrow.png";
import upArrow from "../../Images/upArrow.png";
import axios from "axios";

const UserSelect = () => {
  const navigate = useNavigate();
  const states = useContext(SavedStateContext);
  const [selectedUser, setSelectedUser] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const users = states.users;

  const handleClick = () => {
    states.currentUser ? navigate("/dashboard") : navigate("/login");
  };

  const handleChange = (e) => {
    const selectedName = e.target.value;
    changeUser(selectedName, states.currentUser.email);
    setSelectedUser(""); // Reset to default option
  };

  const changeUser = (name, email) => {
    setShowDropdown(false);
    console.log("change user called", name, email);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={classes.selectuserdiv}>
        {users && (
          //   <select
          //     value={""}
          //     onChange={handleChange}
          //     className={classes.userSelectname}
          //   >
          //     <option value="" style={{ fontSize: "20px" }} disabled selected>
          //       Switch User
          //     </option>
          //     {users.map((name, idx) => (
          //       <option value={name} key={idx} className={classes.optiontext}>
          //         <div>
          //           <img
          //             src={
          //               states.userImage[name]
          //                 ? states.userImage[name].data
          //                 : avatarIcon
          //             }
          //             alt={avatarIcon}
          //             className={classes.avatar}
          //           />
          //           {name}
          //         </div>
          //       </option>
          //     ))}
          //   </select>
          // )}
          <div className={classes.dropdown}>
            <button
              className={classes.dropbtn}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <div className={classes.switchUser}>Switch User</div>
              <img
                src={showDropdown ? upArrow : downArrow}
                alt="downArrow"
                className={classes.downArrow}
              />
            </button>

            <div
              className={classes.dropdownContent}
              style={showDropdown ? { display: "block" } : { display: "none" }}
            >
              {users.map((name, idx) => (
                <div
                  className={classes.imgUserWrapper}
                  onClick={() => changeUser(name, states.currentUser.email)}
                >
                  <img
                    src={
                      states.userImage[name]
                        ? states.userImage[name].data
                        : avatarIcon
                    }
                    alt={avatarIcon}
                    className={classes.avatar}
                  />
                  {name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserSelect;
