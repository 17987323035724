import React from "react";
import Instagram from "../../Images/Instagram.png";
import LinkedIn from "../../Images/LinkedIn.png";
import PPGRGB from "../../Images/PPGRGB.png";
import Twitter from "../../Images/TwitterX.png";
import classes from "./MobileFooter.module.css";
const MobileFooter = () => {
  return (
    <div className={classes.mobileFooter}>
      <div className={classes.joinustext}>Join Us</div>
      <div className={classes.email}>
        <input className={classes.emailbox} placeholder="Send mail" />
      </div>
      <div className={classes.joinbuttons}>
        <img src={Instagram} className={classes.socialImage} />
        <img src={Twitter} className={classes.socialImage} />
        <img src={LinkedIn} className={classes.socialImage} />
      </div>
    </div>
  );
};

export default MobileFooter;
