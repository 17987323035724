import React from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import bodyParamImage from "../../Images/lungs.png";

const OxygenSaturation = () => {
  const topic = "Oxygen Saturation Rate";
  const params = [
    "Pulse Pressure",
    "IBI",
    "Stress Index",
    "Cardiac Output",
    "Stroke Volume",
  ];

  const name = "Oxygen Saturation";

  return (
    <BodyParamsLayout
      topic={topic}
      params={params}
      name={name}
      img={bodyParamImage}
    />
  );
};

export default AppLayout()(OxygenSaturation);
