import React, { useState, useEffect, useContext, useRef } from "react";
import classes from "./selfTest1.module.css";
import AppLayout from "../../layout/AppLayout/AppLayout";
import avatarIcon from "../../Images/avatarIcon.png";
import dropDownIcon from "../../Images/dropDownIcon.png";
import CircWebcam from "./CircWebcam";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SavedStateContext } from "../../context/SavedState";
import { set } from "mongoose";

const SelfTest1 = () => {
  const states = useContext(SavedStateContext);

  const email = states.currentUser.email;
  const name = states.currentUser.name;
  const usertype = states.currentUser.usertype;
  console.log(email, name, usertype);

  const [userform, setuserform] = useState({});
  const [thispageform, setthispageform] = useState({});
  const [selectedTime, setSelectedTime] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [form, setForm] = useState(1);
  const [isAlertActive, setAlertActive] = useState(false);

  const permissionRef = useRef();

  function alertWDC(message) {
    setAlertActive(true);
    alert(message);
    setTimeout(() => setAlertActive(false), 100);
  }

  // useEffect(() => {
  //   axios
  //     .get("https://www.iterve.ai/uservitals/registeruser", {
  //       params: { e: email, name: name },
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setuserform((prevUserForm) => ({
  //         ...prevUserForm,
  //         ...res.data,
  //       }));
  //       console.log(userform);
  //     });
  // }, [states.currentUser]);

  useEffect(() => {
    console.log(userform);
  }, [userform]);

  const changeUser = (name, email) => {
    console.log("change user called", name, email);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNext = () => {
    if (form === 1) setForm(2);
    else setForm(1);
  };

  const userInfo = [
    { label: "Name-", name: "name", placeholder: "Enter your name" },
    {
      label: "Age-",
      name: "age",
      placeholder: "Enter your age",
    },
    {
      label: "Height-",
      name: "height",
      placeholder: "Enter your height in mtr",
    },
    {
      label: "Weight-",
      name: "weight",
      placeholder: "Enter your weight in kg",
    },
    {
      label: "Email-",
      name: "email",
      placeholder: "Enter your email",
    },
    {
      label: "Mobile Number-",
      name: "mobileNumber",
      placeholder: "Enter your mobile number",
    },
  ];

  const healthParams = [
    {
      label: "Gender",
      name: "gender",
      options: ["Male", "Female"],
    },
    { label: "Diabetic-", name: "diabetic", options: ["yes", "no"] },
    {
      label: "Blood Pressure-",
      name: "bloodPressure",
      options: ["High", "Normal", "Low"],
    },
    { label: "Heart Disease-", name: "heartDisease", options: ["yes", "no"] },
    {
      label: "Major Health Issues-",
      name: "majorHealthIssues",
      options: [
        "None",
        "Cancer",
        "Heart Attack",
        "Mental Health disorder",
        "Autoimmune Disease",
        "Kidney Disease",
      ],
    },
  ];

  const firstForm = () => (
    <div>
      <div className={classes.headingOfMiniCont}>User Information</div>
      <div className={classes.miniCont}>
        {userInfo.map((detail, idx) => (
          <div className={classes.detailsRow} key={idx}>
            <div className={classes.userData1}>{detail.label}</div>
            <input
              onChange={handleInputChange}
              name={detail.name}
              defaultValue=""
              className={classes.userInput1}
              placeholder={detail.placeholder}
              value={userform[detail.name]}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const secondForm = () => (
    <div>
      <div className={classes.headingOfMiniCont}>User Information</div>
      <div className={classes.miniCont}>
        {healthParams.map((detail, idx) => (
          <div className={classes.detailsRow} key={idx}>
            <div className={classes.userData}>{detail.label}</div>
            <select
              className={classes.userInput}
              name={detail.name}
              onChange={handleInputChange}
              value={userform[detail.name] ? userform[detail.name] : ""}
            >
              <option value="" disabled>
                Please select {detail.label}
              </option>
              {detail.options.map((option, optionIdx) => (
                <option key={optionIdx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        ))}

        <div className={classes.detailsRow}>
          <div className={classes.userData}>Activity Factor</div>

          <select
            className={classes.userInput}
            onChange={handleInputChange}
            name={"activity_factor"}
            value={userform.activity_factor ? userform.activity_factor : ""}
          >
            <option value="" disabled>
              Please select Activity Factor
            </option>
            <option value="1.2">Sedentary Active</option>
            <option value="1.375">Lightly Active</option>
            <option value="1.55">Moderately Active</option>
            <option value="1.725">Very Active</option>
            <option value="1.9">Extra Active</option>
          </select>
        </div>
      </div>
    </div>
  );

  const handleInputChange = (e) => {
    console.log(e.target.value);
    setuserform((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const [isRecording, setRecording] = React.useState(false);
  const [isModelLoading, setModelLoading] = React.useState(false);

  const handleRecord = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const ageRegex = /^\d+$/;
    const weightRegex = /^\d+(\.\d+)?$/;
    const genderRegex = /^(Male|Female)$/;

    if (isRecording) {
      setRecording(false);
      return;
    }
    if (!isChecked) {
      alertWDC("Please agree to the privacy policy");
      permissionRef.current.focus();
      return;
    }

    if (!userform.name) {
      alertWDC("Please enter your name.");
      return;
    }

    if (!userform.age || !ageRegex.test(userform.age)) {
      alertWDC("Please enter a valid age.");
      return;
    }

    const heightValue = parseFloat(userform.height);
    if (
      !userform.height ||
      isNaN(heightValue) ||
      heightValue < 1 ||
      heightValue > 2.2
    ) {
      alertWDC("Please enter a valid height between 1 and 2.2 meters.");
      return;
    }
    const weightValue = parseFloat(userform.weight);
    if (
      !weightValue ||
      !weightRegex.test(weightValue) ||
      weightValue < 20 ||
      weightValue > 200
    ) {
      alertWDC("Please enter a valid weight between 20 and 200 kgs.");
      return;
    }

    if (userform.email && !emailRegex.test(userform.email)) {
      alertWDC("Please enter a valid email address.");
      return;
    }

    if (userform.mobileNumber && !phoneRegex.test(userform.mobileNumber)) {
      alertWDC("Please enter a valid 10-digit phone number.");
      return;
    }

    if (!userform.gender || !genderRegex.test(userform.gender)) {
      alertWDC("Please select gender");
      return;
    }

    if (
      userform.diabetic &&
      userform.bloodPressure &&
      userform.heartDisease &&
      userform.activity_factor
    ) {
      console.log("userform", userform);
      setRecording(true);
    } else {
      if (!userform.diabetic) {
        alertWDC("Please select diabetic");
      } else if (!userform.bloodPressure) {
        alertWDC("Please select blood pressure");
      } else if (!userform.heartDisease) {
        alertWDC("Please select heart disease");
      } else if (!userform.activity_factor) {
        alertWDC("Please select activity factor");
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={classes.container}>
      <div className={classes.hellotext}>
        HELLO,
        <div className={classes.name}>
          {states.currentUser ? states.currentUser.name : ""}
        </div>
      </div>
      <div style={{ display: "flex", gap: "3vw" }}>
        {states.users ? (
          states.users.map((user, index) => (
            <div
              key={index}
              className={classes.avatarName}
              onClick={() => changeUser(user, email)}
            >
              <img
                src={
                  states.userImage[user]
                    ? states.userImage[user].data
                    : avatarIcon
                }
                alt={avatarIcon}
                className={classes.avatar}
              />
              <div className={classes.user}>{user}</div>
            </div>
          ))
        ) : (
          <></>
        )}
        <div className={classes.prevReportSec}>
          <div className={classes.user}>Previous Report</div>
          <img
            src={dropDownIcon}
            alt="avatar icon"
            className={classes.avatar}
          />
        </div>
      </div>

      <div className={classes.userSec}>
        <div className={classes.headMiniCont}>
          {form === 1 ? firstForm() : secondForm()}
          {form === 1 ? (
            <button className={classes.formButton} onClick={handleNext}>
              Next
            </button>
          ) : (
            <button className={classes.formButton} onClick={() => setForm(1)}>
              Prev
            </button>
          )}
        </div>
        <div className={classes.webcamSection}>
          <CircWebcam
            startRecording={isRecording}
            setRecording={setRecording}
            userform={userform}
            setLoading={setModelLoading}
            email={email}
            name={name}
            usertype={usertype}
            isAlertActive={isAlertActive}
            setAlertActive={setAlertActive}
          />
          <button onClick={handleRecord} className={classes.recordBtn}>
            {!isRecording ? "Start" : "Stop"}
          </button>
        </div>
      </div>

      <div className={classes.tncContainer}>
        <div className={classes.disclaimer}>
          Your video and picture is not stored in our database; our system
          extracts only RGB and rPPG from camera processing and uses it to
          derive your health vitals.
        </div>
        <label style={{ cursor: "pointer" }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            ref={permissionRef}
            style={{ cursor: "pointer" }}
          />
          I agree to share my data for research and training purposes.
        </label>
      </div>
    </div>
  );
};

export default AppLayout()(SelfTest1);
