import React from "react";
import itervelogo from "../../Images/itervelogo.png";
import classes from "./SplashScreen.module.css";
import { useNavigate } from "react-router-dom";

const SplashScreen = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.mobilediv}>
        <img className={classes.logoimage} src={itervelogo} />
      </div>
      <div className={classes.welcomeCont}>
        <div className={classes.text} onClick={() => navigate("/home")}>
          WELCOME
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
