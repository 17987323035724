import React from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import whiteHeart from "../../Images/whiteHeart.png";

const BloodPressure = () => {
  const topic = "Blood Pressure";
  const params = [
    "Pulse Pressure",
    "Mean Arterial Pressure",
    "Cardiac Output",
    "Cardiac Index",
    "Stroke Volume",
  ];

  const name = "SBP/DBP";

  return (
    <BodyParamsLayout
      topic={topic}
      params={params}
      name={name}
      img={whiteHeart}
    />
  );
};

export default AppLayout()(BloodPressure);
