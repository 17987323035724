import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import classes from "./PatientAppointment.module.css";
import schedapp from "../../Images/Schedule.png";
import totdoc from "../../Images/Out Patient Department.png";
import videocon from "../../Images/Video Conference.png";
import phonecon from "../../Images/Call male.png";
import listdown from "../../Images/Drag List Down.png";
import doctorImg from "../../Images/avatarIcon.png";
import TitleBar from "../../Components/TitleBar/TitleBar";
import AppLayout from "../../layout/AppLayout/AppLayout";

const PatientAppointment = () => {
  const [selectedNav, setSelectedNav] = React.useState(null);
  const [date, setDate] = React.useState(new Date());

  const consultedDoctors = [
    {
      name: "Ashwini Chawan",
      designation: "MBBS/MS",
      consultType: "Clinic Consulting",
      date: "20-Aug-2024",
      time: "8:30PM - 9:30PM    1hr",
      address:
        "1609/1610, Kamdhenu, E-Com, Sector 13, Kharghar, Navi Mumbai - 410210",
      areaofconsideration: [
        "Pain in Chest",
        "Cough",
        "Losing weight",
        "Loss of appetite",
      ],
    },
    {
      name: "Yash Rathi",
      designation: "MBBS/MS",
      consultType: "Clinic Consulting",
      date: "20-Aug-2024",
      time: "8:30PM - 9:30PM    1hr",
      address:
        "1609/1610, Kamdhenu, E-Com, Sector 13, Kharghar, Navi Mumbai - 410210",
      areaofconsideration: ["Diet Management", "Weight Loss", "Exercise"],
    },
  ];

  const handleClick = (navItem) => {
    if (selectedNav === navItem) {
      setSelectedNav(null);
    } else {
      setSelectedNav(navItem);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.minicont}>
        <div className={classes.headnav}>
          <div
            onClick={() => handleClick("appointment")}
            className={
              selectedNav === "appointment"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Appointment
          </div>
          <div
            onClick={() => handleClick("doctors")}
            className={
              selectedNav === "doctors"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            My Doctors
          </div>
          <div
            onClick={() => handleClick("schedule")}
            className={
              selectedNav === "schedule"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Scheduled Timing
          </div>
          <div
            onClick={() => handleClick("payments")}
            className={
              selectedNav === "payments"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Payments
          </div>
          <div
            onClick={() => handleClick("message")}
            className={
              selectedNav === "message"
                ? classes.navstyleselected
                : classes.navstyle
            }
          >
            Message
          </div>
        </div>
        <div className={classes.buttondiv}>
          <div className={classes.schedulediv}>
            <div className={classes.imgtextdiv}>
              <img src={schedapp} />
              <div className={classes.imgtext}>20</div>
            </div>
            Schedule Appointments
          </div>
          <div className={classes.totdoctordiv}>
            <div className={classes.imgtextdiv}>
              <img src={totdoc} />
              <div className={classes.imgtext}>20</div>
            </div>
            Total Doctors
          </div>
          <div className={classes.videoconsultdiv}>
            <div className={classes.imgtextdiv}>
              <img src={videocon} />
              <div className={classes.imgtext}>20</div>
            </div>
            Video Consulting
          </div>
          <div className={classes.phoneconsultdiv}>
            <div className={classes.imgtextdiv}>
              <img src={phonecon} />
              <div className={classes.imgtext}>20</div>
            </div>
            Phone Consulting
          </div>
        </div>
        <div className={classes.listdiv}>
          <div className={classes.calendarContainer}>
            <div className={classes.calendertext}>Calendar</div>
            <Calendar onChange={setDate} value={date} />
          </div>
          {consultedDoctors.map((doctor, index) => (
            <div key={index} className={classes.consultediv}>
              <div className={classes.doctorHeader}>
                <div className={classes.doctorName}>{doctor.name}</div>
                <div className={classes.doctorDesignation}>
                  {doctor.designation}
                </div>
                <div className={classes.consultType}>{doctor.consultType}</div>
              </div>
              <div className={classes.doctorDetails}>
                <div className={classes.dateTime}>
                  <div>Date</div>
                  <div className={classes.date}>
                    <div>{doctor.date}</div>
                  </div>
                  <div className={classes.time}>{doctor.time}</div>
                </div>
                <div className={classes.addareadiv}>
                  <div className={classes.address}>{doctor.address}</div>
                  <ul className={classes.areaOfConsideration}>
                    {doctor.areaofconsideration.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppLayout()(PatientAppointment);
