import React, { useContext, useState } from "react";
import classes from "./Register.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import { SavedStateContext } from "../../context/SavedState";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import menu from "../../Images/Menu.png";
import itervelogo from "../../Images/itervelogo.png";
import Instagram from "../../Images/Instagram.png";
import Twitter from "../../Images/TwitterX.png";
import LinkedIn from "../../Images/LinkedIn.png";

// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { CalendarMonth } from "@mui/icons-material";
import MobileFooter from "../../Components/Footer/MobileFooter";
import MobileHeader from "../../Components/Header/MobileHeader";

const Register = () => {
  const navigate = useNavigate();
  const states = useContext(SavedStateContext);
  const [formData, setFormData] = useState({});
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const [currentAge, setCurrentAge] = useState();

  const fields1 = [
    { label: "Name", name: "name", placeholder: "Enter your name" },
    { label: "Email ID", name: "email", placeholder: "Enter your email" },
    {
      label: "Mobile Number",
      name: "mobileNumber",
      placeholder: "Enter your mobile number",
    },
  ];

  const fields2 = [
    { label: "Age", name: "age", placeholder: "Enter your date of birth" },
    {
      label: "Height",
      name: "height",
      placeholder: "Enter your height (in meters)",
    },
    {
      label: "Weight",
      name: "weight",
      placeholder: "Enter your weight (in kg)",
    },
    { label: "State", name: "state", placeholder: "Enter your state" },
    { label: "PIN CODE", name: "pincode", placeholder: "Enter your pincode" },
  ];

  function calculateAge(dateString) {
    // Split the input date string to get day, month, and year
    let [day, month, year] = dateString.split("/").map(Number);

    // Create a date object from the input date
    let birthDate = new Date(year, month - 1, day);
    let today = new Date();

    // Calculate age
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleGenderChange = (event) => {
    setFormData({ ...formData, ["gender"]: event.target.value });
    setGender(event.target.value);
  };

  function formatDateToString(date) {
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleDobChange = (date) => {
    setDob(date);
    console.log(date);
    setCurrentAge(calculateAge(format(date, "dd/MM/yyyy")));
    setFormData({
      ...formData,
      ["dob"]: format(date, "dd/MM/yyyy"),
    });
  };

  const handleSubmit = async () => {
    const submitReg = document.getElementById("submitReg");
    submitReg.style.pointerEvents = "none";
    const {
      name,
      email,
      mobileNumber,
      gender,
      height,
      weight,
      state,
      pincode,
      dob,
    } = formData;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const weightRegex = /^\d+(\.\d+)?$/;
    const pincodeRegex = /^\d{6}$/;
    const dobRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const stateRegex = /^[A-Za-z\s]+$/;
    const genderRegex = /^(Male|Female)$/;

    if (!name) {
      alert("Please enter your name.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }
    if (!email.endsWith(".ai") && !email.endsWith(".in")) {
      alert("Email must be of .ai or .in domain");
      submitReg.style.pointerEvents = "auto";
      return false;
    }
    if (!email || !emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!mobileNumber || !phoneRegex.test(mobileNumber)) {
      alert("Please enter a valid 10-digit phone number.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!gender || !genderRegex.test(gender)) {
      alert("Please select gender");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    const heightValue = parseFloat(height);
    if (!height || isNaN(heightValue) || heightValue < 1 || heightValue > 2.2) {
      alert("Please enter a valid height between 1 and 2.2 meters.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!weight || !weightRegex.test(formData.weight)) {
      alert("Please enter a valid weight.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!pincode || !pincodeRegex.test(pincode)) {
      alert("Please enter a valid 6-digit pincode.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!state || !stateRegex.test(state)) {
      alert("Please enter a valid state name containing only alphabets.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    if (!dob || !dobRegex.test(dob)) {
      alert("Please enter a valid date of birth in the format MM-DD-YYYY.");
      submitReg.style.pointerEvents = "auto";
      return false;
    }

    console.log(formData);

    var e = formData["email"];

    await axios
      .get("https://www.iterve.ai/uservitals/registerMainUser", {
        params: { e },
      })
      .then((res) => {
        console.log(res);
        if (!res.data || res.data === "") {
          axios
            .get("https://www.iterve.ai/otp/getemail", {
              params: { email: formData.email },
            })
            .then(async (res) => {
              console.log(res);
              if (res.data) {
                axios
                  .delete("https://www.iterve.ai/otp/deleteotp", {
                    params: { email: res.data.email },
                  })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }

              await axios
                .post("https://www.iterve.ai/otp/postotp", {
                  params: { email: formData.email },
                })
                .then((res) => {
                  console.log(res);
                  navigate("/passcreate", { state: { formData: formData } });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((error) => {
              console.log(error);
              submitReg.style.pointerEvents = "auto";
            });
        } else {
          alert("User already exists");
          submitReg.style.pointerEvents = "auto";
        }
        console.log(res);
      });
  };

  return (
    <div>
      {window.innerWidth > 768 ? (
        <Header />
      ) : (
        <MobileHeader heading={"Register"} />
      )}
      <div className={classes.container}>
        <div className={classes.registertext}>REGISTER</div>
        <div className={classes.inputcontainers}>
          <div className={classes.leftcontainer}>
            {fields1.map((field, index) => (
              <div key={index} className={classes.lableNameWrap}>
                <div className={classes.inputlabel}>{field.label}:</div>
                <input
                  name={field.name}
                  id={field.name}
                  className={classes.inputtags}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  autoComplete="off"
                  placeholder={field.placeholder}
                />
              </div>
            ))}
            <div className={classes.genderCont}>
              <label htmlFor="gender" className={classes.inputlabel}>
                Gender:{" "}
              </label>
              <select
                id="gender"
                value={gender}
                onChange={handleGenderChange}
                className={classes.inputtags}
              >
                <option value="" disabled>
                  Please select your gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <div
                className={classes.genderCont}
                style={{ position: "relative" }}
              >
                <div className={classes.inputlabel}>Date of Birth:</div>
                <DatePicker
                  placeholderText="Select your date of birth"
                  selected={dob}
                  onChange={handleDobChange}
                  className={classes.inputtags}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                />
                <CalendarMonth className={classes.calendarIcon} />
              </div>
            </div>
          </div>
          <div className={classes.rightcontainer}>
            {fields2.map((field, index) => (
              <div key={index} className={classes.lableNameWrap}>
                <div className={classes.inputlabel}>{field.label}:</div>
                <input
                  name={field.name}
                  id={field.name}
                  className={classes.inputtags}
                  onChange={handleInputChange}
                  value={
                    field.name === "age" ? currentAge : formData[field.name]
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  autoComplete="off"
                  placeholder={field.placeholder}
                  readOnly={field.name === "age" ? true : false}
                />
              </div>
            ))}
          </div>
        </div>
        {window.innerWidth > 768 ? (
          <div className={classes.next} onClick={handleSubmit} id="submitReg">
            NEXT
          </div>
        ) : (
          <div className={classes.submit} onClick={handleSubmit} id="submitReg">
            NEXT
          </div>
        )}
      </div>
      {window.innerWidth > 768 ? <Footer /> : <MobileFooter />}
    </div>
  );
};

export default Register;
