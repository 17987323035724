import React from "react";
import classes from "./AppointmentHeader.module.css";
import schedapp from "../../Images/Schedule.png";
import totdoc from "../../Images/Out Patient Department.png";
import videocon from "../../Images/Video Conference.png";
import phonecon from "../../Images/Call male.png";

const AppointmentHeader = () => {
  const [selectedNav, setSelectedNav] = React.useState(null);

  const handleClick = (navItem) => {
    if (selectedNav === navItem) {
      setSelectedNav(null);
    } else {
      setSelectedNav(navItem);
    }
  };
  return (
    <div>
      <div className={classes.buttondiv}>
        <div className={classes.schedulediv}>
          <div className={classes.imgtextdiv}>
            <img src={schedapp} />
            <div className={classes.imgtext}>20</div>
          </div>
          Schedule Appointments
        </div>
        <div className={classes.totdoctordiv}>
          <div className={classes.imgtextdiv}>
            <img src={totdoc} />
            <div className={classes.imgtext}>20</div>
          </div>
          Total Doctors
        </div>
        <div className={classes.videoconsultdiv}>
          <div className={classes.imgtextdiv}>
            <img src={videocon} />
            <div className={classes.imgtext}>20</div>
          </div>
          Video Consulting
        </div>
        <div className={classes.phoneconsultdiv}>
          <div className={classes.imgtextdiv}>
            <img src={phonecon} />
            <div className={classes.imgtext}>20</div>
          </div>
          Phone Consulting
        </div>
      </div>
      <div className={classes.headnav}>
        <div
          onClick={() => handleClick("appointment")}
          className={
            selectedNav === "appointment"
              ? classes.navstyleselected
              : classes.navstyle
          }
        >
          Appointment
        </div>
        <div
          onClick={() => handleClick("doctors")}
          className={
            selectedNav === "doctors"
              ? classes.navstyleselected
              : classes.navstyle
          }
        >
          My Doctors
        </div>
        <div
          onClick={() => handleClick("schedule")}
          className={
            selectedNav === "schedule"
              ? classes.navstyleselected
              : classes.navstyle
          }
        >
          Scheduled Timing
        </div>
        <div
          onClick={() => handleClick("payments")}
          className={
            selectedNav === "payments"
              ? classes.navstyleselected
              : classes.navstyle
          }
        >
          Payments
        </div>
        <div
          onClick={() => handleClick("message")}
          className={
            selectedNav === "message"
              ? classes.navstyleselected
              : classes.navstyle
          }
        >
          Message
        </div>
      </div>
    </div>
  );
};

export default AppointmentHeader;
