import React, { useContext, useEffect, useState } from "react";
import classes from "./Settings.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import {
  Edit as EditIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import { InputAdornment, TextField } from "@mui/material";
import MobileFooter from "../../Components/Footer/MobileFooter";

const Settings = () => {
  const navigate = useNavigate();

  const states = useContext(SavedStateContext);

  return (
    <div>
      <div className={classes.container}>
        {window.innerWidth > 768 ? (
          <div className={classes.headertext}>CHANGE SETTINGS</div>
        ) : (
          <div className={classes.headertext}>SETTINGS</div>
        )}
        <div className={classes.inputconatiner}>
          {window.innerWidth > 768 ? (
            <TextField
              label="Change Email ID"
              variant="outlined"
              value={states.currentUser.email}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <EditIcon
                      sx={{
                        color: "#043E74",
                      }}
                      onClick={() => navigate("/emailchange")}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#043E74",
                  fontSize: "1.5vw",
                  cursor: "pointer",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#043E74",
                    fontSize: "1vw",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#043E74",
                  fontSize: "1vw",
                },
              }}
            />
          ) : (
            <TextField
              label="Change Email ID"
              variant="outlined"
              value={states.currentUser.email}
              className={classes.valueinput}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <EditIcon
                      sx={{
                        color: "#043E74",
                      }}
                      onClick={() => navigate("/emailchange")}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#043E74",
                  fontSize: "16px",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#043E74",
                    fontSize: "16px",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#043E74",
                  fontSize: "16px",
                },
              }}
            />
          )}
          {window.innerWidth > 768 ? (
            <TextField
              label="Change Phone Number"
              variant="outlined"
              value={states.currentUser.mobileNumber}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <EditIcon
                      sx={{
                        color: "#043E74",
                      }}
                      onClick={() => navigate("/phonechange")}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#043E74",
                  fontSize: "1.5vw",
                  cursor: "pointer",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#043E74",
                    fontSize: "1vw",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#043E74",
                  fontSize: "1vw",
                },
              }}
            />
          ) : (
            <TextField
              label="Change Phone Number"
              variant="outlined"
              value={states.currentUser.mobileNumber}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <EditIcon
                      sx={{
                        color: "#043E74",
                      }}
                      onClick={() => navigate("/phonechange")}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#043E74",
                  fontSize: "16px",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#043E74",
                    fontSize: "16px",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#043E74",
                  fontSize: "16px",
                },
              }}
            />
          )}
          {window.innerWidth > 768 ? (
            <TextField
              label="Change Password"
              variant="outlined"
              value={"********"}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <EditIcon
                      sx={{
                        color: "#043E74",
                      }}
                      onClick={() => navigate("/passchange")}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#043E74",
                  fontSize: "1.5vw",
                  cursor: "pointer",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#043E74",
                    fontSize: "1vw",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#043E74",
                  fontSize: "1vw",
                },
              }}
            />
          ) : (
            <TextField
              label="Change Password"
              variant="outlined"
              value={"********"}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <EditIcon
                      sx={{
                        color: "#043E74",
                      }}
                      onClick={() => navigate("/passchange")}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                // Root class for the input field
                "& .MuiOutlinedInput-root": {
                  color: "#043E74",
                  fontSize: "16px",
                  cursor: "pointer",
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#043E74",
                    fontSize: "16px",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#043E74",
                  fontSize: "16px",
                },
              }}
            />
          )}
        </div>
      </div>
      {window.innerWidth <= 768 && <MobileFooter />}
    </div>
  );
};

export default AppLayout()(Settings);
