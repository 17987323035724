import { Edit } from "@mui/icons-material";
import { Avatar, IconButton, Stack } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import avatarIcon from "../../Images/blank-profile-picture.png";
import { SavedStateContext } from "../../context/SavedState";
import AppLayout from "../../layout/AppLayout/AppLayout";
import classes from "./profile.module.css";
import UserSelect from "../../Components/UserSelect/Userselect";

const Profile = () => {
  const states = useContext(SavedStateContext);
  console.log(states);

  const [user, setUser] = useState();
  const [avatar, setAvatar] = useState(null);
  const [userInfo, setUserInfo] = useState(states.currentUser);
  const [currentAge, setCurrentAge] = useState();

  const userDetails = [
    { label: "Name-", name: "name" },
    { label: "Age-", name: "age" },
    { label: "Gender-", name: "gender" },
    { label: "Height (cm)-", name: "height" },
    { label: "Weight (kg)-", name: "weight" },
  ];
  const activityDetails = [
    { label: "Yoga", value: states.currentUser.Yoga },
    { label: "Sports", value: states.currentUser.Sports },
    { label: "Meditation", value: states.currentUser.Meditation },
    { label: "Exercise", value: states.currentUser.Exercise },
    { label: "Jog", value: states.currentUser.Jog },
  ];

  const avatarHandler = (e) => {
    const avatarUrl = URL.createObjectURL(e.target.files[0]);
    setAvatar(avatarUrl);
  };

  const handleUpdateImage = async () => {
    console.log(avatar);
    fetch(avatar)
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob.size);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64data = reader.result;
          // console.log("base64 image string", base64data); // This will be your data URL

          const form = new FormData();
          // console.log(avatar);
          form.append("email", states.currentUser.email);
          form.append("name", states.currentUser.name);
          form.append("data", base64data);
          states.setUserImage((prev) => ({
            ...prev,
            [states.currentUser.name]: { data: base64data },
          }));
          setAvatar(base64data);

          try {
            const response = await axios.put(
              "https://www.iterve.ai/uservitals/updateImage",
              form,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (error) {
            console.error("Error updating image:", error);
            alert(
              "Image size too big or image is not valid. Try uploading a another image."
            );
          }
        };
      })
      .catch((error) => {
        console.error("Error converting blob to base64:", error);
      });
  };

  const showImage = async () => {
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: {
          email: states.currentUser.email,
          name: states.currentUser.name,
        },
      })
      .then((res) => {
        setAvatar(res.data.image[0].data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeUser = (name, email) => {
    console.log("change user called", name, email);
    axios
      .get("https://www.iterve.ai/uservitals/findinguser", {
        params: { email: email, name: name },
      })
      .then((res) => {
        console.log("found user", res.data);
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUser([states.currentUser.name, ...states.users]);
    setAvatar(
      states.userImage[states.currentUser.name]
        ? states.userImage[states.currentUser.name].data
        : avatarIcon
    );
  }, [states.users]);

  const handleInfoChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("handleInfoChange called", name, value);
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function calculateAge(dateString) {
    // Split the input date string to get day, month, and year
    let [day, month, year] = dateString.split("/").map(Number);

    // Create a date object from the input date
    let birthDate = new Date(year, month - 1, day);
    let today = new Date();

    // Calculate age
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const handleSubmit = () => {
    console.log("submit called", userInfo);
    axios
      .put("https://www.iterve.ai/uservitals/activityUpdate", userInfo, {
        params: {
          email: states.currentUser.email,
          name: states.currentUser.name,
        },
      })
      .then((res) => {
        console.log("submit reponse", res);
        alert("User Info Updated");
        states.setCurrentUser(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.container}>
      {window.innerWidth <= 768 && (
        <div className={classes.heading}>Profile</div>
      )}
      <div className={classes.hellotext}>
        HELLO,
        <div className={classes.name}>
          {states.currentUser ? states.currentUser.name : ""}
        </div>
      </div>
      {window.innerWidth > 768 ? (
        <>
          <div className={classes.switch}> Switch User </div>
          <div className={classes.userCont}>
            {user ? (
              user.map((name, idx) => (
                <div
                  className={`${classes.avatarName} ${
                    name === `${states.currentUser.name}` ? classes.border : ""
                  }`}
                  key={idx}
                  onClick={(e) => changeUser(name, states.currentUser.email)}
                >
                  <img
                    src={
                      states.userImage[name]
                        ? states.userImage[name].data
                        : avatarIcon
                    }
                    alt="avatar icon"
                    className={classes.avatar}
                  />
                  <div className={classes.user}>{name}</div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <UserSelect />
      )}
      <div className={classes.userSec}>
        <div className={classes.headMiniCont}>
          <div className={classes.headingOfMiniCont}>Personal Details</div>
          <div className={classes.miniCont}>
            {userDetails.map((detail, idx) => (
              <div className={classes.detailsRow} key={idx}>
                <div className={classes.userData}>{detail.label}</div>
                <input
                  className={classes.userInput}
                  name={detail.name}
                  defaultValue={
                    detail.label === "Name-"
                      ? userInfo.name
                      : detail.label === "Age-"
                      ? calculateAge(states.currentUser.dob)
                      : detail.label === "Gender-"
                      ? userInfo.gender
                      : detail.label === "Height (cm)-"
                      ? userInfo.height
                      : detail.label === "Weight (kg)-"
                      ? userInfo.weight
                      : ""
                  }
                  readOnly={
                    detail.label === "Name-"
                      ? true
                      : detail.label === "Age-"
                      ? true
                      : detail.label === "Gender-"
                      ? true
                      : false
                  }
                  onChange={handleInfoChange}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.headMiniCont}>
          <div className={classes.headingOfMiniCont}>Activity Status</div>
          <div className={classes.miniCont}>
            {activityDetails.map((detail, idx) => (
              <div className={classes.detailsRow} key={idx}>
                <div className={classes.userData}>{detail.label}</div>
                {detail.label !== "Exercise" ? (
                  <select
                    className={classes.userInput}
                    onChange={handleInfoChange}
                    name={detail.label}
                    defaultValue={
                      userInfo[detail.label] ? userInfo[detail.label] : ""
                    }
                  >
                    <option value="" disabled>
                      Please select {detail.label}
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                ) : (
                  <select
                    className={classes.userInput}
                    onChange={handleInfoChange}
                    name={detail.label}
                    defaultValue={
                      userInfo[detail.label] ? userInfo[detail.label] : ""
                    }
                  >
                    <option value="" disabled>
                      Please select {detail.label}
                    </option>
                    <option value="Lightly Active">Lightly Active</option>
                    <option value="Very Active">Very Active</option>
                    <option value="Sedentary Active">Sedentary Active</option>
                    <option value="Moderately Active">Moderately Active</option>
                    <option value="Extra Active">Extra Active</option>
                  </select>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.headAvtarCont}>
          <div className={classes.headingOfMiniCont}>Edit Profile Photo</div>
          <Stack
            position={"relative"}
            sx={
              window.innerWidth > 768
                ? { width: "12vw", marginTop: "2vw" }
                : { width: "242px", alignItems: "center" }
            }
          >
            <Avatar
              src={avatar}
              sx={
                window.innerWidth > 768
                  ? {
                      width: "12vw",
                      height: "12vw",
                      objectFit: "contain",
                    }
                  : {
                      width: "242px",
                      height: "242px",
                    }
              }
            />
            <IconButton
              sx={
                window.innerWidth > 768
                  ? {
                      position: "absolute",
                      right: "1vw",
                      bottom: 0,
                      color: "white",
                      backgroundColor: "#FFFFFF",
                      ":hover": {
                        backgroundColor: "lightgrey",
                      },
                      border: "1px solid #043E74",
                    }
                  : {
                      position: "absolute",
                      right: "40px",
                      bottom: 0,
                      color: "white",
                      backgroundColor: "#FFFFFF",
                      ":hover": {
                        backgroundColor: "lightgrey",
                      },
                      border: "1px solid #043E74",
                    }
              }
              component="label"
            >
              <>
                <input
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    margin: -1,
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  type="file"
                  accept="image/*"
                  onChange={avatarHandler}
                />
                <Edit sx={{ color: "#174E80" }} />
              </>
            </IconButton>
          </Stack>

          <button className={classes.btn} onClick={handleUpdateImage}>
            UPLOAD PHOTO
          </button>
          <button className={classes.btn} onClick={handleSubmit}>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppLayout()(Profile);
