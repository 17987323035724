import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import itervelogo from "../../Images/itervelogo.png";
import Header from "../../Components/Header/Header";
import faceLogo from "../../Images/faceLogo.png";
import classes from "./homescreen.module.css";
import { SavedStateContext } from "../../context/SavedState";
import Instagram from "../../Images/Instagram.png";
import Twitter from "../../Images/TwitterX.png";
import LinkedIn from "../../Images/LinkedIn.png";
import menu from "../../Images/Menu.png";
import PPGRGB from "../../Images/PPGRGB.png";
import MobileHeader from "../../Components/Header/MobileHeader";

const HomeScreen = () => {
  const navigate = useNavigate();
  const states = useContext(SavedStateContext);

  const handleClick = () => {
    states.currentUser ? navigate("/dashboard") : navigate("/login");
  };

  return (
    <>
      {window.innerWidth > 768 ? <Header /> : <MobileHeader heading="Home" />}
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.left}>
            <div className={classes.heading}>
              Iterve Health Check: Measure Your Vitals
            </div>
            <div className={classes.description}>
              iTerve health check is an AI solution developed by AIVOT AI that
              measures your body vitals, including Heart Rate, Oxygen
              Saturation, Blood Pressure, and Blood Sugar through a facial video
              of less than 1 minute. It is non-invasive, accurate, and instant.
            </div>
            <div className={classes.mobileImage}>
              <img src={faceLogo} alt="faceLogo" className={classes.faceLogo} />
            </div>
            <button className={classes.btn} onClick={handleClick}>
              Get Started
            </button>
          </div>
          <div className={classes.right}>
            <img src={faceLogo} alt="faceLogo" className={classes.faceLogo} />
          </div>
        </div>
      </div>

      {window.innerWidth > 768 ? (
        <Footer />
      ) : (
        <div className={classes.mobileFooter}>
          <div className={classes.joinustext}>Join Us</div>
          <div className={classes.email}>
            <input className={classes.emailbox} placeholder="Send mail" />
          </div>
          <div className={classes.joinbuttons}>
            <div className={classes.share}>
              <img src={Instagram} className={classes.join} />
            </div>
            <div className={classes.share}>
              <img src={Twitter} className={classes.join} />
            </div>
            <div className={classes.share}>
              <img src={LinkedIn} className={classes.join} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeScreen;
