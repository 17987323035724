import React, { useContext, useState } from "react";
import classes from "./AboutUs.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ss_aboutus from "../../Images/ss_aboutus.png";
import itervelogo from "../../Images/itervelogo.png";
import faceLogo from "../../Images/faceLogo.png";
import { SavedStateContext } from "../../context/SavedState";
import Instagram from "../../Images/Instagram.png";
import Twitter from "../../Images/TwitterX.png";
import LinkedIn from "../../Images/LinkedIn.png";
import menu from "../../Images/Menu.png";
import PPGRGB from "../../Images/PPGRGB.png";
import MobileHeader from "../../Components/Header/MobileHeader";

const AboutUs = () => {
  return (
    <>
      {window.innerWidth > 768 ? (
        <div>
          <Header />
          <div className={classes.heading}>About Us</div>
          <div className={classes.body}>
            <div className={classes.paragraph}>
              At iTerve, we are revolutionizing healthcare with our innovative
              AI-driven solutions. Our dedicated AIVOT AI team has meticulously
              developed an advanced platform by integrating three major AI
              technologies, creating a comprehensive healthcare solution like no
              other. By referencing over 1,000 healthcare research papers, we've
              built the first mature AI model that accurately trains extracted
              PPG waves and RGB data alongside user profile information—such as
              age, sex, weight, and height—to deliver precise readings of key
              vitals. Notably, this includes the world’s first noninvasive and
              touchless random blood sugar measurement.
            </div>
            <img src={ss_aboutus} />
          </div>
          <Footer />
        </div>
      ) : (
        <>
          <div className={classes.container}>
            <MobileHeader />
            <div className={classes.content}>
              <div className={classes.left}>
                <div className={classes.aboutUs}>
                  <div className={classes.aboutHeading}>About Us</div>
                  <div className={classes.aboutDescription}>
                    At iTerve, we are revolutionizing healthcare with our
                    innovative AI-driven solutions. Our dedicated AIVOT AI team
                    has meticulously developed an advanced platform by
                    integrating three major AI technologies, creating a
                    comprehensive healthcare solution like no other. By
                    referencing over 1,000 healthcare research papers, we've
                    built the first mature AI model that accurately trains
                    extracted PPG waves and RGB data alongside user profile
                    information—such as age, sex, weight, and height—to deliver
                    precise readings of key vitals. Notably, this includes the
                    world’s first noninvasive and touchless random blood sugar
                    measurement.
                  </div>
                </div>
              </div>
              <div className={classes.right}>
                <img
                  src={faceLogo}
                  alt="faceLogo"
                  className={classes.faceLogo}
                />
              </div>
            </div>
          </div>

          {window.innerWidth > 768 ? (
            <Footer />
          ) : (
            <div className={classes.mobileFooter}>
              <img src={PPGRGB} />
              <div className={classes.joinustext}>Join Us</div>
              <div className={classes.email}>
                <input className={classes.emailbox} placeholder="Send mail" />
              </div>
              <div className={classes.joinbuttons}>
                <div className={classes.share}>
                  <img src={Instagram} className={classes.join} />
                </div>
                <div className={classes.share}>
                  <img src={Twitter} className={classes.join} />
                </div>
                <div className={classes.share}>
                  <img src={LinkedIn} className={classes.join} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AboutUs;
