import React from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import bodyParamImage from "../../Images/humanBody.png";

const BodyWater = () => {
  const topic = "Body Water";
  const params = [
    "BMI",
    "Body Fat",
    "Subcutaneous Fat",
    "Visceral Fat",
    "Lean Body Mass",
  ];

  const name = "Body Water";

  return (
    <BodyParamsLayout
      topic={topic}
      params={params}
      name={name}
      img={bodyParamImage}
    />
  );
};

export default AppLayout()(BodyWater);
