import React, { useEffect, useState } from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import AppLayout from "../../layout/AppLayout/AppLayout";
import bodyParamImage from "../../Images/cholestrol.png";

const Cholestrol = () => {
  const topic = "Cholestrol";
  const params = [
    "LDL (Low-Density Lipoprotein)",
    "HDL (High-Density Lipoprotein)",
    "VLDL (Very Low-Density Lipoprotein)",
    "Triglycerides",
  ];

  const name = "Cholestrol";

  return (
    <BodyParamsLayout
      topic={topic}
      params={params}
      name={name}
      img={bodyParamImage}
    />
  );
};

export default AppLayout()(Cholestrol);
