import React from "react";
import BodyParamsLayout from "../../layout/BodyParamsLayout/BodyParamsLayout";
import brain from "../../Images/brain.png";
import AppLayout from "../../layout/AppLayout/AppLayout";

const Stress = () => {
  const topic = "Stress Record";
  const params = [
    "Heart Rate",
    "Oxygen Saturation",
    "Blood Pressure",
    "Random Blood Sugar",
  ];

  const name = "Stress Index";

  return (
    <BodyParamsLayout topic={topic} params={params} name={name} img={brain} />
  );
};

export default AppLayout()(Stress);
