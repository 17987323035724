import moment, { months } from "moment";
import React, { useContext, useEffect, useState } from "react";
import shadowEllipse from "../../Images/shadowEllipse.png";
import classes from "./bodyParamsLayout.module.css";
import CandleChart from "../../Components/CandleChart/CandleChart";
import axios from "axios";
import { SavedStateContext } from "../../context/SavedState";
import { useNavigate } from "react-router-dom";

const BodyParamsLayout = ({ topic, params, name, img }) => {
  const states = useContext(SavedStateContext);
  const username = states.currentUser.name;
  const email = states.currentUser.email;
  const navigate = useNavigate();

  const vitalsMapping = {
    "Allowable Blood Loss [milliliters]": "allowable_blood_loss",
    BMI: "bmi",
    "Body Fat": "body_fat",
    "Body Water": "body_water",
    "Breathing Rate [breaths per minute]": "breathing_rate",
    "Cardiac Index": "cardiac_index",
    "Cardiac Output": "cardiac_output",
    "DBP [mmHg]": "dbp",
    "HDL (High-Density Lipoprotein)": "hdl",
    HbA1c: "hba1c",
    "Heart Rate": "heart_rate",
    Hematocrit: "hematocrit",
    Hemoglobin: "hemoglobin",
    IBI: "ibi",
    "LDL (Low-Density Lipoprotein)": "ldl",
    "Mean Arterial Pressure": "mean_arterial_pressure",
    "Mean Corpuscular Hemoglobin": "mean_corpuscular_hemoglobin",
    "Mean Corpuscular Volume [fl]": "mean_corpuscular_volume",
    "Mean Plasma Glucose": "mean_plasma_glucose",
    "Oxygen Saturation": "oxygen_saturation",
    "Pulse Pressure": "pulse_pressure",
    "Pulse Rate Quotient [beats per breath]": "pulse_rate_quotient",
    "RBC Count [million cells/mcL]": "rbc_count",
    RMSSD: "rmssd",
    "Random Blood Sugar [mg/dL]": "random_blood_sugar",
    "SBP [mmHg]": "sbp",
    "SD1 [miliseconds]": "sd1",
    "SD2 [miliseconds]": "sd2",
    SDNN: "sdnn",
    "Stress Index": "stress_index",
    "Stroke Volume": "stroke_volume",
    "Subcutaneous Fat": "subcutaneous_fat",
    "Total Cholesterol [mg/dL]": "total_cholestrol",
    Triglycerides: "triglycerides",
    "VLDL (Very Low-Density Lipoprotein)": "vldl",
    "Visceral Fat": "visceral_fat",
    "(T3) Triiodothyronine [ng/dL]": "t3",
    "(T4) Thyroxine [μg/dL]": "t4",
    "(TSH) Thyroid Stimulating Hormone [μIU/mL]": "tsh",
    "Blood Volume [mL]": "blood_volume",
  };

  const [paramName, setParamName] = useState("");
  const [bloodpressurepage, setbloodpressurepage] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [vitalsData, setVitalsData] = useState();

  const [graphData, setGraphData] = useState([
    [
      "day",
      "a",
      "b",
      "c",
      "d",
      { role: "style" },
      { role: "tooltip", type: "string", p: { html: true } },
    ],
  ]);

  const daysInMonth = currentMonth.daysInMonth();
  const startOfMonth = currentMonth.startOf("month").day();

  const handlePrevDays = () => {
    const decrement = window.innerWidth <= 768 ? 10 : 15;
    const newIndex = currentDayIndex - decrement;
    setCurrentDayIndex(newIndex < 0 ? daysInMonth + newIndex : newIndex);
  };

  const handleNextDays = () => {
    const increment = window.innerWidth <= 768 ? 10 : 15;
    setCurrentDayIndex((currentDayIndex + increment) % daysInMonth);
  };

  const handlePrevMonth = () => {
    const newMonth = moment(currentMonth).subtract(1, "month");
    setCurrentMonth(newMonth);
    setCurrentDayIndex(0);
  };

  const handleNextMonth = () => {
    const newMonth = moment(currentMonth).add(1, "month");
    setCurrentMonth(newMonth);
    setCurrentDayIndex(0);
  };

  const handleDaySelect = (e) => {
    console.log(e.target.innerText);
    const day = e.target.innerText;
    setGraphData((prev) => {
      const header = prev[0];
      const updatedData = prev.slice(1).map((entry) => {
        return entry[0] === day
          ? [
              entry[0],
              entry[1],
              entry[2],
              entry[3],
              entry[4],
              "color: darkblue",
              entry[6],
            ]
          : [
              entry[0],
              entry[1],
              entry[2],
              entry[3],
              entry[4],
              "color: #0564BC",
              entry[6],
            ];
      });

      // Combine the header with the updated data
      return [header, ...updatedData];
    });
  };
  const generateDays = () => {
    const days = [];
    const currentMonthStart = currentMonth.startOf("month");
    const dayGroup = window.innerWidth <= 768 ? 10 : 15;
    for (let i = 0; i < dayGroup; i++) {
      const day = moment(currentMonthStart).add(currentDayIndex + i, "days");
      days.push(
        <div
          key={day.format("DD-MM-YYYY")}
          className={classes.dayCont}
          onClick={() => setSelectedDate(day)}
        >
          <span
            className={`${classes.date} ${
              selectedDate.isSame(day, "day") ? classes.selectedDate : ""
            }`}
            onClick={handleDaySelect}
          >
            {day.date()}
          </span>
          <span className={classes.day}>{day.format("ddd")}</span>
        </div>
      );
    }
    return days;
  };

  useEffect(() => {
    console.log("email name", email, username);
    axios
      .get("https://www.iterve.ai/uservitals/latestreport", {
        params: { email: email, name: username },
      })
      .then((res) => {
        setVitalsData({
          ...res.data[0],
          "Heart Rate": `${res.data[0].heart_rate} bpm`,
          IBI: `${res.data[0].ibi} ms`,
          SDNN: `${res.data[0].sdnn} ms`,
          RMSSD: `${res.data[0].rmssd} ms`,
          "Cardiac Output": `${res.data[0].cardiac_output} L/min`,
          "Stroke Volume": `${res.data[0].stroke_volume} mL`,
          "Body Water": `${res.data[0].body_water}%`,
          "SBP/DBP": `${res.data[0].sbp}/${res.data[0].dbp}mmHg`,
          "Pulse Pressure": `${res.data[0].pulse_pressure} mmHg`,
          "Mean Arterial Pressure": `${res.data[0].mean_arterial_pressure} mmHg`,
          "Cardiac Index": `${res.data[0].cardiac_index} L/min/m^2`,
          BMI: `${res.data[0].bmi} kg/m^2`,
          "Body Fat": `${res.data[0].body_fat}%`,
          "Subcutaneous Fat": `${res.data[0].subcutaneous_fat}%`,
          "Visceral Fat": `${res.data[0].visceral_fat}%`,
          "Lean Body Mass": `${Math.round(res.data[0].LBM * 100) / 100} kg`,
          "Oxygen Saturation": `${res.data[0].oxygen_saturation}%`,
          Hemoglobin: `${res.data[0].hemoglobin} g/dL`,
          "Mean Corpuscular Hemoglobin": `${res.data[0].mean_corpuscular_hemoglobin} pg`,
          Hematocrit: `${res.data[0].hematocrit}%`,
          HbA1c: `${res.data[0].hba1c}%`,
          Cholestrol: `${res.data[0].total_cholestrol} mg/dL`,
          "LDL (Low-Density Lipoprotein)": `${res.data[0].ldl} mg/dL`,
          "HDL (High-Density Lipoprotein)": `${res.data[0].hdl} mg/dL`,
          "VLDL (Very Low-Density Lipoprotein)": `${res.data[0].vldl} mg/dL`,
          Triglycerides: `${res.data[0].triglycerides} mg/dL`,
          "Random Blood Sugar": `${res.data[0].random_blood_sugar} mg/dL`,
          "Mean Plasma Glucose": `${res.data[0].mean_plasma_glucose} mg/dL`,
          "Blood Pressure": `${res.data[0].sbp}/${res.data[0].dbp} mmHg`,
          "Stress Index": `${res.data[0].stress_index}%`,
          "SD1/SD2": `${Math.round(res.data[0].sd1_sd2 * 100) / 100}`,
        });

        console.log("latest report", res.data[0]);
      })
      .catch((err) => {
        setVitalsData();
        console.log(err);
      });
  }, [states.currentUser]);

  // useEffect(() => {
  //   vitalsData &&
  //     setVitalsData((prev) => ({
  //       ...prev,
  //       "SBP/DBP": `${vitalsData.sbp}/${vitalsData.dbp}`,
  //       "Oxygen Saturation": `${vitalsData.oxygen_saturation}%`,
  //       HemoGlobin: `${vitalsData.hemoglobin} g/dL`,
  //       Cholestrol: `${vitalsData.total_cholestrol} mg/dL`,
  //     }));
  // }, [vitalsData]);

  useEffect(() => {
    setMonth(currentMonth.format("MMMM"));
    setYear(currentMonth.format("YYYY"));
    console.log(month, year);
    if (topic === "Heart") {
      setParamName("heart_rate");
      setbloodpressurepage(false);
    } else if (topic === "Blood Pressure") {
      setbloodpressurepage(true);
    } else if (topic === "Body Water") {
      setParamName("body_water");
      setbloodpressurepage(false);
    } else if (topic === "Oxygen Saturation Rate") {
      setParamName("oxygen_saturation");
      setbloodpressurepage(false);
    } else if (topic === "Hemoglobin") {
      setParamName("hemoglobin");
      setbloodpressurepage(false);
    } else if (topic === "Random Blood Sugar") {
      setParamName("random_blood_sugar");
      setbloodpressurepage(false);
    } else if (topic === "Cholestrol") {
      setParamName("total_cholestrol");
      setbloodpressurepage(false);
    } else if (topic === "Stress Record") {
      setParamName("stress_index");
      setbloodpressurepage(false);
    }
  }, []);

  //for fetching and setting the vitals
  useEffect(() => {
    console.log(month, year);
    const referenceRange = {
      "Heart Rate": "60-100",
      "SBP/DBP": "100-130/65-90",
      "Body Water": "43-73",
      Hemoglobin: "13-17",
      "Random Blood Sugar": "70-140",
      Cholestrol: "140-200",
      "Oxygen Saturation": "95-100",
      "Stress Record": "55-100",
    };
    console.log("reference range", referenceRange[name]);
    if (!bloodpressurepage) {
      month &&
        axios
          .get("https://www.iterve.ai/uservitals/monthlyAverages", {
            params: {
              email: `${states.currentUser.email}`,
              year: `${year}`,
              month: `${month}`,
              attribute: `${paramName}`,
              username: `${states.currentUser.name}`,
            },
          })
          .then((res) => {
            console.log(res);
            // Creating a map from day to value for easy lookup
            const dataMap = new Map(
              res.data.map((item) => [item.day, item.average])
            );

            // Creating the array
            const result = [];
            for (let day = 1; day <= currentMonth.daysInMonth(); day++) {
              const value = dataMap.get(day) || 80;
              result.push([
                day.toString(),
                80,
                80,
                value,
                value,
                "color: #0564BC",
                dataMap.get(day)
                  ? `
                <div style="padding: 5px; display: flex; flex-direction: column;">
                   
                  <div style="display: flex; justify-content:center"> <strong>Date:</strong> ${day.toString()} ${month}</div>
                  <div style="display: flex; justify-content:center">Normal Range: ${
                    referenceRange[name]
                  }</div>
                  <div style="display: flex; justify-content:center">Actual Val: ${value}</div>
                </div>
              `
                  : `<div style="padding: 5px; display: flex; flex-direction: column;">
                   
                  <div style="display: flex; justify-content:center"> <strong>Date:</strong> ${day.toString()} ${month}</div>
                  <div style="display: flex; justify-content:center">null</div>
                  <div style="display: flex; justify-content:center">null</div>
                </div>`,
              ]);
            }

            setGraphData((prev) => [prev[0], ...result]);
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      month &&
        axios
          .get("https://www.iterve.ai/uservitals/monthlyAverages", {
            params: {
              email: `${states.currentUser.email}`,
              year: `${year}`,
              month: `${month}`,
              attribute: "sbp",
              username: `${states.currentUser.name}`,
            },
          })
          .then((resSbp) => {
            axios
              .get("https://www.iterve.ai/uservitals/monthlyAverages", {
                params: {
                  email: `${states.currentUser.email}`,
                  year: `${year}`,
                  month: `${month}`,
                  attribute: "dbp",
                  username: `${states.currentUser.name}`,
                },
              })
              .then((resDbp) => {
                // Creating a map from day to value for easy lookup
                const dataMap = new Map(
                  resSbp.data.map((item, index) => [
                    item.day,
                    [item.average, resDbp.data[index].average],
                  ])
                );

                // Creating the array
                const result = [];
                for (let day = 1; day <= currentMonth.daysInMonth(); day++) {
                  const values = dataMap.get(day) || [80, 80];
                  result.push([
                    day.toString(),
                    values[0],
                    values[0],
                    values[1],
                    values[1],
                    "color: #0564BC",
                    dataMap.get(day)
                      ? `
                    <div style="padding: 5px; display: flex; flex-direction: column;">
                       
                      <div style="display: flex; justify-content:center"> <strong>Date:</strong> ${day.toString()} ${month}</div>
                      <div style="display: flex; justify-content:center">SBP/DBP: ${
                        values[0]
                      }/${values[1]}</div>
                     
                      <div style="display: flex; justify-content:center">Normal Range: ${
                        referenceRange[name]
                      }</div>
                    </div>
                  `
                      : `
                  <div style="padding: 5px; display: flex; flex-direction: column;">
                     
                    <div style="display: flex; justify-content:center"> <strong>Date:</strong> ${day.toString()} ${month}</div>
                    <div style="display: flex; justify-content:center">null
                    </div>
                    <div style="display: flex; justify-content:center">null
                    </div>
                  </div>
                `,
                  ]);
                }

                setGraphData((prev) => [prev[0], ...result]);
              })
              .catch((err) => {
                console.log(err);
              })
              .catch((err) => {
                console.log(err);
              });
          });
    }
  }, [month, states.currentUser]);

  useEffect(() => {
    setMonth(currentMonth.format("MMMM"));
    setYear(currentMonth.format("YYYY"));
  }, [currentMonth]);

  useEffect(() => {
    console.log(graphData);
  }, [graphData]);

  useEffect(() => {
    console.log(vitalsData);
  }, [vitalsData]);

  //html for vital box
  const paramList = (
    <div className={classes.paramsList}>
      {params.map((param, index) => (
        <div className={classes.paramsRow} key={index}>
          <div className={classes.param}>{param}</div>
          <div className={classes.valuesOfParams}>
            {vitalsData && vitalsData[param]}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.topic}>{topic}</div>
      <div className={classes.leftRightContWrap}>
        <div className={classes.leftContainer}>
          <img src={img} alt="paramsImage" className={classes.paramsImage} />
          <img
            src={shadowEllipse}
            alt="shadowEllipse"
            className={classes.shadowEllipse}
          />

          {window.innerWidth > 768 && paramList}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.measurementBox}>
            <div className={classes.name}>{name}</div>
            <div className={classes.value}>
              {vitalsData && vitalsData[name]}
            </div>
          </div>
          <div className={classes.dateMonthContainer}>
            <div className={classes.monthBox}>
              <div className={classes.month}>MONTH</div>
              <div className={classes.arrowContainer}>
                <div className={classes.arrow} onClick={handlePrevMonth}>
                  {"<"}
                </div>
                <div className={classes.arrow} onClick={handleNextMonth}>
                  {">"}
                </div>
              </div>
              <div className={classes.monthName}>
                {currentMonth.format("MMMM YYYY")}
              </div>
            </div>
            <div className={classes.dateBox}>
              <div className={classes.month}> DATE</div>
              <div className={classes.dateAndDay}>
                <div className={classes.arrow} onClick={handlePrevDays}>
                  {"<"}
                </div>
                <div className={classes.dateList}>{generateDays()}</div>
                <div className={classes.arrow} onClick={handleNextDays}>
                  {">"}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.headingGraph}>
            {currentMonth.format("MMMM")} Month {topic} Chart
          </div>
          <div>{graphData.length > 1 && <CandleChart data={graphData} />}</div>
        </div>
        {window.innerWidth <= 768 && paramList}
        {window.innerWidth <= 768 && (
          <div>
            <div
              className={classes.testButton}
              onClick={() => navigate("/imr")}
            >
              IMR DASHBOARD
            </div>
            <div
              className={classes.testButton}
              onClick={() => navigate("/reports")}
            >
              REPORT
            </div>
            <div
              className={classes.testButton}
              onClick={() => navigate("/dashboard")}
            >
              DASHBOARD
            </div>
            <div
              className={classes.testButton}
              onClick={() => navigate("/selftest")}
            >
              SELF TEST
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BodyParamsLayout;
