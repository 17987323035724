import React, { useEffect } from "react";
import Menu from "../../Components/Menu/Menu";
import TitleBar from "../../Components/TitleBar/TitleBar";
import MobileHeader from "../../Components/Header/MobileHeader";

const AppLayout = () => (WrappedComponent) => {
  return () => {
    return (
      <>
        {window.innerWidth > 768 ? (
          <div style={{ display: "flex", width: "100%" }}>
            <Menu />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <TitleBar />
              <WrappedComponent />
            </div>
          </div>
        ) : (
          <>
            <MobileHeader />
            <WrappedComponent />
          </>
        )}
      </>
    );
  };
};

export default AppLayout;
