import React, { useContext, useState } from "react";
import classes from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import itervelogo from "../../Images/itervelogo.png";
import { SavedStateContext } from "../../context/SavedState";
import axios from "axios";

const Header = () => {
  const navigate = useNavigate();
  const states = useContext(SavedStateContext);
  console.log("inside header");
  const signout = async () => {
    await axios
      .put("https://www.iterve.ai/uservitals", {
        params: {
          email: states.currentUser.email,
          deviceId: "",
        },
      })
      .then((res) => {
        console.log(res);
        states.setCurrentUser(null);
        localStorage.removeItem("currentUser");
        navigate("/home");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={classes.container}>
      <img className={classes.image} src={itervelogo} />
      <div className={classes.navcontainer}>
        <div className={classes.navitems} onClick={() => navigate("/home")}>
          Home
        </div>
        <div className={classes.navitems} onClick={() => navigate("/aboutus")}>
          About Us
        </div>
        {states.currentUser ? (
          <div onClick={signout} className={classes.navitems}>
            Sign Out
          </div>
        ) : (
          <div className={classes.navitems} onClick={() => navigate("/login")}>
            Sign In
          </div>
        )}
        {states.currentUser ? (
          <div
            className={classes.navitems}
            onClick={() => navigate("/dashboard")}
          >
            Dashboard
          </div>
        ) : (
          <div
            className={classes.navitems}
            onClick={() => navigate("/register")}
          >
            Register
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
