import React, { useState, useRef, useEffect } from "react";
import classes from "./reportVitals.module.css";
import aivotLogo from "../../Images/aivotLogo 2.png";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink, View, pdf } from "@react-pdf/renderer";
import MyPDF from "./MyPDF";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import wpimage from "../../Images/whatsapp.png";
import mailimage from "../../Images/mail.png";
import appleimage from "../../Images/apple.png";
import androidimage from "../../Images/android.png";
import webimage from "../../Images/www.png";
import copyimage from "../../Images/copy.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tickimage from "../../Images/tick.png";
import cross from "../../Images/remove.png";
import gamil from "../../Images/gmail.png";
import yahoo from "../../Images/yahoo.png";
import outlook from "../../Images/outlook.png";

const ReportVitals = () => {
  const mypdfRef = useRef(null);
  const [isDownloaded, setDownloaded] = useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [healthParameters, sethealthParameters] = useState([]);
  const [formData, setFormData] = useState({});
  const [vitals, setvitals] = useState();
  const [childid, setchildid] = useState("");
  const [parentid, setparentid] = useState("");
  const [sharepopup, setsharepopup] = useState(false);
  const [whatsappopt, setwhatsappopt] = useState(false);
  const [mailopt, setmailopt] = useState(false);
  const [copycompleted, setcopycompleted] = useState(false);
  const [accountNumber, setaccountNumber] = useState("");
  const [enduserid, setenduserid] = useState("");
  console.log("vitals page rendered");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.state);
    console.log(parentid, childid);
    if (location.state) {
      setvitals(location.state);
      console.log(location.state);

      setparentid(location.state.id);
      setchildid(location.state._id);
    } else {
      console.log("else");
      const objectId = location.pathname.split("/")[2].slice(4);
      const vitalsDataId = location.pathname.split("/")[3].slice(4);
      setparentid(objectId);
      setchildid(vitalsDataId);
      console.log(parentid, childid);
      if (!objectId || !vitalsDataId) navigate("/reports");
      const dbcollection = "uservitals";
      fetch(
        `https://www.iterve.ai/${dbcollection}/fetchSingleVital?objectId=${objectId}&vitalDataId=${vitalsDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setvitals(objectId === "enduser" ? res : res.vitalsData);
          setaccountNumber(res.accountNumber ? res.accountNumber : "");
        });
    }
  }, [location.state]);

  useEffect(() => {
    console.log(vitals);

    if (vitals) {
      sethealthParameters([
        {
          vital: "Heart Rate",
          value: vitals["heart_rate"],
          unit: "Beats per min",
          referenceRange: "60 - 100",
        },
        {
          vital: "Oxygen Saturation %",
          value: vitals["oxygen_saturation"],
          unit: "%",
          referenceRange: "95 – 100",
        },
        // {
        //   vital: "Breathing Rate",
        //   value: vitals["breathing_rate"],
        //   unit: "BPM",
        //   referenceRange: "12 – 20",
        // },
        // {
        //   vital: "Pulse Rate Quotient",
        //   value: vitals["pulse_rate_quotient"],
        //   unit: "Beats per min",
        //   referenceRange: "4",
        // },
        {
          vital: "BMI",
          value: vitals["bmi"],
          unit: "kg/m^2",
          referenceRange: "18.5 – 24.9",
        },
        {
          vital: "Body Water %",
          value: vitals["body_water"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "43 - 73" : "41 - 60",
        },
        {
          vital: "Body Fat",
          value: vitals["body_fat"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "8 - 21" : "14 - 28",
        },
        {
          vital: "Visceral Fat",
          value: vitals["visceral_fat"],
          unit: "%",
          referenceRange: "1 - 12",
        },
        {
          vital: "Subcutaneous Fat %",
          value: vitals["subcutaneous_fat"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "8 - 25" : "20 - 35",
        },
        {
          vital: "Cardiac Output",
          value: vitals["cardiac_output"],
          unit: "L/min",
          referenceRange: "4 – 8",
        },
        {
          vital: "Cardiac Index",
          value: vitals["cardiac_index"],
          unit: "L/min/m^2",
          referenceRange: "2.6 – 4.2",
        },
        {
          vital: "Stroke Volume",
          value: vitals["stroke_volume"],
          unit: "milli litre",
          referenceRange: "48.2 - 98.5",
        },
        {
          vital: "RMSSD",
          value: vitals["rmssd"],
          unit: "milli sec",
          referenceRange: "19 – 107",
        },
        {
          vital: "SDNN",
          value: vitals["sdnn"],
          unit: "milli sec",
          referenceRange: ">55",
        },
        {
          vital: "SD1",
          value: vitals["sd1"],
          unit: "milli sec",
          referenceRange: vitals["gender"] === "Male" ? "40.5 - 71" : "29 - 65",
        },
        {
          vital: "SD2",
          value: vitals["sd2"],
          unit: "milli sec",
          referenceRange: "35 - 107",
        },
        {
          vital: "IBI",
          value: vitals["ibi"],
          unit: "milli sec",
          referenceRange: "600 – 900",
        },
        {
          vital: "Stress Index*",
          value: vitals["stress_index"],
          unit: "%",
          referenceRange: "20 – 80",
        },
        {
          vital: "SBP",
          value: vitals["sbp"],
          unit: "mmHg",
          referenceRange: "100 – 130",
        },
        {
          vital: "DBP",
          value: vitals["dbp"],
          unit: "mmHg",
          referenceRange: "65 - 90",
        },

        {
          vital: "Mean Arterial Pressure",
          value: vitals["mean_arterial_pressure"],
          unit: "mmHg",
          referenceRange: "70 - 100",
        },
        {
          vital: "Pulse Pressure",
          value: vitals["pulse_pressure"],
          unit: "mmHg",
          referenceRange: "44 – 60",
        },
        {
          vital: "LDL*",
          value: vitals["ldl"],
          unit: "mg/dl",
          referenceRange: "50 - 130",
        },
        {
          vital: "HDL*",
          value: vitals["hdl"],
          unit: "mg/dl",
          referenceRange: "40 - 75",
        },
        {
          vital: "Triglycerides*",
          value: vitals["triglycerides"],
          unit: "mg/dl",
          referenceRange: "60 - 150",
        },
        {
          vital: "Total Cholesterol*",
          value: vitals["total_cholestrol"],
          unit: "mg/dl",
          referenceRange: "140 - 200",
        },
        {
          vital: "VLDL*",
          value: vitals["vldl"],
          unit: "mg/dl",
          referenceRange: "5 - 40",
        },
        {
          vital: "RBC Count*",
          value: vitals["rbc_count"],
          unit: "million cell/mcl",
          referenceRange:
            vitals["gender"] === "Male" ? "4.7 - 6.1" : "4.2 - 5.4",
        },
        {
          vital: "Mean Corpuscular Value*",
          value: vitals["mean_corpuscular_volume"],
          unit: "fl",
          referenceRange: "80 – 100",
        },
        {
          vital: "Mean Corpuscular Haemoglobin*",
          value: vitals["mean_corpuscular_hemoglobin"],
          unit: "pg/cells",
          referenceRange: "27 - 31",
        },
        // {
        //   vital: "T3 (Triiodothyronine)",
        //   value: "",
        //   unit: "ng/dl",
        //   referenceRange: "80-200",
        // },
        // {
        //   vital: "T4 (Thyroxine)",
        //   value: "",
        //   unit: "μg/dL",
        //   referenceRange: "4.8 – 12.7",
        // },
        // {
        //   vital: "TSH (Thyroid Stimulating Hormone)",
        //   value: "",
        //   unit: "μIU/mL",
        //   referenceRange: "0.54 – 5.30",
        // },
        // {
        //   vital: "Blood Volume",
        //   value: "",
        //   unit: "mL",
        //   referenceRange: "5000",
        // },
        {
          vital: "Hemoglobin",
          value: vitals["hemoglobin"],
          unit: "g/dl",
          referenceRange:
            vitals["gender"] === "Male" ? "13 - 17" : "11.6 - 15.5",
        },
        {
          vital: "Hematocrit",
          value: vitals["hematocrit"],
          unit: "%",
          referenceRange: vitals["gender"] === "Male" ? "40 - 54" : "36 - 48",
        },
        {
          vital: "Random Blood Sugar",
          value: vitals["random_blood_sugar"],
          unit: "mg/dl",
          referenceRange: "70 – 140",
        },
        {
          vital: "HbA1c",
          value: vitals["hba1c"],
          unit: "%",
          referenceRange: "4 - 5.6",
        },
        {
          vital: "Mean Plasma Glucose",
          value: vitals["mean_plasma_glucose"],
          unit: "mg/dl",
          referenceRange: "72 - 100",
        },
        {
          vital: "Allowable Blood Loss",
          value: vitals["allowable_blood_loss"],
          unit: "ml/kg",
          referenceRange: vitals["gender"] === "Male" ? "75" : "65",
        },
      ]);

      // sethealthParameters([
      //   {
      //     vital: "Heart Rate",
      //     value: vitals["heart_rate"],
      //     unit: "Beats per min",
      //     referenceRange: "60 - 100",
      //   },
      //   {
      //     vital: "Oxygen Saturation %",
      //     value: vitals["oxygen_saturation"],
      //     unit: "%",
      //     referenceRange: "95 – 100",
      //   },
      //   // {
      //   //   vital: "Breathing Rate",
      //   //   value: vitals["breathing_rate"],
      //   //   unit: "BPM",
      //   //   referenceRange: "12 – 20",
      //   // },
      //   // {
      //   //   vital: "Pulse Rate Quotient",
      //   //   value: vitals["pulse_rate_quotient"],
      //   //   unit: "Beats per min",
      //   //   referenceRange: "4",
      //   // },
      //   {
      //     vital: "BMI",
      //     value: Math.round(vitals["bmi"] * 1000) / 1000,
      //     unit: "kg/m^2",
      //     referenceRange: "18.5 – 24.9",
      //   },
      //   {
      //     vital: "Cardiac Output",
      //     value: Math.round(vitals["cardiac_output"] * 1000) / 1000,
      //     unit: "L/min",
      //     referenceRange: "4 – 8",
      //   },
      //   {
      //     vital: "Cardiac Index",
      //     value: Math.round(vitals["cardiac_index"] * 1000) / 1000,
      //     unit: "L/min/m^2",
      //     referenceRange: "2.6 – 4.2",
      //   },
      //   {
      //     vital: "Stroke Volume",
      //     value: Math.round(vitals["stroke_volume"] * 1000) / 1000,
      //     unit: "milli litre",
      //     referenceRange: "48.2 - 98.5",
      //   },
      //   {
      //     vital: "SBP",
      //     value: vitals["sbp"],
      //     unit: "mmHg",
      //     referenceRange: "100 – 130",
      //   },
      //   {
      //     vital: "DBP",
      //     value: vitals["dbp"],
      //     unit: "mmHg",
      //     referenceRange: "65 - 90",
      //   },
      //   // {
      //   //   vital: "T3 (Triiodothyronine)",
      //   //   value: "",
      //   //   unit: "ng/dl",
      //   //   referenceRange: "80-200",
      //   // },
      //   // {
      //   //   vital: "T4 (Thyroxine)",
      //   //   value: "",
      //   //   unit: "μg/dL",
      //   //   referenceRange: "4.8 – 12.7",
      //   // },
      //   // {
      //   //   vital: "TSH (Thyroid Stimulating Hormone)",
      //   //   value: "",
      //   //   unit: "μIU/mL",
      //   //   referenceRange: "0.54 – 5.30",
      //   // },
      //   // {
      //   //   vital: "Blood Volume",
      //   //   value: "",
      //   //   unit: "mL",
      //   //   referenceRange: "5000",
      //   // },
      //   {
      //     vital: "Hemoglobin",
      //     value: vitals["hemoglobin"],
      //     unit: "g/dl",
      //     referenceRange:
      //       vitals["gender"] === "Male" ? "13 - 17" : "11.6 - 15.5",
      //   },
      //   {
      //     vital: "Hematocrit",
      //     value: vitals["hematocrit"],
      //     unit: "%",
      //     referenceRange: vitals["gender"] === "Male" ? "40 - 54" : "36 - 48",
      //   },
      //   {
      //     vital: "HbA1c",
      //     value: vitals["hba1c"],
      //     unit: "%",
      //     referenceRange: "4 - 5.6",
      //   },
      //   {
      //     vital: "Mean Plasma Glucose",
      //     value: vitals["mean_plasma_glucose"],
      //     unit: "mg/dl",
      //     referenceRange: "72 - 100",
      //   },
      // ]);

      setFormData({
        name: vitals["name"],
        phoneNumber: vitals["phoneNumber"],
        email: vitals["email"],
        subjectId: vitals["subjectid"],
        gender: vitals["gender"],
        age: vitals["age"],
        height: vitals["height"],
        weight: vitals["weight"],
      });
    }
  }, [vitals]);

  const tableHead = ["Name", "Subject ID", "Gender", "Age", "Height", "Weight"];

  useEffect(() => {
    if (vitals) console.log("vitals", Object.keys(vitals), vitals);
  }, [vitals]);

  const [recipientEmail, setRecipientEmail] = useState("aniroycr537@gmail.com");
  const [file, setFile] = useState(null);

  const handleMailButtonClick = async (prov) => {
    try {
      // Generate PDF blob
      const blob = await pdf(
        <MyPDF
          healthParameters={healthParameters}
          formData={formData}
          accno={accountNumber}
        />
      ).toBlob();

      // Create FormData object to send the blob
      const emailform = new FormData();
      emailform.append("file", blob, "mypdf.pdf");

      // Additional data (recipient email)
      emailform.append("recipientEmail", "aniruddha@aivot.ai");

      // console.log(parentid,childid);

      if (prov === "gmail") {
        var link = `https://mail.google.com/mail/u/0/?fs=1&to&su=check+out+the+health+vitals&body=https://www.iterve.ai/vitals/pid%3D${parentid}/cid%3D${childid}&tf=cm`;
      } else if (prov === "yahoo") {
        var link = `https://compose.mail.yahoo.com/?subject=check+out+the+health+vitals&body=https://www.iterve.ai/vitals/pid%3D${parentid}/cid%3D${childid}`;
      } else {
        var link = `https://outlook.office.com/mail/deeplink/compose/?subject=check%20out%20the%20health%20vitals&body=https://www.iterve.ai/vitals/pid%3D${parentid}/cid%3D${childid}`;
      }
      window.open(link, "_blank");
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  // const handleMailButtonClick = async () => {
  //   // Fetching the PDF file
  //   const blob = await pdf(<MyPDF healthParameters={healthParameters} formData={formData} />).toBlob();
  //   console.log('blob', blob);
  //   // Converting the PDF file to base64
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = () => {
  //       const base64data = reader.result.split(',')[1];

  //       // Generate mailto link
  //       const recipient = 'recipient@gmail.com';
  //       const subject = 'Your Subject';
  //       const body = 'Please find the attached PDF.';
  //       const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}&attachment=${encodeURIComponent(base64data)}`;

  //       // Open mail app
  //       console.log(mailtoUrl);
  //       window.open(mailtoUrl, '_blank');
  //   };
  // };

  const copyToClipboard = async (text) => {
    console.log(text);
    await navigator.clipboard
      .writeText(text)
      .then(() => {
        setcopycompleted(true);
        setTimeout(() => {
          setcopycompleted(false);
        }, 3000);
        toast.success("Text copied to clipboard!", {
          autoClose: 1500,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhatsappClick = async (s) => {
    //whatsapp share

    const sharelink = `https://${s}.whatsapp.com/send?text=https://www.iterve.ai/vitals/pid=${parentid}/cid=${childid}`;
    // const a = document.createElement('a');
    // a.href=sharelink;
    // a.click();
    window.open(sharelink, "_blank");
  };

  return (
    <div className={classes.container}>
      {/* <img src={watermark} className={classes.watermark} /> */}
      <div className={classes.headingRow}>
        <div style={{ width: "15vw" }}>
          <img
            src={aivotLogo}
            alt="Hiims Logo here"
            className={classes.logo1VisOff}
          />
        </div>
        <div className={classes.headingText}>Your Vitals</div>
        <div style={{ width: "15vw" }}>
          <img src={aivotLogo} alt="Aivot Logo here" className={classes.logo} />
        </div>
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.name}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Name:</div>

          <div>{formData.name}</div>
        </div>

        <div
          className={classes.email}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div style={{ padding: "0 2vw" }}>Email:</div>
          <div>{formData.email}</div>
        </div>
      </div>

      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>PhoneNumber</div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Subject ID</div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Gender</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Age</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
          }}
        >
          <div>Height</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
          }}
        >
          <div>Weight</div>
        </div>
      </div>
      <div className={classes.infoSec}>
        <div
          className={classes.phoneNumber}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.phoneNumber} </div>
        </div>

        <div
          className={classes.subject}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.subjectId} </div>
        </div>

        <div
          className={classes.gender}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.gender}</div>
        </div>

        <div
          className={classes.age}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.age}</div>
        </div>

        <div
          className={classes.height}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.height}</div>
        </div>

        <div
          className={classes.weight}
          style={{
            borderTop: "0.1vw solid black",
            borderLeft: "0.1vw solid black",
            borderRight: "0.1vw solid black",
            borderBottom: "0.1vw solid black",
          }}
        >
          <div>{formData.weight}</div>
        </div>
      </div>

      <div className={classes.vitalRows}>
        <div className={classes.vitalsCol}>
          <div className={classes.vital}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Vitals
            </div>
          </div>
          <div className={classes.value}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Values
            </div>
          </div>
          <div className={classes.units}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Units
            </div>
          </div>
          <div className={classes.range}>
            <div
              style={{ fontSize: "2vw", color: "black", fontWeight: "bold" }}
            >
              Reference Range
            </div>
          </div>
        </div>

        {healthParameters.map((param, index) => (
          <div className={classes.vitalsCol} key={index}>
            <div className={classes.vital}>
              <div>{param.vital}</div>
            </div>
            <div className={classes.value}>
              <div>{param.value}</div>
            </div>
            <div className={classes.units}>
              <div>{param.unit}</div>
            </div>
            <div className={classes.range}>
              <div>{param.referenceRange}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.noteSec}>
        <div>
          <b>Note:</b> These values are only indicative, cannot be used for
          clinical. The vitals marked as <b>* (asterisk)</b> are under
          evaluation for testing the models.
        </div>
      </div>

      <div className={classes.allbuttons}>
        <button
          className={classes.downloadBtn}
          onClick={() => {
            navigate("/reports");
          }}
        >
          Back
        </button>

        <PDFDownloadLink
          document={
            <MyPDF
              healthParameters={healthParameters}
              formData={formData}
              accno={accountNumber}
            />
          }
          fileName={`${vitals ? vitals["name"] : ""}_report.pdf`}
          className={classes.downloadBtn}
        >
          {({ loading }) => (loading ? "Loading..." : "Download now")}
        </PDFDownloadLink>

        <div className={classes.popparent}>
          {sharepopup ? (
            <>
              <div className={classes.sharepopup}>
                <img
                  src={cross}
                  className={classes.crossbtn}
                  onClick={() => setsharepopup(false)}
                />
                <div className={classes.largecont}>
                  {!whatsappopt ? (
                    <button
                      className={
                        !mailopt ? classes.sharebtn : classes.sharebtn2
                      }
                      onClick={() => {
                        setmailopt(!mailopt);
                        return false;
                      }}
                    >
                      <img src={mailimage} className={classes.wpimage}></img>
                    </button>
                  ) : (
                    <></>
                  )}
                  {mailopt ? (
                    <>
                      <div className={classes.expandArrow}></div>
                      <div className={classes.wpoptdiv}>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("gmail")}
                        >
                          <img src={gamil} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("yahoo")}
                        >
                          <img src={yahoo} className={classes.optimage}></img>
                        </button>
                        <button
                          className={classes.wpoptbtn}
                          onClick={() => handleMailButtonClick("outlook")}
                        >
                          <img src={outlook} className={classes.optimage}></img>
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {!mailopt && (
                    <div className={classes.wpdiv}>
                      {!whatsappopt ? (
                        <button
                          className={classes.sharebtn}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      ) : (
                        <button
                          className={classes.sharebtn2}
                          onClick={() => {
                            setwhatsappopt(!whatsappopt);
                          }}
                          style={{ backgroundImage: { wpimage } }}
                        >
                          <img className={classes.wpimage} src={wpimage}></img>
                        </button>
                      )}
                      {whatsappopt ? (
                        <>
                          <div className={classes.expandArrow}></div>
                          <div className={classes.wpoptdiv}>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("web")}
                            >
                              <img
                                src={webimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={androidimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                            <button
                              className={classes.wpoptbtn}
                              onClick={() => handleWhatsappClick("api")}
                            >
                              <img
                                src={appleimage}
                                className={classes.optimage}
                              ></img>
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
                <div className={classes.smallcont}>
                  <div
                    className={classes.copybtn}
                    onClick={() =>
                      copyToClipboard(
                        `https://www.iterve.ai/vitals/pid=${parentid}/cid=${childid}`
                      )
                    }
                  >
                    <img
                      src={copycompleted ? tickimage : copyimage}
                      className={classes.copyimage}
                    />
                    Copy
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <button
            className={classes.downloadBtn}
            onClick={() => {
              setsharepopup(!sharepopup);
            }}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportVitals;
