import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const SavedStateContext = createContext();

const SavedState = (props) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("currentUser"))
  );
  const [users, setUsers] = useState([]);
  const [userImage, setUserImage] = useState({});

  useEffect(() => {
    currentUser &&
      axios
        .get("https://www.iterve.ai/uservitals/subusernames", {
          params: { email: currentUser.email },
        })
        .then((res) => {
          console.log("all users", res.data);
          var a = [];
          var ui = {};
          for (let i = 0; i < res.data.length; i++) {
            a.push(res.data[i].name);
            if (res.data[i].image) {
              ui[res.data[i].name] = res.data[i].image[0];
            } else {
              ui[res.data[i].name] = "No image";
            }
          }
          setUserImage(ui);
          setUsers(a.filter((name) => name != currentUser.name));
        });
  }, [currentUser]);

  console.log("localStorage", JSON.parse(localStorage.getItem("currentUser")));
  return (
    <SavedStateContext.Provider
      value={{ currentUser, setCurrentUser, users, userImage, setUserImage }}
    >
      {props.children}
    </SavedStateContext.Provider>
  );
};

export { SavedState, SavedStateContext };
